import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { BrowserView, isWindows, MobileView } from 'react-device-detect';
import { Accordion } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { API_BASE, cargoPriceFree } from '../../config/env';
import PreInformationForm from '../common/PreInformationForm';
import SalesAgreement from '../common/SalesAgreement';
import Input from 'react-input-auto-format';

const Checkoutpayment = () => {
    const cookies = new Cookies();
    var cargoPrice = parseInt(cookies.get('cargoPrice'));
    var cargoId = cookies.get('cargoId');
    const [cargoPricee, setCargoPricee] = useState(0);
    const [instalment, setInstalment] = useState(1);
    const [totalInfo, setTotalInfo] = useState({
        totalPrice: 0,
        totalDiscountedPrice: 0,
        KDV: 0,
        Kargo: 0
    });
    const [creditCardInfo, setCreditCardInfo] = useState({});
    const [basketQuantity, setBasketQuantity] = useState([]);
    const [kuponKod, SetKuponKod] = useState('');
    const [basketId, SetBasketId] = useState(0);
    const [shippingAddressDetail, setShippingAddressDetail] = useState();
    const [billingAddressDetail, setBillingAddressDetail] = useState();
    const [basket, setBasket] = useState({
        basketId: 0,
        basketProducts: [
            {
                productId: '',
                productImage: '',
                discountPrice: 0,
                campaignPrice: 0,
                productName: '',
                productShortDesc: '',
                price: 0,
                variantInfo: {
                    productAttributeId: 0,
                    productAttributeValue: '',
                    productAttributeValueId: 0,
                    productAttributeValueValue: '',
                    price: 0.0,
                    stockId: '',
                    variantPictureId: 0,
                    variantPicturePath: ''
                }
            }
        ],
        basketInfo: {
            totalAmount: 0.0,
            discountedAmount: 0.0,
            totalKdv: 0.0,
            isShipmentFree: false,
            shipmentFreeLimit: 0
        }
    });
    const [shippingAddress, setShippingAddress] = useState();
    const [billingAddress, setBillingAddress] = useState();
    const [unregisterEmail, setUnregisterEmail] = useState();
    const [cargoPriceState, setCargoPriceState] = useState();
    const [agreementCheckBoxChecked, setAgreementCheckBoxChecked] =
        useState(false);
    function agreementCheckBoxChanged(event) {
        setAgreementCheckBoxChecked(event.target.checked);
        if (event.target.checked == true) setAgreementErrorShow(false);
    }
    const [userInfo, setUserInfo] = useState({});
    useEffect(() => {
        var cargoPricee = 0;
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };

        axios
            .get(`${API_BASE}Cargo/GetCargoById?cargoId=${cargoId}`, config)
            .then((data) => {
                if (data.data) {
                    cargoPricee = data.data.set.basePrice;
                    setCargoPricee(data.data.set.basePrice);
                }
            });
        const shippingAddressCookie = cookies.get('shippingaddress');
        const billingAddressCookie = cookies.get('billingaddress');
        const unregisterEmail = cookies.get('unregisterEmail');
        setBillingAddress(billingAddressCookie);
        setShippingAddress(shippingAddressCookie);
        setUnregisterEmail(unregisterEmail);
        setCargoPriceState(cargoPricee);
        //order-payment-page
        document.getElementById('body').className = 'order-payment-page';
        const userToken = localStorage.getItem('token');

        if (!cargoId || !shippingAddressCookie) {
            alert('Lütfen adres ve kargo seçimi yapın');
            window.location.href = '/AddressCheck';
        }

        axios
            .get(
                `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                config
            )
            .then((data) => {
                if (data.data) {
                    if (data.data.code == '200') {
                        setUserInfo(data.data.set);
                        GetActiveBasket(data.data.set.customerId);
                        CheckCustomerId(data.data.set.customerId);
                    } else {
                        CheckCustomerId(0);
                    }
                }
            });

        function GetActiveBasket(customerId) {
            //apiden ilgili basketin kupon kodunu çekiyoruz.
            axios
                .get(
                    `${API_BASE}basket/GetActiveBasket?customerId=${customerId}`,
                    config
                )
                .then((data) => {
                    if (data.data) {
                        if (data.data.code == '200') {
                            SetBasketId(data.data.set.Id);
                            if (
                                data.data.set.couponCode != null ||
                                data.data.set.campaignId != null
                            ) {
                                SetKuponKod(data.data.set.couponCode);
                                //SP kupon kodu ve basket id gönderilecek gelen değer true ya da false olacak;

                                const config = {
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                };
                                axios
                                    .get(
                                        `${API_BASE}Campaign/CouponCampaignControl?basketId=${data.data.set.id}`,
                                        config
                                    )
                                    .then((data) => {
                                        if (data.data) {
                                            if (data.data.code == '200') {
                                                var isCampaign = data.data.set;
                                                if (isCampaign == false) {
                                                    alert(
                                                        'Kampanya uygulanamadı. Geçersiz Kampanya!'
                                                    );
                                                    window.location.href =
                                                        '/basket';
                                                }
                                            }
                                        }
                                    });
                            }
                        }
                    }
                });
        }

        function CheckCustomerId(customerId) {
            if (customerId == 0) {
                var cookiedBasket = cookies.get('basket');
                cookiedBasket && setBasket(cookiedBasket);
                SepetIslemleri(cookiedBasket);
            } else {
                const getBasketServiceUrl = 'basket/GetCustomerBasket/';
                const config = {
                    headers: { 'Content-Type': 'application/json' }
                };
                axios
                    .get(
                        `${API_BASE}${getBasketServiceUrl}${customerId}`,
                        config
                    )
                    .then((data) => {
                        setBasket(data.data.set);
                        SepetIslemleri(data.data.set);
                    })
                    .catch();
            }
        }

        function SepetIslemleri(basket) {
            //Cookies işlemleri.
            var totalPrice = 0;
            var totalDiscountedPrice = 0;
            var basketQuantities = [];
            // setBasketQuantity([{ productId: productId, quantity: 1, price: price }])
            if (!basket) {
                window.location.pathname = '/';
            }
            basket.basketProducts.map((bp) => {
                basketQuantities.push({
                    productId: bp.productId,
                    quantity: bp.quantity,
                    price: bp.price
                });
                totalPrice += bp.price * bp.quantity;
                totalDiscountedPrice +=
                    bp.campaignPrice !== undefined && bp.campaignPrice !== null
                        ? bp.campaignPrice * bp.quantity
                        : bp.discountPrice !== null
                        ? bp.discountPrice * bp.quantity
                        : bp.price * bp.quantity;

                setTotalInfo({
                    totalPrice: totalPrice,
                    totalDiscountedPrice: totalDiscountedPrice,
                    KDV: 20,
                    Kargo:
                        Number(totalDiscountedPrice) >= cargoPriceFree
                            ? 0
                            : cargoPricee
                });
            });
            setBasketQuantity(basketQuantities);
        }
        //basketAddress Detail
        if (shippingAddressCookie) {
            const config2 = {
                Headers: { 'Content-Type': 'application/json;charset=utf-8' }
            };
            const dataAddress = {
                StateProvinceId: shippingAddressCookie?.stateProvinceId,
                DistrictId: shippingAddressCookie?.districtId
            };
            axios
                .post(
                    `${API_BASE}Basket/BasketAddressDetail`,
                    dataAddress,
                    config2
                )
                .then((data) => {
                    if (data.data.code == '200') {
                        setShippingAddressDetail(data.data.set);
                    }
                });
        }
        if (billingAddressCookie) {
            const config2 = {
                Headers: { 'Content-Type': 'application/json;charset=utf-8' }
            };
            const dataAddress = {
                StateProvinceId: billingAddressCookie?.stateProvinceId,
                DistrictId: billingAddressCookie?.districtId
            };
            axios
                .post(
                    `${API_BASE}Basket/BasketAddressDetail`,
                    dataAddress,
                    config2
                )
                .then((data) => {
                    if (data.data.code == '200') {
                        setBillingAddressDetail(data.data.set);
                    }
                });
        }
        var basketItems = [];
        if (basket && basket.basketProducts.length > 0) {
            basket.basketProducts.map((bi) => {
                basketItems.push({
                    item_id: bi.productId,
                    item_name: bi.productName
                });
            });
        }

        if (totalInfo.totalPrice > 1) {
            window.dataLayer.push({
                event: 'begin_checkout',
                currency: 'TRY',
                value: totalInfo.totalDiscountedPrice,
                coupon: kuponKod,
                begincheckout: {
                    items: basketItems
                }
            });
        }
    }, [totalInfo.totalPrice]);

    function formsOnChange(e) {
        if (e.target.name == 'creditCardNumber') {
            if (e.target.value.length > 16) {
                e.target.value = e.target.value.substring(0, 16);
                return;
            }
        }
        if (e.target.name == 'creditCardExpirations') {
            if (e.target.value.length > 5) {
                e.target.value = e.target.value.substring(0, 5);
                return;
            }
            var val = e.target.value.replace('/', '');
            if (val.length == 2) {
                e.target.value = e.target.value + '/';
            }
        }
        if (e.target.name == 'creditCartCVC') {
            if (e.target.value.length > 3) {
                e.target.value = e.target.value.substring(0, 3);
                return;
            }
        }
        setCreditCardInfo({
            ...creditCardInfo,
            [e.target.name]: e.target.value
        });
    }
    const [agreementErrorShow, setAgreementErrorShow] = useState(false);
    function btnPayClick() {
        if (agreementCheckBoxChecked == false) {
            alert(
                'Ön bilgilendirme formunu ve mesafeli satış sözleşmesini okuyup onaylayınız.'
            );
            setAgreementErrorShow(true);
            return;
        }

        var unregisteredEmail = cookies.get('unregisterEmail');
        var unregisteredAddress = cookies.get('billingaddress');
        var shippingaddress = cookies.get('shippingaddress');
        var basketProducts = basket.basketProducts;
        if (
            !(
                creditCardInfo &&
                creditCardInfo.creditCardNumber &&
                creditCardInfo.creditCartCVC &&
                creditCardInfo.creditCardExpirations &&
                creditCardInfo.creditCardNameFirstName
            )
        ) {
            alert('Bilgiler eksik');
        } else {
            const config = {
                headers: { 'Content-Type': 'application/json;charset=utf-8' }
            };

            var obj = {};
            obj.amount = totalInfo.totalDiscountedPrice + totalInfo.Kargo;
            obj.cargoPrice = totalInfo.Kargo;
            obj.cargoId = cargoId;
            obj.card_holder = creditCardInfo.creditCardNameFirstName;
            obj.cvc = creditCardInfo.creditCartCVC;
            obj.month = creditCardInfo.creditCardExpirations.split('/')[0];
            obj.pan = creditCardInfo.creditCardNumber;
            obj.year = creditCardInfo.creditCardExpirations.split('/')[1];

            obj.products = basketProducts;
            if (userInfo && userInfo.isEmployee == true) {
                obj.email = userInfo.email;
                obj.customerId = userInfo.customerId;
            } else if (userInfo && userInfo.isEmployee == false) {
                obj.email = userInfo.email;
                obj.customerId = userInfo.customerId;
            } else {
                obj.email = unregisteredEmail.unregisterMail;
            }

            obj.totalPrice = totalInfo.totalDiscountedPrice + totalInfo.Kargo;
            obj.billingAddress = unregisteredAddress;
            obj.shippingAddress = shippingaddress;
            obj.instalment = instalment;

            document.getElementById('btnPay').disabled = true;
            document.getElementById('btnPay').innerHTML =
                'Lütfen Bekleyiniz...';
            axios
                .post(`${API_BASE}Order/PayOrderStart`, obj, config)
                .then((data) => {
                    if (data.data.set) {
                        if (data.data.set.post_url) {
                            cookies.set('orderId', data.data.set.orderId);
                            window.location.href = data.data.set.post_url;
                        } else {
                            alert(data.data.set.message);
                            document.getElementById('btnPay').disabled = false;
                        }
                    } else {
                        alert('Bir Hata Oluştu!');
                        document.getElementById('btnPay').disabled = false;
                    }
                });
        }
    }
    const instalmentCheckChanged = (e) => {
        setInstalment(e.target.value);
    };
    return (
        <>
            <div className="checkout-header py-4">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-center">
                        <a
                            href="/AddressCheck"
                            className="btn step current p3 d-flex flex-column align-items-center"
                        >
                            <i className="bi bi-truck h5"></i>
                            <span className="fw-bold">Teslimat</span>
                        </a>
                        <span className="line current"></span>
                        <button
                            href="#"
                            className="btn step current p3 d-flex flex-column align-items-center"
                        >
                            <i className="bi bi-credit-card h5"></i>
                            <span className="fw-bold">Güvenli Ödeme</span>
                        </button>
                        <span className="line"></span>
                        <button
                            href="#"
                            className="btn step p3 d-flex flex-column align-items-center"
                        >
                            <i className="bi bi-receipt h5"></i>
                            <span className="fw-bold">Özet</span>
                        </button>
                    </div>
                </div>
            </div>
            <section className="checkout-payment py-5 flex-fill">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <div className="d-flex flex-column gap-5 mb-5">
                                {/* Kredi Kartı */}
                                <div className="credit-card d-flex flex-column gap-4">
                                    <header className="header d-flex flex-wrap gap-3 justify-content-between align-items-center mb-4">
                                        <h5 className="fw-bold">
                                            <i className="bi bi-credit-card me-2"></i>
                                            <span>Kredi Kartı İle Ödeme</span>
                                        </h5>
                                        <div className="d-sm-flex align-items-center small text-success d-none">
                                            <i className="bi bi-shield-check me-2 h2"></i>
                                            <div className="text d-flex flex-column gap-0">
                                                <h5 className="fw-bolder">
                                                    256-Bit SSL
                                                </h5>
                                                <div className="small fw-bold">
                                                    şifreleme ile korunmaktadır.
                                                </div>
                                            </div>
                                        </div>
                                    </header>
                                    <div className="row justify-content-between align-items-center g-4">
                                        <div className="col-sm-6">
                                            {/* Ödeme Detayları */}
                                            <div className="border rounded-2">
                                                <div className="cardnumber border-bottom p-4">
                                                    <label
                                                        className="form-label fw-bold"
                                                        htmlFor="creditCardNumber"
                                                    >
                                                        <i className="bi bi-credit-card-2-front me-2 text-primary"></i>
                                                        Kart Numarası
                                                    </label>
                                                    <Input
                                                        className="form-control border-0 p-0 rounded-0"
                                                        type="text"
                                                        id="creditCardNumber"
                                                        placeholder="**** **** **** ****"
                                                        name="creditCardNumber"
                                                        format="################"
                                                        autoComplete="off"
                                                        onChange={(e) =>
                                                            formsOnChange(e)
                                                        }
                                                    />
                                                </div>
                                                <div className="firstname border-bottom p-4">
                                                    <label
                                                        className="form-label fw-bold"
                                                        htmlFor="creditCardNameFirstName"
                                                    >
                                                        <i className="bi bi-person me-2 text-primary"></i>
                                                        Kart Üzerindeki İsim
                                                    </label>
                                                    <input
                                                        className="form-control border-0 p-0 rounded-0"
                                                        type="text"
                                                        id="creditCardNameFirstName"
                                                        name="creditCardNameFirstName"
                                                        placeholder="Kart sahibinin adı ve soyadı"
                                                        autoComplete="off"
                                                        onChange={(e) =>
                                                            formsOnChange(e)
                                                        }
                                                    />
                                                </div>
                                                <div className="d-flex flex-row justify-content-between gap-4">
                                                    <div className="date py-4 ps-4">
                                                        <label
                                                            className="form-label text-nowrap fw-bold"
                                                            htmlFor="creditCardExpirations"
                                                        >
                                                            <i className="bi bi-calendar4-week me-2 text-primary"></i>
                                                            Son Kullanma Tarihi
                                                        </label>
                                                        <Input
                                                            className="form-control border-0 p-0 rounded-0"
                                                            type="text"
                                                            id="creditCardExpirations"
                                                            name="creditCardExpirations"
                                                            placeholder="Ay / Yıl"
                                                            format="##/##"
                                                            autoComplete="off"
                                                            onChange={(e) =>
                                                                formsOnChange(e)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="code py-4 pe-4">
                                                        <label
                                                            className="form-label fw-bold"
                                                            htmlFor="creditCartCVC"
                                                        >
                                                            <i className="bi bi-credit-card-2-back me-2 text-primary"></i>
                                                            Güvenlik Kodu
                                                        </label>
                                                        <input
                                                            className="form-control border-0 p-0 rounded-0"
                                                            type="text"
                                                            id="creditCartCVC"
                                                            name="creditCartCVC"
                                                            placeholder="CVC / CVV"
                                                            autoComplete="off"
                                                            onChange={(e) =>
                                                                formsOnChange(e)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="d-flex flex-column gap-4 p-4 rounded-2 border">
                                                <div>
                                                    <div className="form-subheader fw-bold">
                                                        TAKSİT SEÇENEKLERİ
                                                    </div>
                                                    <div className="text-black-50">
                                                        Kredi kartlarına vade
                                                        farksız 6 ay'a kadar
                                                        taksitlendirme
                                                        yapabilirsiniz.
                                                    </div>
                                                </div>
                                                <table className="table table-striped installmentTable mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                Taksit
                                                                Seçenekleri
                                                            </th>
                                                            <th scope="col">
                                                                Aylık Ödeme
                                                            </th>
                                                            <th scope="col">
                                                                Toplam
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="instalment"
                                                                        id="instalment1"
                                                                        value="1"
                                                                        onChange={
                                                                            instalmentCheckChanged
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="instalment1"
                                                                    >
                                                                        Peşin
                                                                    </label>
                                                                </div>
                                                            </th>
                                                            <td>
                                                                {' '}
                                                                {(
                                                                    totalInfo.totalDiscountedPrice +
                                                                    totalInfo.Kargo
                                                                ).toFixed(
                                                                    2
                                                                )}{' '}
                                                                TL
                                                            </td>
                                                            <td>
                                                                {' '}
                                                                {(
                                                                    totalInfo.totalDiscountedPrice +
                                                                    totalInfo.Kargo
                                                                ).toFixed(
                                                                    2
                                                                )}{' '}
                                                                TL
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="instalment"
                                                                        id="instalment3"
                                                                        value="3"
                                                                        onChange={
                                                                            instalmentCheckChanged
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="instalment3"
                                                                    >
                                                                        3 Taksit
                                                                    </label>
                                                                </div>
                                                            </th>
                                                            <td>
                                                                {' '}
                                                                {(
                                                                    (totalInfo.totalDiscountedPrice +
                                                                        totalInfo.Kargo) /
                                                                    3
                                                                ).toFixed(
                                                                    2
                                                                )}{' '}
                                                                TL
                                                            </td>
                                                            <td>
                                                                {' '}
                                                                {(
                                                                    totalInfo.totalDiscountedPrice +
                                                                    totalInfo.Kargo
                                                                ).toFixed(
                                                                    2
                                                                )}{' '}
                                                                TL
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="instalment"
                                                                        id="instalment6"
                                                                        value="6"
                                                                        onChange={
                                                                            instalmentCheckChanged
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="instalment6"
                                                                    >
                                                                        6 Taksit
                                                                    </label>
                                                                </div>
                                                            </th>
                                                            <td>
                                                                {(
                                                                    (totalInfo.totalDiscountedPrice +
                                                                        totalInfo.Kargo) /
                                                                    6
                                                                ).toFixed(
                                                                    2
                                                                )}{' '}
                                                                TL
                                                            </td>
                                                            <td>
                                                                {(
                                                                    totalInfo.totalDiscountedPrice +
                                                                    totalInfo.Kargo
                                                                ).toFixed(
                                                                    2
                                                                )}{' '}
                                                                TL
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Yasal Bildirimler */}
                                <div className="legal-notices">
                                    <h5 className="fw-bold mb-4">
                                        <i className="bi bi-file-earmark-check me-2"></i>
                                        <span>Yasal Bildirimler</span>
                                    </h5>
                                    <div
                                        className="accordion accordion-flush"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="onbilgilendirmeformu"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#onbilgilendirme-collapse"
                                                    aria-expanded="false"
                                                    aria-controls="onbilgilendirme-collapse"
                                                >
                                                    ÖN BİLGİLENDİRME FORMU
                                                </button>
                                            </h2>
                                            <div
                                                id="onbilgilendirme-collapse"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="onbilgilendirmeformu"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body accordion-scroll">
                                                    <div>
                                                        <PreInformationForm
                                                            userInfo={userInfo}
                                                            billingAddress={
                                                                billingAddress
                                                            }
                                                            shippingAddress={
                                                                shippingAddress
                                                            }
                                                            billingAddressDetail={
                                                                billingAddressDetail
                                                            }
                                                            shippingAddressDetail={
                                                                shippingAddressDetail
                                                            }
                                                            unregisterEmail={
                                                                unregisterEmail
                                                            }
                                                            cargoPriceState={
                                                                cargoPriceState
                                                            }
                                                            instalment={
                                                                instalment
                                                            }
                                                            totalInfo={
                                                                totalInfo
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="mesafelisatissozlemesi"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#mesafelisatis-collapse"
                                                    aria-expanded="false"
                                                    aria-controls="mesafelisatis-collapse"
                                                >
                                                    MESAFELİ SATIŞ SÖZLEŞMESİ
                                                </button>
                                            </h2>
                                            <div
                                                id="mesafelisatis-collapse"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="mesafelisatissozlemesi"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div
                                                    className="accordion-body accordion-scroll"
                                                    id="mesafelisatissozlemesi"
                                                >
                                                    <div>
                                                        <SalesAgreement
                                                            userInfo={userInfo}
                                                            billingAddress={
                                                                billingAddress
                                                            }
                                                            shippingAddress={
                                                                shippingAddress
                                                            }
                                                            billingAddressDetail={
                                                                billingAddressDetail
                                                            }
                                                            shippingAddressDetail={
                                                                shippingAddressDetail
                                                            }
                                                            unregisterEmail={
                                                                unregisterEmail
                                                            }
                                                            cargoPriceState={
                                                                cargoPriceState
                                                            }
                                                            instalment={
                                                                instalment
                                                            }
                                                            totalInfo={
                                                                totalInfo
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border rounded-2 p-4 border-success text-success text-center mt-4">
                                        "SİPARİŞİ TAMAMLA" butonuna basmanız
                                        halinde, seçmiş olduğunuz ödeme
                                        yöntemine uygun olarak,
                                        <br /> toplam{' '}
                                        <strong>
                                            {' '}
                                            {(
                                                totalInfo.totalDiscountedPrice +
                                                (totalInfo.Kargo != null
                                                    ? totalInfo.Kargo
                                                    : 0)
                                            ).toFixed(2)}{' '}
                                            TL
                                        </strong>{' '}
                                        tahsil edilecektir.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <BrowserView className="col-sm-3 mb-5">
                            <div className="summary border rounded-2 sticky-upper overflow-hidden">
                                <header className="border-bottom p-4 h5 fw-bold bg-light">
                                    Sipariş Özeti
                                </header>
                                <div className="d-flex flex-column">
                                    <div className="detail d-flex flex-column gap-3 p-4 border-bottom">
                                        <div className="d-flex justify-content-start align-items-start gap-3">
                                            <i className="bi bi-file-text text-primary"></i>
                                            <div className="d-flex flex-column">
                                                <span className="text-black-50 small">
                                                    İndirimsiz Toplam
                                                </span>
                                                <span className="fw-bold">
                                                    {totalInfo.totalPrice.toFixed(
                                                        2
                                                    )}{' '}
                                                    TL{' '}
                                                    <span className="text-danger small fw-normal">
                                                        ({totalInfo.KDV}% KDV
                                                        Dahil)
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        {totalInfo.totalPrice >
                                        totalInfo.totalDiscountedPrice ? (
                                            <div className="d-flex justify-content-start align-items-start gap-3">
                                                <i className="bi bi-file-medical text-primary"></i>
                                                <div className="d-flex flex-column">
                                                    <span className="text-black-50 small">
                                                        İndirimli Tutar
                                                    </span>
                                                    <span className="fw-bold">
                                                        {totalInfo.totalDiscountedPrice.toFixed(
                                                            2
                                                        )}{' '}
                                                        TL
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        <div className="d-flex justify-content-start align-items-start gap-3">
                                            <i className="bi bi-box-seam text-primary"></i>
                                            <div className="d-flex flex-column">
                                                <span className="text-black-50 small">
                                                    Kargo Bedeli
                                                </span>
                                                {Number(
                                                    totalInfo.totalDiscountedPrice
                                                ) >= cargoPriceFree ? (
                                                    <span className="fw-bold text-success">
                                                        ÜCRETSİZ
                                                    </span>
                                                ) : (
                                                    <span className="fw-bold">
                                                        {totalInfo.Kargo <= 0
                                                            ? 'Kargo Seçiniz'
                                                            : totalInfo.Kargo +
                                                              ' TL'}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="total px-4 pt-4">
                                        <div className="fw-normal text-black-50">
                                            Ödenecek Tutar
                                        </div>
                                        <div className="fw-bolder h4">
                                            {' '}
                                            {(
                                                totalInfo.totalDiscountedPrice +
                                                totalInfo.Kargo
                                            ).toFixed(2)}{' '}
                                            TL
                                        </div>
                                    </div>
                                    <div className="form-check mt-4 mx-4 border-success fw-bold">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="readPolicyAccept"
                                            onChange={agreementCheckBoxChanged}
                                            required
                                        />
                                        <label
                                            className={`form-check-label small ${
                                                agreementErrorShow == true
                                                    ? 'text-danger'
                                                    : 'text-dark'
                                            }`}
                                            htmlFor="readPolicyAccept"
                                        >
                                            Ön bilgilendirme formunu ve mesafeli
                                            satış sözleşmesini okudum
                                            onaylıyorum.
                                        </label>
                                    </div>
                                    <div className="d-grid mt-4">
                                        <button
                                            id="btnPay"
                                            name="btnPay"
                                            className="btn btn-lg btn-primary py-3 fw-bold rounded-2 mx-4 mb-4 d-flex justify-content-between"
                                            type="submit"
                                            onClick={() => btnPayClick()}
                                        >
                                            Güvenli Öde
                                            <i className="bi bi-check"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </BrowserView>
                        <MobileView className="position-fixed bottom-0 p-0 z-index-3 w-100">
                            <div className="d-flex flex-column gap-3 bg-white p-3 m-3 rounded-3 border shadow">
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className="rounded-3">
                                            <i className="bi bi-receipt me-2"></i>
                                            <span>Detayları Göster</span>
                                        </Accordion.Header>
                                        <Accordion.Body className="pt-2 pb-0">
                                            <div className="detail d-flex flex-column gap-3 py-4">
                                                {/* Özet Elemanı */}
                                                <div className="d-flex justify-content-start align-items-start gap-3">
                                                    <i className="bi bi-file-text text-primary"></i>
                                                    <div className="d-flex flex-column">
                                                        <span className="text-black-50 small">
                                                            İndirimsiz Toplam
                                                        </span>
                                                        <span className="fw-bold">
                                                            {totalInfo.totalPrice.toFixed(
                                                                2
                                                            )}{' '}
                                                            TL{' '}
                                                            <span className="text-danger small fw-normal">
                                                                KDV Dahil
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                {totalInfo.totalPrice >
                                                totalInfo.totalDiscountedPrice ? (
                                                    <div className="d-flex justify-content-start align-items-start gap-3">
                                                        <i className="bi bi-file-medical text-primary"></i>
                                                        <div className="d-flex flex-column">
                                                            <span className="text-black-50 small">
                                                                İndirimli Tutar
                                                            </span>
                                                            <span className="fw-bold">
                                                                {totalInfo.totalDiscountedPrice.toFixed(
                                                                    2
                                                                )}{' '}
                                                                TL
                                                            </span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                <div className="d-flex justify-content-start align-items-start gap-3">
                                                    <i className="bi bi-box-seam text-primary"></i>
                                                    <div className="d-flex flex-column">
                                                        <span className="text-black-50 small">
                                                            Kargo Bedeli
                                                        </span>
                                                        {Number(
                                                            totalInfo.totalDiscountedPrice
                                                        ) >= cargoPriceFree ? (
                                                            <span className="fw-bold text-success">
                                                                ÜCRETSİZ
                                                            </span>
                                                        ) : (
                                                            <span className="fw-bold">
                                                                {totalInfo.Kargo <=
                                                                0
                                                                    ? 'Kargo Seçiniz'
                                                                    : totalInfo.Kargo +
                                                                      ' TL'}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <div className="form-check border-success fw-bold">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="readPolicyAccept"
                                        onChange={agreementCheckBoxChanged}
                                        required
                                    />
                                    <label
                                        className={`form-check-label small ${
                                            agreementErrorShow == true
                                                ? 'text-danger'
                                                : 'text-dark'
                                        }`}
                                        htmlFor="readPolicyAccept"
                                    >
                                        <span>
                                            <a href="#onbilgilendirmeformu">
                                                Ön bilgilendirme formu
                                            </a>
                                        </span>
                                        nu ve{' '}
                                        <span>
                                            <a href="#mesafelisatissozlemesi">
                                                mesafeli satış sözleşmesi
                                            </a>
                                        </span>
                                        ni okudum onaylıyorum.
                                    </label>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="total">
                                        <div className="fw-normal text-black-50">
                                            Ödenecek Tutar
                                        </div>
                                        <div className="fw-bolder h4 text-success">
                                            {' '}
                                            {(
                                                totalInfo.totalDiscountedPrice +
                                                totalInfo.Kargo
                                            ).toFixed(2)}{' '}
                                            TL
                                        </div>
                                    </div>
                                    <button
                                        id="btnPay"
                                        name="btnPay"
                                        className="btn btn-lg btn-primary py-3 fw-bold rounded-3 d-flex justify-content-between"
                                        type="submit"
                                        onClick={() => btnPayClick()}
                                    >
                                        Güvenli Öde
                                        <i className="bi bi-check"></i>
                                    </button>
                                </div>
                            </div>
                        </MobileView>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Checkoutpayment;
