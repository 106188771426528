import { useEffect, useState } from 'react';
const SalesAgreement = (props) => {
    var fullname = `${props && props.userInfo.firstName}  ${
        props && props.userInfo.lastname
    }`;
    if (!props.userInfo.firstName) {
        // console.log(props.shippingAddress)
        fullname = `${props.shippingAddress?.firstName} ${props.shippingAddress?.lastName}`;
    }
    const saticiTelefon = '02124546000';
    const saticiAdres =
        'Çobançeşme Mahallesi, Sanayi Caddesi, Bilge 1 Sokak No:17 Yenibosna 34196 Bahçelievler / İstanbul';

    return (
        <>
            <p>
                <strong>MADDE 1: TARAFLAR</strong>
            </p>
            <p>
                <strong>1.1. SATICI:</strong>
            </p>
            <p>
                Unvanı: <strong>Tesan İletişim A.Ş.</strong>
            </p>
            <p>Adres: {saticiAdres}</p>
            <p>Telefon: {saticiTelefon}</p>
            <p>Mersis No: 0840004806900010</p>
            <p>
                <strong>1.2. ALICI:</strong>
            </p>
            <p>
                İş bu sözleşme kapsamında {fullname} www.mojue.com.tr internet
                sitesinden sipariş veren kişidir. {fullname}’nın, üye olurken
                veya sipariş verirken kullanıldığı adres ve iletişim bilgileri
                esas alınacaktır.
            </p>
            <p>
                <strong>MADDE 2: KONU</strong>
            </p>
            <p>
                <strong>2.1.</strong>
                İşbu sözleşmenin konusu,{fullname}`nın SATICI`ya ait
                www.mojue.com.tr internet sitesinden elektronik ortamda sipariş
                verdiği, sözleşmede bahsi geçen nitelikleri haiz satış fiyatı
                belirtilen ürünlerin satışı ve teslimi ile ilgili olarak 6502
                sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli
                Sözleşmeler Yönetmeliği hükümleri gereğince tarafların hak ve
                yükümlülüklerinin saptanmasıdır.
            </p>
            <p>
                <strong>2.2.</strong>
                {fullname}, satışa konu ürünlerin temel nitelikleri, satış
                fiyatı, ödeme şekli, teslimat koşulları vs. satışa konu ürün ile
                ilgili tüm ön bilgiler, cayma hakkı, gizlilik ve kişisel
                verilerin korunması hakkında bilgilendirme, uyuşmazlık halinde
                başvuru hakları vs. tüm ön bilgileri ve sözleşmeyi elektronik
                ortamda teyit ettiğini ve sonrasında ürünü sipariş verdiğini,
                işbu sözleşme hükümlerince kabul ve beyan eder.
            </p>
            <p>
                <strong>2.3. </strong>
                www.mojue.com.tr sitesinde yer alan ön bilgilendirme ve{' '}
                {fullname}
                tarafından verilen sipariş üzerine düzenlenen fatura ve sevk
                irsaliyesi işbu sözleşmenin ayrılmaz parçalarıdır.
            </p>
            <p>
                <strong>2.4.</strong>
                İşbu sözleşme, {fullname}’nın ürünün siparişine ilişkin
                elektronik ortamda gerekli teyidi vermesinden sonra geçerlilik
                kazanır.
            </p>
            <p>
                <strong>
                    MADDE 3: ÜRÜNÜN TESLİMİ, SÖZLEŞMENİN İFA YERİ VE TESLİM
                    ŞEKLİ
                </strong>
            </p>
            <p>
                <strong>3.1.</strong>
                {fullname}’nın teslimini talep etmiş olduğu ürün, {fullname}{' '}
                tarafından belirlenmiş olan teslimat adresi_ adresine kargo
                şirketi yetkilisi marifetiyle teslim edilecektir.
            </p>
            <p>
                <strong>3.2. </strong>
                Elektronik ortamda alınan ürün/ürünlerin cinsi ve türü, miktarı,
                marka/modeli, satış bedeli, ödeme şekli, teslim alacak kişi,
                teslimat adresi, fatura bilgileri {fullname}’nın sipariş verdiği
                bölümde yazıldığı gibidir. Fatura edilecek kişi ile sözleşmeyi
                yapan kişi aynı olmak zorundadır. Bu bilgilerin doğru olmadığı
                veya noksan olduğu durumlardan doğacak zararları tamamıyla
                karşılamayı {fullname} kabul eder ve ayrıca bu durumlardan
                oluşabilecek her türlü sorumluluğu {fullname} kabul eder.
            </p>
            <p>
                <strong>3.3.</strong>S ATICI gerekli gördüğü durumlarda,
                {fullname}’nın vermiş olduğu bilgilerin gerçekle örtüşmediği
                tespit edildiğinde, siparişi durdurma hakkını saklı tutar.
                SATICI, siparişte sorun tespit ettiği durumlarda {fullname}’nın
                vermiş olduğu telefon, elektronik posta ve posta adreslerinden
                {fullname}’ya ulaşamadığı takdirde siparişin yürürlüğe
                konulmasını 15 (onbeş) gün süre ile dondurma hakkına sahiptir.
                {fullname}’nın bu süre zarfında SATICI ile konuyla ilgili
                iletişime geçmesi beklenir. Bu süre içinde {fullname}’dan
                herhangi bir cevap alınamaz ise SATICI, her iki tarafın da zarar
                görmemesi için siparişi iptal etme hakkına sahiptir.
            </p>
            <p>
                <strong>3.4. </strong>
                Listede ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan
                edilen fiyatlar ve vaatler güncelleme yapılana ve değiştirilen
                kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise
                belirlenen süre sonuna kadar geçerlidir. <strong></strong>
            </p>
            <p>
                <strong>MADDE 4: TESLİMAT MASRAFLARI VE İFASI</strong>
            </p>
            <p>
                <strong>4.1.</strong>
                Sipariş onayı mailinin {fullname}’nın verdiği e-posta hesabına
                gönderilmesi, stoğun müsait olması ve ürün bedelinin SATICI’nın
                hesabına geçmesiyle birlikte, ürün/ürünler SATICI’nın anlaşmalı
                olduğu kargo şirketine verilir. SATICI, ürünü siparişinden
                itibaren 5 iş günü içinde kargo şirketine teslim eder.{' '}
                {fullname}’ya önceden yazılı olarak veya bir sürekli veri
                taşıyıcıyla bildirmek koşuluyla bu süre en fazla 30 (otuz) gün
                uzatılabilir.
            </p>
            <p>
                <strong>4.2.</strong>
                Ürün/ürünler SATICI’nın anlaşmalı olduğu kargo ile {fullname}
                ’nın adresine teslim edilecektir. Teslimat masrafları {
                    fullname
                }{' '}
                aittir. Sipariş onaylandığında kargo bedava kampanyası yapılması
                durumunda teslimat masrafları SATICI’ya ait olacaktır. Teslim
                anında {fullname}’nın adresinde bulunmaması durumunda dahi
                SATICI, edimini tam ve eksiksiz olarak yerine getirmiş kabul
                edilecektir. Karayolları Taşıma Yönetmeliği md. 43 kapsamında
                kargo şirketinin veri işleme faaliyeti SATICI’nın yetki alanı
                dışındadır.
            </p>
            <p>
                <strong>4.3.</strong>
                Sözleşme konusu ürün, {fullname} dan başka bir kişi/kuruluşa
                teslim edilecek ise, teslim edilecek kişi/kuruluşun teslimatı
                kabul etmemesinden SATICI sorumlu tutulamaz. Bu nedenle,{' '}
                {fullname}
                `nın ürünü geç teslim almasından kaynaklanan her türlü zarar ile
                ürünün kargo şirketinde beklemiş olması ve/veya kargonun
                SATICI’ya iade edilmesinden dolayı oluşan giderler de {fullname}
                ’ya aittir.
            </p>
            <p>
                <strong>4.4.</strong>
                Ürünler, kargo şirketinin adres teslimatı yapmadığı bölgelere
                telefon ihbarlı olarak gönderilir.
            </p>
            <p>
                <strong>4.5. </strong>
                SATICI, ticari imkânsızlık halleri, beklenmeyen haller, mücbir
                sebepler veya nakliyeyi engelleyen hava muhalefeti, ulaşımın
                kesilmesi gibi olağanüstü durumlar nedeni ile sözleşme konusu
                ürünü süresi içinde teslim edemez ise, bu durumu öğrendiği
                tarihten itibaren 3 (üç) gün içinde durumu {fullname}`ya yazılı
                olarak veya kalıcı veri saklayıcısı ile bildirmekle yükümlüdür.
                Bu takdirde {fullname} siparişin iptal edilmesini, sözleşme
                konusu ürünün varsa emsali ile değiştirilmesini ve/veya teslimat
                süresinin engelleyici durumun ortadan kalkmasına kadar
                ertelenmesi haklarından birini kullanabilir. {fullname}'nın
                siparişi iptal etmesi halinde ödediği tutar 14 (ondört) gün
                içinde kendisine iade edilir.
            </p>
            <p>
                <strong>4.6. </strong>
                Herhangi bir nedenle ürün bedeli ödenmez veya banka kayıtlarında
                iptal edilir ise, SATICI ürünün teslimi yükümlülüğünden
                kurtulmuş kabul edilir.
            </p>
            <p>
                <strong>MADDE 5: ALICININ BEYAN VE TAAHHÜTLERİ</strong>
            </p>
            <p>
                <strong>5.1.</strong>
                {fullname}, sözleşmede kendisine yüklenen edimleri mücbir
                sebepler dışında eksiksiz yerine getirmeyi kabul ve taahhüt
                eder.
            </p>
            <p>
                <strong>5.2.</strong>
                {fullname}, sipariş vermekle birlikte işbu sözleşme hükümlerini
                kabul etmiş sayıldığını ve sözleşmede belirtilen ödeme şekline
                uygun ödemeyi yapacağını kabul ve taahhüt eder.
            </p>
            <p>
                <strong>5.3.</strong>
                {fullname}, www.mojue.com.tr internet sitesinden SATICI’nın
                isim, ünvan, açık adres, telefon ve diğer erişim bilgileri,
                satışa konu malın temel nitelikleri, vergiler dahil olmak üzere
                satış fiyatı, ödeme şekli, teslimat koşulları ve masrafları vs.
                satışa konu mal ile ilgili tüm ön bilgiler ve "cayma” hakkının
                kullanılması ve bu hakkın nasıl kullanılacağı, şikayet ve
                itirazlarını iletebilecekleri resmi makamlar vs. konusunda açık,
                anlaşılır ve internet ortamına uygun şekilde bilgi sahibi
                olduğunu bu ön bilgileri elektronik ortamda teyit ettiğini,
                kişisel verilerin işlenmesine yönelik sitemizde bulunan
                aydınlatma metnini okuduğunu kabul ve beyan eder.
            </p>
            <p>
                <strong>5.4. </strong>
                Bir önceki maddeye bağlı olarak {fullname}, ürün sipariş ve
                ödeme koşullarının, ürün kullanım talimatlarının, olası
                durumlara karşı alınan tedbirlerin ve yapılan uyarıların olduğu
                www.mojue.com.tr sipariş/ödeme/kullanım prosedürü bilgilerini
                okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli
                teyidi verdiğini beyan eder.
            </p>
            <p>
                <strong>5.5.</strong>
                {fullname}, sözleşme konusu ürünü teslim almadan önce muayene
                ederek kırık, ezik, ambalajı yırtılmış vb. hasarlı ve ayıplı
                ürünü kargo şirketinden teslim almayarak kargo şirketi
                yetkilisine tutanak tutturmalıdır. Eğer kargo şirketi yetkilisi
                paketin hasarlı olmadığı görüşünde ise, paketin orada açılarak
                ürün/ürünlerin hasarsız teslim edildiğini kontrol ettirme ve
                durumun yine bir tutanakla tespit edilmesini isteme hakkı
                {fullname}’da vardır. Teslim alınan ürünün hasarsız ve sağlam
                olduğu kabul edilecektir. Teslimden sonra ürünün özenle
                korunması borcu, {fullname}’ya aittir. Paket kabul edilmemiş ve
                tutanak tutulmuş ise durum tutanağın {fullname}’da kalan
                kopyasıyla birlikte en kısa zamanda SATICI’ya bildirilmelidir.{' '}
                {fullname}, SATICI’nın belirlediği taşıyıcı dışında başka bir
                taşıyıcı ile ürünün gönderilmesini talep etmesi durumunda,
                ürünün ilgili taşıyıcıya tesliminden itibaren oluşabilecek kayıp
                ya da hasardan SATICI sorumlu değildir
            </p>
            <p>
                <strong>5.6. </strong>
                {fullname}, gösterdiği adrese ve bu adreste teslim sırasında
                bulunan kişiye yapılan teslimatın, {fullname}’ya yapılmış
                teslimat sayılacağını kabul etmektedir.
            </p>
            <p>
                <strong>5.7. </strong>
                Cayma hakkı kullanılacaksa süresi içerisinde fatura ve sevk
                irsaliyesi ile iade edilmelidir. Fatura aslı gönderilmezse
                {fullname}’ya KDV ve varsa diğer yasal yükümlülükler iade
                edilemez.
            </p>
            <p>
                <strong>MADDE 6: SATICININ BEYAN VE TAAHHÜTLERİ</strong>
            </p>
            <p>
                <strong>6.1.</strong>
                SATICI, 6502 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve
                Mesafeli Sözleşmeler Yönetmeliği hükümleri uyarınca mücbir
                haller dışında sözleşme konusu ürünün sağlam, eksiksiz,
                siparişte belirtilen niteliklere uygun ve varsa garanti
                belgeleri ve kullanım kılavuzları ile teslim edilmesinden
                sorumludur. Sözleşme konusu ürün, {fullname}’dan başka bir
                kişi/kuruluşa teslim edilecek ise teslim edilecek kişi/kuruluşun
                teslimatı kabul etmemesinden SATICI sorumlu tutulamaz.
            </p>
            <p>
                <strong>6.2.</strong>
                SATICI, işbu sözleşmenin 10. maddesine uygun olarak ve
                istisnalar haricinde {fullname}’nın hiçbir hukuki ve cezai
                sorumluluk üstlenmeksizin ve hiçbir gerekçe göstermeksizin ürünü
                teslim aldığı veya sözleşmeyi imzaladığı/onayladığı tarihten
                itibaren 14 (ondört) gün içerisinde ürünü reddederek sözleşmeden
                cayma hakkının var olduğunu kabul etmektedir.
            </p>
            <p>
                <strong>6.3.</strong>
                18 (onsekiz) yaşından küçük kişiler www.mojue.com.tr adresinden
                alışveriş yapamaz.
            </p>
            <p>
                <strong>6.4.</strong>
                SATICI, haklı gerekçelerle, sözleşmedeki ifa süresi dolmadan{' '}
                {fullname}'ya eşit kalite ve fiyatta ürünü tedarik edebilir.
                SATICI, ürünün teslimi ifasının imkânsızlaştığını düşünüyorsa,
                sözleşmenin ifa süresi dolmadan {fullname}’ya bildirimde
                bulunmak zorundadır. Bu takdirde {fullname} siparişin iptal
                edilmesini, sözleşme konusu ürünün varsa emsali ile
                değiştirilmesini ve/veya teslimat süresinin engelleyici durumun
                ortadan kalkmasına kadar ertelenmesi haklarından birini
                kullanabilir. {fullname}`nın siparişi iptal etmesi halinde
                ödenen bedel ve varsa belgeler 14 (ondört) gün içinde iade
                edilir.
            </p>
            <p>
                <strong>6.5.</strong>
                www.mojue.com.tr adresinden kredi kartı ile alışveriş
                yapılabilir. Sipariş tarihinden itibaren 1 gün içinde ödemesi
                yapılmayan siparişler iptal edilir. Siparişlerin işleme alınma
                zamanı, siparişin verildiği an değil, kredi kartı hesabından
                gerekli tahsilatın yapıldığı andır. Ödemeli gönderi gibi müşteri
                hizmetleri ile görüşülmeden gerçekleştirilen ödeme yöntemleri
                kabul edilmez.
            </p>
            <p>
                <strong>MADDE 7: SÖZLEŞMEYE KONU ÜRÜNÜN ÖZELLİKLERİ</strong>
            </p>
            <p>
                <strong>7.1.</strong>
                Ürünün cinsi ve türü, miktarı, marka/modeli, rengi ve tüm
                vergiler dâhil satış bedeli www.mojue.com.tr adlı web
                sitesindeki ürünün tanıtım sayfasında yer alan bilgilerde ve
                işbu sözleşmenin ayrılmaz parçası sayılan faturada belirtildiği
                gibidir.
            </p>
            <p>
                <strong>7.2.</strong>
                SATICI, teknik nedenlerden ve sistem hatalarından kaynaklanan
                fiyat yanlışlıklarından sorumlu değildir. Buna istinaden SATICI,
                internet sitesindeki sistemden, dizayndan veya yasadışı yollarla
                internet sitesine yapılabilecek müdahaleler sebebiyle ortaya
                çıkabilecek tanıtım, fiyat hatalarından sorumlu değildir. Sistem
                hatalarına dayalı olarak {fullname} SATICI’dan hak iddiasında
                bulunamaz.
            </p>
            <p>
                <strong>MADDE 8: SİPARİŞ VE ÖDEME PROSEDÜRÜ</strong>
            </p>
            <p>
                <strong>8.1.</strong>
                Ürünün peşin fiyatı fatura içeriğinde mevcuttur. Eğer vadeli
                satış yapılıyor ise satış, satış fiyatına yapılan vadeye göre
                fiyatı, fatura içeriğinde mevcuttur.
            </p>
            <p>
                <strong>8.2.</strong>
                {fullname}, çalıştığı bankaya karşı 9. madde hükmünce
                sorumludur.
            </p>
            <p>
                <strong>8.3.</strong>
                Ürünün peşinat tutarı, fatura içeriğinde mevcuttur.
            </p>
            <p>
                <strong>8.4.</strong>
                Alışveriş sepetine eklenen ürünlerin KDV dahil TL tutarı
                (taksitli işlemlerde toplam taksit tutarları) {fullname}{' '}
                tarafından onaylandıktan sonra, ilgili banka kartının posu
                üzerinden işleme alınır. Bu nedenle siparişler, sevk edilmeden
                önce müşteriye sipariş onay maili gönderilir. Sipariş onay maili
                gönderilmeden sevkiyat yapılmaz.
            </p>
            <p>
                <strong>8.5.</strong>
                Ürünün tesliminden sonra {fullname}’ya ait kredi kartının
                {fullname}’nın kusurundan kaynaklanmayan bir şekilde yetkisiz
                kişilerce haksız veya hukuka aykırı olarak kullanılması nedeni
                ile banka veya finans kuruluşunun ürün bedelini SATICI’ya
                ödememesi halinde, {fullname}’nın kendisine teslim edilmiş ürünü
                10 (on) gün içinde SATICI’ya nakliye giderleri kendisine ait
                olmak kaydı ile göndermesi zorunludur.
            </p>
            <p>
                <strong>MADDE 9: ÖDEME PLANI</strong>
            </p>
            <p>
                <strong>9.1.</strong>
                Vadeli satışların sadece bankalara ait kredi kartları ile
                yapılması nedeniyle, {fullname}, ilgili faiz oranlarını ve
                temerrüt faizi ile ilgili bilgileri bankasından ayrıca teyit
                edeceğini, yürürlükte bulunan mevzuat hükümleri gereğince faiz
                ve temerrüt faizi ile ilgili hükümlerin banka ve {fullname}{' '}
                arasındaki kredi kartı sözleşmesi kapsamında uygulanacağını
                kabul, beyan ve taahhüt eder.
            </p>
            <p>
                <strong>9.2.</strong>
                Taksitlendirme işlemlerinde, {fullname} ile kart sahibi banka
                arasında imzalamış bulunan sözleşmenin ilgili hükümleri
                geçerlidir. Kredi kartı ödeme tarihi banka ile {fullname}{' '}
                arasındaki sözleşme hükümlerince belirlenir. {fullname}, ayrıca
                bankanın gönderdiği hesap özetinden taksit sayısını ve
                ödemelerini takip edebilir.
            </p>
            <p>
                <strong>9.3.</strong>
                ALICI’nın kullandığı kredi kartı veya diğer ödeme seçenekleri
                sürecinde bankanın/ödeme kuruluşunun veri işleme faaliyetleri
                satıcının sorumluluğunda değildir.
            </p>
            <p>
                <strong>MADDE 10: CAYMA HAKKI</strong>
            </p>
            <p>
                <strong>10.1.</strong>
                {fullname}’nın cayma hakkından faydalanabilmesi için; yapılan
                satışın 6502 sayılı Tüketicinin Korunması Hakkındaki Kanun
                Kapsamına giren bir satış sözleşmesine dayanması; iadenin, satın
                alınan ürünü kutusu, ambalajı, varsa standart aksesuarları ile
                birlikte eksiksiz ve hasarsız olarak teslim tarihinden itibaren
                herhangi bir gerekçe göstermeden ve cezai şart ödemeden ürünün
                tesliminden itibaren 14 (ondört) günlük süre içinde
                gerçekleştirilmesi gereklidir. İade gönderilirken ALICI’nın
                kullanması gereken anlaşmalı kargo firmaları ve anlaşma kodları
                Ön Bilgilendirme Formu’nda belirtilmiştir.
            </p>
            <p>
                <strong>10.2.</strong>
                İade işlemlerinin gerçekleşmesi için öncelikli olarak {fullname}
                ’nın fatura alt kısmında bulunan iade bölümünü ve/veya Ek 1’de
                bulunan Cayma Formu’nu doldurması ve yazılı olarak SATICI’ya
                süresi içinde bildirmesi gerekmektedir. Bu bölüm doldurulmadan
                yapılacak iade talepleri kabul edilmeyecektir. Bu hakkın
                kullanılması halinde, 3. kişiye veya {fullname}’ya teslim edilen
                ürüne ilişkin faturanın iadesi zorunludur. ALICI, cayma hakkını
                kullandığına ilişkin bildirimi yönelttiği tarihten itibaren 10
                gün içinde ürünü SATICI’ya geri göndermek zorundadır.
            </p>
            <p>
                <strong>10.3.</strong>
                {fullname}’nın cayma hakkını kullanması halinde SATICI, cayma
                hakkının kullanıldığına ilişkin bildirimin kendisine ulaştığı
                tarihten itibaren 14 gün içinde, varsa ürünün ALICI’ya teslim
                masrafları da dahil olmak üzere tahsil edilen tüm ödemeleri iade
                edecektir. Fatura aslı gönderilmezse, {fullname}’ya KDV ve varsa
                diğer yasal yükümlülükler iade edilemez.
            </p>
            <p>
                <strong>10.4.</strong>
                SATICI, tüm geri ödemeleri, tüketicinin satın alırken kullandığı
                ödeme aracına uygun bir şekilde ve tüketiciye herhangi bir
                masraf veya yükümlülük getirmeden tek seferde yapmak zorundadır.
                SATICI, bir iade işlemi söz konusu olduğunda ilgili yazılım
                aracılığıyla iadesini yapacak olup, SATICI ilgili tutarı bankaya
                nakden veya mahsuben ödemekle yükümlü olduğundan yukarıda
                detayları belirtilen prosedür gereğince {fullname}’ya nakit
                olarak ödeme yapılmamaktadır. Bu tutarın bankaya iadesinden
                sonra ALICI hesaplarına yansıması tamamen banka işlem süreci ile
                ilgili olduğundan, ALICI olası gecikmeler için SATICI’nın
                herhangi bir şekilde müdahalede bulunmasının ve sorumluluk
                üstlenmesinin mümkün olamayacağını şimdiden kabul eder. Kredi
                kartına yapılan iadelerin kredi kart hesaplarına yansıma süresi
                ilgili bankanın tasarrufundadır. Alışveriş kredi kartıyla ve
                taksitli olarak yapılmışsa, ürünü kaç taksit ile aldıysanız,
                banka geri ödemesini taksitle yapmaktadır.
            </p>
            <p>
                <strong>10.5.</strong>
                {fullname}’nın kusurundan kaynaklanan bir nedenle malın
                değerinde bir azalma olursa veya iade imkansızlaşırsa {fullname}{' '}
                kusur oranında SATICI’nın zararını tazmin etmekle yükümlüdür.
            </p>
            <p>
                <strong>MADDE 11: CAYMA HAKKININ İSTİSNALARI</strong>
            </p>
            <p>
                <strong>11.1. </strong>
                Mesafeli Sözleşmeler Yönetmeliği’nin 15. Maddesinde belirtilen
                istisnaların olması durumunda ALICI cayma hakkını kullanamaz.
                <strong></strong>
            </p>
            <p>
                Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu
                unsurları açılmış olan ürünlerden, kullanım esnasında {fullname}
                tarafından vücut ile birebir temas gerektiren iadesi sağlık ve
                hijyen açısından uygun olmayan ürünler (kulaklıklar vs.), tek
                kullanımlık ürünler ve buna benzer ürünler arızalı/ ayıplı
                olması halleri dışında niteliği itibariyle cayma hakkı
                kullanılamayacak ürünler arasında bulunmaktadır.
            </p>
            <p>
                <strong>11.2.</strong>
                ALICI’nın özel istekleri ve kişisel ihtiyaçları doğrultusunda
                üretilen veya üzerinde değişiklik ya da ilaveler yapılarak
                kişiye özel hale getirilen ürünlerde cayma hakkı kullanılamaz.
            </p>
            <p>
                <strong>MADDE 12: TEMERRÜT HALİ VE HUKUKİ SONUÇLARI</strong>
            </p>
            <p>
                {fullname}, kredi kartı ile yapmış olduğu işlemlerinde temerrüde
                düşmesi halinde kart sahibi bankanın kendisi ile yapmış olduğu
                kredi kartı sözleşmesi çerçevesinde faiz ödeyecek ve bankaya
                karşı sorumlu olacaktır. Bu durumda ilgili banka hukuki yollara
                başvurabilir; doğacak masrafları ve vekâlet ücretini
                {fullname}’dan talep edebilir ve her koşulda {fullname}’nın
                borcundan dolayı temerrüde düşmesi halinde, {fullname}, borcun
                gecikmeli ifasından dolayı SATICI’nın oluşan zarar ve ziyanını
                ödemeyi kabul eder.
            </p>
            <p>
                <strong>MADDE 13: GARANTİ</strong>
            </p>
            <p>
                SATICI’nın garanti sorumluluğu yalnızca 6502 sayılı
                Tüketicilerin Korunması Hakkındaki Kanun kapsamına giren
                tüketiciler için geçerlidir. Ticari nitelikteki işler için Türk
                Ticaret Kanunu hükümleri geçerli olacaktır.
            </p>
            <p>
                <strong>MADDE 14: YETKİLİ MAHKEME</strong>
            </p>
            <p>
                İşbu sözleşmenin uygulanmasından kaynaklanan uyuşmazlık halinde
                Ticaret Bakanlığınca ilan edilen değere kadar yetkili Tüketici
                Hakem Heyetleri ve Tüketici Mahkemeleri yetkilidir. 6502 Sayılı
                Tüketicinin Korunması Hakkında Kanun’un 68. Maddesinin 1.
                Fıkrasında belirtilen alt ve üst limitler doğrultusunda tüketici
                talepleri hakkında ilçe/il tüketici hakem heyetleri yetkilidir.
            </p>
            <p>
                <strong> </strong>
            </p>
            <p>
                <strong>
                    MADDE 15: KİŞİSEL VERİ İŞLEME VE GİZLİLİK POLİTİKASI
                </strong>
            </p>
            <p>
                Kişisel bilgilerinizin korunması ve ödeme sırasındaki
                güvenliğiniz bizler için çok önemlidir. Bundan dolayı
                bilgilerinizi titizlikle korumanın önemi ve bilinci ile
                ödemelerinizin kredi kartı ile gerçekleştirilmesi aşamasında, 3D
                Secure sistemini kullanmanızı öneririz. Ödemenin
                gerçekleştirilmesi için gerekli olan bilgiler (Örnek: Kredi kart
                numarası) TESAN tarafından saklanmamaktadır. Bu bilgiler
                aracısız olarak, anlaşmalı olduğumuz bankaya sizin tarafınızdan
                aktarılmaktadır. Tüm kişisel bilgileriniz internet güvenlik
                standardı olan SSL Secure sistemi ile 256 bit şifrelenmiştir. Bu
                şekilde internet üzerindeki dolaşımları sırasında herhangi bir
                şekilde bu bilgilerinizin istenilmeyen kişi veya kurumların
                eline geçirilmesi önlenmiştir. TESAN, müşterilerine daha iyi ve
                kaliteli hizmet verebilmek amacıyla aşağıda yazılı olan kişisel
                bilgileri satış sözleşmesinin ifası için talep etmektedir.
                Müşterilerimizden istenen kişisel bilgiler, siz Müşterilerimize
                hizmet verilebilmesi için gerekli olup, istisnasız tüm
                müşterilerimize ait bu bilgiler kati suretle gizlilik içerisinde
                alınmakta ve sıkı güvenlik tedbirleri altında korunmaktadır.
                Bununla beraber iş sürecimizin sorunsuz ilerlemesi için müşteri
                bilgilerimizin saklanması, kullanımı, işlenmesi kısmen
                gereklidir.
            </p>
            <p>
                Detaylı Kişisel Veri İşleme Politikasına ve aydınlatma
                metinlerine
                https://www.tesan.com.tr/kisisel-verilerin-korunmasi/
                linkinden/web sitemizden ulaşabilirsiniz. Ürün satın aldığınızda
                Ad, soyad, kimlik numarası, adres, telefon, e-posta adresi,
                sipariş bilgileri, alıcı bilgileri, alıcı notları, teslim alacak
                kişi bilgileri, kredi kartının bilgilerini vermeniz
                istenecektir.
            </p>
            <p>
                Sözleşmenin ifası hukuki sebebine dayalı olarak işlenecek olan
                bilgileriniz ancak aşağıda belirtilen amaçlarla kullanılacaktır;
            </p>
            <ul type="disc">
                <li>Kimlik doğrulama ve kayıt oluşturulması amacıyla,</li>
                <li>
                    Siparişlerinizin işleme konulması ve hesabınızın
                    yönetilmesi, ticari faaliyetlerin yerine getirilebilmesi ve
                    sürekliliğinin sağlanabilmesi amacıyla,
                </li>
                <li>
                    Alınan hizmet süreçleri ile alakalı bilgilendirme amacıyla,
                </li>
                <li>Siparişlerin teslimi/iadesi amacıyla,</li>
                <li>Siparişler ile ilgili şikayetlerin çözülmesi amacıyla,</li>
                <li>Teknik servis hizmetinin verilebilmesi amacıyla,</li>
                <li>
                    Önceden onay vermeniz kaydıyla yeni ürünler, kampanyalar ve
                    promosyonlar hakkında bilgi vermek için elektronik ileti
                    gönderilmesi amacıyla,
                </li>
                <li>
                    İşlemlerinizle ilgili istatistiklerin yapılabilmesi ve
                    bağlantılı listelerin oluşturulması, ticari istatistik ve
                    analizlerin bir araya getirilmesi amacıyla,
                </li>
                <li>
                    Ürünlerimizin, hizmetlerimizin ve kişisel seçim
                    olanaklarınızın araştırılması ve geliştirilmesi amacıyla,
                </li>
                <li>
                    Yazılım, kurumsal kaynak planlaması, raporlama, pazarlama
                    vs. gibi işlevlerin yerine getirilmesi amacıyla,
                </li>
                <li>Promosyon ve hediye verilebilmesi amacıyla,</li>
                <li>
                    Gerekli kalite, gizlilik ve standart denetimlerinin
                    yapabilmesi amacıyla,
                </li>
                <li>
                    Kanun ve yönetmelikler ile belirlenmiş gerekliliklerin ifa
                    edilmesi amacıyla (vergi mevzuatı, tüketicilerin korunmasına
                    yönelik mevzuat, borçlar hukuku mevzuatı, ticaret hukuku
                    mevzuatı, gümrük mevzuatı, elektronik iletişim ile ilgili
                    mevzuat vs. ilgili tüm mevzuatlar),
                </li>
                <li>
                    e-fatura, e-arşiv ve e-irsaliye ile ilgili yükümlülüklerin
                    yerine getirilmesi amacıyla ,
                </li>
                <li>
                    Yasal düzenlemelerin gerektirdiği veya zorunlu kıldığı
                    şekilde kamu kurum ve kuruluşlarının taleplerinin yerine
                    getirilmesi amacıyla,
                </li>
                <li>
                    KVKK’da belirtilmiş yasal yükümlülüklerin yerine getirilmesi
                    amacıyla,
                </li>
            </ul>
            <p>
                Kanunların emredici hükümleri gereği gerçek kişilerin
                bilgilerinin doğru ve güncel tutulması esastır. Bu nedenle,
                belirli zaman aralıkları ile kişisel bilgilerinizin
                güncellenmesi talep edilebilir. Sisteme girdiğiniz tüm bilgilere
                sadece sizler ulaşabilmekte ve bilgileri sadece sizler
                değiştirebilmektesiniz. TESAN, hiçbir koşulda tarafınızdan
                sunulan yanlış bilgiden sorumlu tutulamaz.
            </p>
            <p>
                TESAN’ın sahibi olduğu internet sitelerinde; kişilerin
                sitelerdeki ziyaretlerini amaçlarıyla uygun bir şekilde
                gerçekleştirmelerini temin etmek; kendilerine özelleştirilmiş
                içerikler gösterebilmek ve çevirim içi reklamcılık
                faaliyetlerinde bulunabilmek maksadıyla teknik vasıtalarla (Örn:
                cookie uygulaması gibi) site içerisindeki internet hareketleri
                kaydedilmektedir. Şirketimizin Çerez Politikasına ve çerezlerin
                nasıl yönetileceğine dair bilgiye
                <a href="https://www.tesan.com.tr/cerez-politikasi/">
                    https://www.tesan.com.tr/cerez-politikasi/
                </a>
                internet sitemizden ulaşabilirsiniz.
            </p>
            <p>
                TESAN’ın kurumsal e-posta hesapları Microsoft tarafından Türkiye
                dışından sunulmaktadır. Şirket uzantılı e-posta üzerinden
                yazışmanız halinde kişisel verilerinizin bilişim sisteminin
                yapısı nedeni ile Türkiye dışında işleneceği bilinmelidir.
                Ayrıca müşterilerimizin sistemimize bildirdikleri şahsi e-posta
                hesapları da yurt dışından sunulduğu takdirde verilerin yurt
                dışında işlendiği bilinmelidir.
            </p>
            <p>
                İşlenen kişisel verileriniz zorunlu olan bilgiler ile sınırlı
                olmak kaydı ile;
            </p>
            <ul type="disc">
                <li>
                    Siparişlerinizin işleme konulması, hesabınızın yönetilmesi,
                    ticari faaliyetlerin yerine getirilebilmesi ve
                    sürekliliğinin sağlanabilmesi amacıyla hizmet
                    sağlayıcılarıyla,
                </li>
                <li>
                    Siparişlerin teslim/iadesi amacıyla lojistik ve kargo
                    firmalarıyla,
                </li>
                <li>
                    Yazılım, kurumsal kaynak planlaması, raporlama, pazarlama
                    vb. gibi işlevlerin yerine getirilmesi amacıyla
                    tedarikçilerle ve çözüm ortaklarıyla,
                </li>
                <li>
                    Ödeme/tahsilat hizmetlerinin yerine getirilmesi amacıyla
                    bankalar ve ödeme aracılık sistemleri şirketleriyle,
                </li>
                <li>
                    Gerekli kalite, gizlilik ve standart denetimlerinin
                    yapılabilmesi amacıyla denetim firmaları ve bilgi güvenliği
                    firmalarıyla,
                </li>
                <li>
                    TESAN’ın kullandığı bilişim teknolojileri gereği bulut
                    bilişim hizmetini veren firmalarla,
                </li>
                <li>
                    TESAN tarafından kullanılan ERP vs. diğer bilişim
                    sistemlerinin işlerliği ve güvenirliliği açısından ilgili
                    tedarikçilerle ve iş ortaklarıyla,
                </li>
                <li>
                    İlgili mevzuat hükümlerine istinaden yükümlülüklerimizin
                    yerine getirilmesi ve ticari faaliyetlerimizin denetimi
                    amacıyla ilgili denetim firmalarıyla, özel entegratör
                    firmalarıyla, bağımsız denetim firmalarıyla, gümrük
                    firmalarıyla, mali müşavir/muhasebe firmalarıyla, hukuk
                    bürolarıyla,
                </li>
                <li>
                    Promosyon ve hediye verilebilmesi amacıyla ilgili iş
                    ortakları ve tedarikçilere,
                </li>
                <li>TESAN’ın ortakları/hissedarlarıyla,</li>
                <li>
                    Yasal gereklilikleri ifa etmek ve/veya resmi mercilerin
                    taleplerini yerine getirmek amacıyla kamu kurum ve
                    kuruluşlarıyla.
                </li>
            </ul>
            <p>
                Kanunen açık rıza alınması gereken hallerde açık rıza alınarak
                (kanunen açık rıza alınması gerekli olmayan durumlar hariç olmak
                kaydı ile) ve kanun ile belirlenmiş koşullar çerçevesinde
                aktarılabilecektir.
            </p>
            <p>
                <a name="_Hlk511046558"></a>
                <a name="_Hlk510174176">
                    TESAN, müşterilerine ait kişisel bilgileri ticari ilişki
                    devam ettiği sürece yukarıdaki amaçlar doğrultusunda
                    saklayacaktır. TESAN, ticari ilişki sona erdikten sonra Türk
                    Ticaret Kanuna göre 10 yıl boyunca verileri saklamakla
                    yükümlüdür. Süre geçtikten sonra kişisel verileriniz ilk
                    imha süresinde Kişisel Veri Saklama ve İmha Politikası
                    uyarınca silinecek, yok edilecek ve/veya anonim hale
                    getirilecektir. Müşteri, ilgili kanunun 5. maddesi 2.
                    fıkrasındaki şartlar ve kanunen işlenmesi gereken veriler
                    hariç olma kaydı ile kişisel verilerinin işlenmesi için
                    verdiği izni her zaman geri alabilir.
                </a>
            </p>
            <p>
                KVKK kapsamında, kişisel verilerinize ilişkin olarak aşağıdaki
                haklara sahipsiniz;
            </p>
            <ul type="disc">
                <li>Kişisel verilerin işlenip işlenmediğini öğrenme,</li>
                <li>
                    Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
                </li>
                <li>
                    Kişisel verilerin işlenme amacını ve bunların amacına uygun
                    kullanılıp kullanılmadığını öğrenme,
                </li>
                <li>
                    Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                    üçüncü kişileri bilme,
                </li>
                <li>
                    Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
                    bunların düzeltilmesini isteme,
                </li>
                <li>
                    Kişisel verinizin işlenmesini gerektiren sebeplerin ortadan
                    kalkması hâlinde, verilerin silinmesini veya yok edilmesini
                    isteme,
                </li>
                <li>
                    Talebinizce düzeltilen ya da silinen bilgilerinizin, eğer
                    aktarılmış ise kişisel verilerin aktarıldığı üçüncü kişilere
                    bildirilmesini isteme,
                </li>
                <li>
                    İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                    analiz edilmesi suretiyle kişinin kendisi aleyhine bir
                    sonucun ortaya çıkmasına itiraz etme,
                </li>
                <li>
                    Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                    zarara uğraması hâlinde zararın giderilmesini talep etme.
                </li>
            </ul>
            <p>
                Yukarıda belirtilen haklarınızı kullanmak için kimliğinizi
                tespit edici gerekli bilgiler (yalnızca bizde olduğunu
                düşündüğünüz veriler ile başvurunuz, bizde olmayan verileri
                sunduğunuz takdirde bunun yeni bir veri işleme faaliyeti
                olduğunu göz önünde bulundurunuz.) ve kullanmak istediğiniz
                hakkınıza yönelik açıklamalarınızla birlikte yazılı talebinizi
                “Çobançeşme Mah. Bilge 1 Sok. No:17 Yenibosna
                Bahçelievler/İstanbul” adresine konunun KVKK ile ilgili olduğunu
                açıkça belirterek ıslak imzalı olarak, veya tesan@hs01.kep.tr
                kayıtlı elektronik posta adresimize güvenli elektronik imza,
                mobil imza ya da ilgili kişi tarafından TESAN’a daha önce
                bildirilen ve veri sorumlusunun sisteminde kayıtlı bulunan
                elektronik posta adresini kullanmak suretiyle elektronik posta
                ile <a href="">kvkk@tesan.com.tr</a> adresine gönderebilirsiniz.
                Başvurular Türkçe yapılmalıdır. Başvuru sürecine ilişkin
                detaylara KVKK md 13’ten ulaşabilirsiniz.
            </p>
            <p>
                Başvurularda, ad, soyad ve başvuru yazılı ise imza, tebligata
                esas yerleşim yeri veya işyeri adresi, varsa bildirime esas
                elektronik posta adresi, telefon numarası ve talep konusu
                bulunması zorunludur. Başvurucu sayısı birden fazla olduğu
                takdirde TESAN ek doğrulama talep edebilir.
            </p>
            <p>
                Kişisel veri sahibi olarak sahip olduğunuz ve yukarıda
                belirtilen haklarınızı kullanmak için yapacağınız ve kullanmayı
                talep ettiğiniz hakka ilişkin açıklamalarınızı içeren başvuruda;
                talep ettiğiniz hususun açık ve anlaşılır olması, talep
                ettiğiniz konunun şahsınız ile ilgili olması veya başkası adına
                hareket ediyor iseniz bu konuda özel olarak yetkili olmanız ve
                yetkinizi belgelendirilmesi, başvurunun kimlik ve adres
                bilgilerini içermesi ve başvuruya kimliğinizi tevsik edici
                belgelerin eklenmesi gerekmektedir. Tarafımızda olmadığını
                düşündüğünüz verilerinizi bizle paylaşmayınız, paylaşırsanız
                yeni bir veri işleme faaliyeti olacağını unutmayınız.
                Kimliğinizi kontrol amacı ile istenilen bilgilerden tarafımızda
                olduğunu bildiğiniz/düşündüğünüz veriler ile tarafımıza
                ulaşınız.
            </p>
            <p>
                Bu kapsamda yapacağınız başvurular en kısa sürede ve en geç 30
                gün içerisinde sonuçlandırılacaktır. İlgili kişinin başvurusuna
                yazılı olarak cevap verilecekse, on sayfaya kadar ücret alınmaz.
                On sayfanın üzerindeki her sayfa için 1 TL işlem ücreti
                alınabilir. Başvuruya cevabın CD, flaş bellek gibi bir kayıt
                ortamında verilmesi halinde TESAN tarafından talep edilebilecek
                ücret kayıt ortamının maliyetini geçemez.
            </p>
            <p>
                <strong>MADDE 16: YÜRÜRLÜK</strong>
            </p>
            <p>
                Site üzerinden verilen siparişe ait ödemenin gerçekleşmesi
                durumunda {fullname} işbu sözleşmenin tüm koşullarını kabul
                etmiş sayılacaktır. SATICI, söz konusu sözleşmenin site
                üzerinde,
                {fullname} tarafından okunduğuna ve kabul edildiğine dair onay
                almaksızın sipariş verilememesini sağlayacak yazılımsal
                düzenlemeleri yapmakla yükümlüdür.
            </p>
            <p>
                <strong>TARAFLAR</strong>
            </p>
            <p>
                <strong>SATICI: Tesan İletişim AŞ.</strong>
            </p>
            <p>
                <strong>Adres:</strong>
                {saticiAdres}
            </p>
            <p>
                <strong>Telefon:</strong> {saticiTelefon}
            </p>
            <p>
                <strong>Mersis No:</strong>
                0840004806900010
            </p>
            <p>
                <strong>ALICI:</strong>
                {fullname}
            </p>
            <p>
                <strong>T.C. Kimlik No: </strong>
                {props.shippingAddress?.tcKimlikNo}
            </p>
            <p>
                <strong>E-Posta Adresi: </strong>
                {(props.userInfo && props.userInfo.email) == 'undefined'
                    ? props.unregisterEmail?.unregisterMail
                    : props.userInfo.email}
            </p>
            <p>
                <strong>Adres: </strong>
                {props.shippingAddress?.address2}
            </p>
            <p>
                <strong>Telefon: </strong>
                {props.shippingAddress?.phoneNumber}
            </p>
            <p>
                <strong>Tarih: </strong>
                {new Date().toLocaleDateString('tr')}
            </p>
        </>
    );
};

export default SalesAgreement;
