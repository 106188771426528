import React from 'react';
import { cargoPriceFree } from '../../config/env';

const SiteAdvantage = (props) => {
    return (
        <section className="site-advantage">
            <div className="container">
                <div className="row">
                    <div className="d-none d-sm-block">
                        <h1 className="fw-bold text-primary mb-4">mojue</h1>
                        <p>
                            2018 yılında İstanbul'da doğan mojue, uygun fiyat ve
                            fonksiyonelliği birleştiren kaliteli mobil
                            aksesuarları üstün hizmet anlayışı ile müşterilerine
                            sunmayı misyon edinmiştir. mojue, kurulduğu 1983
                            yılından bu yana müşteri odaklı ve yenilikçi
                            vizyonuyla Türkiye’nin katma değerli teknoloji
                            distribütörü TESAN İLETİŞİM A.Ş’nin markasıdır.
                        </p>
                    </div>
                    <div className="row g-sm-4 g-3">
                        <div className="col-sm-3">
                            <div className="advantage">
                                <div className="icon">
                                    <img
                                        src="assets/images/freecargo.svg"
                                        alt="..."
                                        className="convert-turquoise"
                                    />
                                </div>
                                <div className="data">
                                    <div className="title">ÜCRETSİZ KARGO</div>
                                    <div className="description text-black-50">
                                        {cargoPriceFree} TL ve üzeri
                                        alışverişlerde KARGO BEDAVA!
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="advantage">
                                <div className="icon">
                                    <img
                                        src="assets/images/installment.svg"
                                        alt="..."
                                        className="convert-turquoise"
                                    />
                                </div>
                                <div className="data">
                                    <div className="title">TAKSİT SEÇENEĞİ</div>
                                    <div className="description text-black-50">
                                        Tüm alışverişlerde vade farksız 6
                                        taksite kadar ödeme imkanı.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="advantage">
                                <div className="icon">
                                    <img
                                        src="assets/images/fastcargo.svg"
                                        alt="..."
                                        className="convert-turquoise"
                                    />
                                </div>
                                <div className="data">
                                    <div className="title">
                                        24 SAATTE KARGODA
                                    </div>
                                    <div className="description text-black-50">
                                        Siparişleriniz en geç (Hafta Sonu hariç)
                                        24 saat içerisinde kargoya verilir.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="advantage">
                                <div className="icon">
                                    <img
                                        src="assets/images/securepayment.svg"
                                        alt="..."
                                        className="convert-turquoise"
                                    />
                                </div>
                                <div className="data">
                                    <div className="title">GÜVENLİ ÖDEME</div>
                                    <div className="description text-black-50">
                                        256 Bit SSL şifreleme ile tüm
                                        ödemeleriniz güvenli.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SiteAdvantage;
