import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE } from '../../config/env';
import Cookies from 'universal-cookie';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';
import {
    AddItemToBasket,
    createBasket,
    getCurrentBasket
} from '../../redux/actions/BASKET/BasketCookieActions';
import { BrowserView, MobileView } from 'react-device-detect';

const HomePageProductModal = (props) => {
    const productId = props.openProductId;

    //Ürünün ayrıntılı özelliklerini burada çekiyoruz. modal açıldığı zaman.
    const [productState, setProductState] = useState({});
    const [show, setShow] = useState(false);
    const [isCustomerLogin, setIsCustomerLogin] = useState(false);
    const [variantPicture, setVariantPicture] = useState([]);
    const [selectedVariant, setSelectedVariant] = useState('');
    const [firstVariantImg, setFirtsVariantImg] = useState('');
    const [sepetButtonDisable, setSepetButtonDisable] = useState(false);
    const handleClose = () => {
        setProductState({});
        setShow(false);
        props.handleClose();
    };
    const handleShow = () => setShow(true);
    useEffect(() => {
        setShow(props.show);
        setSepetButtonDisable(false);
    }, [props.show]);
    useEffect(() => {
        if (props.userInfo.customerId) {
            setIsCustomerLogin(true);
        }
        setSepetButtonDisable(false);
        if (productId) {
            const config = {
                headers: { 'Content-Type': 'application/json' }
            };

            axios
                .get(
                    `${API_BASE}Product/GetHomePageProductById?id=${productId}`,
                    config
                )
                .then((data) => {
                    setProductState({ ...data.data.set });
                    const stockList = data.data.set.stockList;

                    for (let index = 0; index < stockList.length; index++) {
                        const stock = stockList[index];
                        //console.log(stock);
                        if (stock.quantity !== 0) {
                            setSepetButtonDisable(false);
                            break;
                        } else {
                            setSepetButtonDisable(true);
                        }
                    }
                    if (stockList.length < 1) {
                        setSepetButtonDisable(true);
                    }
                });
                
        }
    }, [props.openProductId]);

    const productAttributeValueMappings =
        productState.product &&
        productState.product.productProductAttributeValueMappings.filter(
            (pa) => pa.isActive == true
        );
    const groups = [];
    const idList = [];
    const cookies = new Cookies();
    const [basket, setBasket] = useState({
        basketId: 0,
        basketProducts: [
            {
                productId: '',
                productName: '',
                productImage: '',
                discountPrice: 0,
                price: 0,
                quantity: 1,
                variantInfo: [
                    {
                        productAttributeId: 0,
                        productAttributeValue: '',
                        productAttributeValueId: 0,
                        productAttributeValueValue: '',
                        price: 0.0,
                        stockId: '',
                        variantPictureId: 0,
                        variantPicturePath: ''
                    }
                ]
            }
        ]
    });

    productAttributeValueMappings &&
        productAttributeValueMappings.reduce(
            (
                pavm,
                {
                    id,
                    productId,
                    productAttributeValueId,
                    productAttributeValue
                }
            ) => {
                const attributeName =
                    productAttributeValue.productAttribute.name;
                if (!groups[attributeName]) groups[attributeName] = [];

                if (idList.indexOf(productAttributeValueId) === -1) {
                    idList.push(productAttributeValueId);
                    groups[attributeName].push({
                        productAttributeValue,
                        productProductAttributeValueId: id
                    });
                    groups[attributeName]
                        .sort((a, b) =>
                            a.productAttributeValue.id >
                            b.productAttributeValue.id
                                ? 1
                                : -1
                        )
                        .sort((a) =>
                            a.productAttributeValue.id == 38 ? -1 : 1
                        );
                }
            },
            {}
        );
    //const firstImageVirtualPath = null;


    const keys = Object.keys(groups);
    useEffect(() => {
        //setSepetButtonDisable(false);
        setFirtsVariantImg('');
        setSelectedVariant('');
        setVariantStockState({
            stock: {},
            otherVariants: []
        });
        setVariantStock({
            barcode: '',
            discount: null,
            discountedPrice: null,
            id: 0,
            price: null,
            productId: 0,
            productProductAttributeValueMappings: [],
            quantity: 0,
            stockId: '',
            wrkStockId: 0
        });

        if (keys) {
            if (groups[keys[0]]) {
                const config = {
                    headers: { 'Content-Type': 'application/json' }
                };

                axios
                    .get(
                        `${API_BASE}Category/GetFirstVariantImage?productId=${
                            productState.product.id
                        }&variantId=${
                            groups[keys[0]][0].productAttributeValue.id
                        }`,
                        config
                    )
                    .then((data) => {
                        setFirtsVariantImg(data.data.set);
                    });
                variantClick(groups[keys[0]][0].productAttributeValue.id);
            }
        }
        if(firstVariantImg == '' || firstVariantImg == null){
            setFirtsVariantImg(productState.product && productState.product.productPictureMappings ? productState.product.productPictureMappings[0].picture.virtualPath:'assets/images/noimage.jpg');
        }
    }, [productState]);
    const firstImageUrl =
    firstVariantImg == null
            ? 'assets/images/noimage.jpg'
            : `${firstVariantImg}`;
    const [variantStockState, setVariantStockState] = useState({
        stock: {},
        otherVariants: [],
        clickedVariantId: 0
    });
    const [variantStock, setVariantStock] = useState({
        barcode: '',
        discount: null,
        discountedPrice: null,
        id: 0,
        price: null,
        productId: 0,
        productProductAttributeValueMappings: [],
        quantity: 0,
        stockId: '',
        wrkStockId: 0
    });

    function variantClick(clickedVariant) {
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };

        axios
            .get(
                `${API_BASE}Category/GetFirstVariantImage?productId=${productState.product.id}&variantId=${clickedVariant}`,
                config
            )
            .then((data) => {
                setFirtsVariantImg(data.data.set);
            });

        setSelectedVariant(clickedVariant);
        //todo: Test için kapatıldı.
        // axios
        //   .get(
        //     `${API_BASE}Product/GetProductStockMapping?id=${clickedVariant}&productId=${productState.product.id}`,
        //     config
        //   )
        //   .then((data) => {
        //     setVariantStockState({
        //       ...data.data.set,
        //       clickedVariantId: clickedVariant,
        //     });

        //     axios
        //       .get(
        //         `${API_BASE}Order/GetStockById?stockId=${data.data.set.stock.id}`,
        //         config
        //       )
        //       .then((data) => {
        //         setVariantStock({ ...data.data.set });
        //       });
        //   });

        axios
            .get(
                `${API_BASE}Product/GetProductStockMapping?id=${clickedVariant}&productId=${productState.product.id}`,
                config
            )
            .then((data) => {
                setVariantStockState({
                    ...data.data.set,
                    clickedVariantId: clickedVariant
                });
                axios
                    .get(
                        `${API_BASE}Order/GetStockById?stockId=${data.data.set.stock.id}`,
                        config
                    )
                    .then((data) => {
                        setVariantStock({ ...data.data.set });
                        if (data.data.set.quantity == 0) {
                            setSepetButtonDisable(true);
                        } else {
                            setSepetButtonDisable(false);
                        }
                        axios
                            .get(
                                `${API_BASE}Product/GetVariantPictureById?productId=${productState.product.id}&attributeValueId=${clickedVariant}`,
                                config
                            )
                            .then((data) => {
                                setVariantPicture(
                                    data.data.set.variantPictureList
                                );
                            });
                    });
            });

        if (selectedVariant != clickedVariant) {
            setSelectedVariant(clickedVariant);
        }
    }

    function addToBasketHandler() {
        var dprice = productState.product.prices.filter((dp) => dp.type == 2);
        window.dataLayer.push({
            event: 'add_to_cart',
            currency: 'TRY',
            value: '',
            addtocart: {
                items: [
                    {
                        item_name: productState.product.name,
                        item_id: productState.product.productId,
                        currency: 'TRY',
                        discount:
                            dprice.length == 1
                                ? productState.product.prices[0].value -
                                  dprice[0].value
                                : '',
                        price:
                            dprice.length == 1
                                ? dprice[0].value
                                : productState.product.prices[0].value,
                        quantity: 1
                    }
                ]
            }
        });
        if (!variantStockState.stock) {
            alert('Stoklarda yok');
            return;
        }

        if (productState.productAttributeValues.length > 0) {
            if (!variantStockState.clickedVariantId) {
                alert('Varyant Seçiniz!');
                return false;
            }

            if (variantStockState.stock) {
                if (variantStockState.stock.quantity == 0) {
                    alert('Stok Yeterli Değil');
                    return false;
                }
            }
        }

        if (isCustomerLogin === true) {
            const prices = productState.product.prices;

            const cartPrice = prices.filter((p) => p.type === 4);

            const discountPrices = prices.filter((p) => p.type === 2);

            const normalPrices = prices.filter((p) => p.type === 1);
            const normalPrice =
                normalPrices && normalPrices.length > 0
                    ? normalPrices[0].value
                    : 0;
            var discountPrice =
                discountPrices && discountPrices.length > 0
                    ? discountPrices[0].value
                    : normalPrice;

            if (cartPrice && cartPrice.length > 0) {
                discountPrice = cartPrice[0].value;
            }

            if (props.userInfo.isEmployee == true) {
                //discountPrice = normalPrice / 2;
                discountPrice = personelPrices && personelPrices.length > 0 ? personelPrices[0].value : normalPrice;
            }
            const productPicture = variantPicture[0]
                ? variantPicture[0].picture.virtualPath
                : productState.product.productPictureMappings[0].picture
                      .virtualPath;

            const addToCartServiceUrl = 'basket/AddProductToBasket';

            const config = {
                headers: { 'Content-Type': 'application/json' }
            };
            const body = JSON.stringify({
                BasketId: basket != null || basket != {} ? basket.basketId : 0,
                CustomerId: props.userInfo.customerId,
                productId: productState.product.id,
                stockId: variantStockState.stock.id
                    ? variantStockState.stock.id
                    : productState.stockList[0].id,
                productName: productState.product.name,
                productShortDesc: productState.product.shortDescription,
                productImage: productPicture,
                discountPrice: Number(discountPrice),
                price: Number(normalPrice),
                quantity: addCount,
                variantInfo: {
                    productAttributeId: 0,
                    productAttributeValue: '',
                    productAttributeValueId: variantStockState.clickedVariantId,
                    productAttributeValueValue:
                        variantStockState.stock
                            .productProductAttributeValueMappings &&
                        variantStockState.stock
                            .productProductAttributeValueMappings[0]
                            .productAttributeValue.value,
                    price: 0.0,
                    //stockId: variantStockState.stock.id,
                    stockId: variantStockState.stock.id
                        ? variantStockState.stock.id
                        : productState.stockList[0].id,
                    //variantPictureId: product.productPictureMappings[0].picture.id,
                    variantPictureId:
                        variantStockState.stockPicture != null
                            ? variantStockState.stockPicture.id
                            : 0,
                    variantPicturePath:
                        variantStockState.stockPicture &&
                        variantStockState.stockPicture.virtualPath
                }
            });
            axios
                .post(`${API_BASE}${addToCartServiceUrl}`, body, config)
                .then((data) => {
                    setBasket(data.data.set);
                    props.showToast(productState.product.name);
                    setTimeout(function () {
                        window.location.reload();
                    }, 2000);
                })
                .catch((error) => alert(error));
        } else {
            //Kullanıcı giriş yapmamış hali. Bütün işlemler sepet üzerinde hareket eder.
            const basket = getCurrentBasket();

            const prices = productState.product.prices;

            const cartPrice = prices.filter((p) => p.type === 4);

            const discountPrices = prices.filter((p) => p.type === 2);

            const normalPrices = prices.filter((p) => p.type === 1);
            const normalPrice =
                normalPrices && normalPrices.length > 0
                    ? normalPrices[0].value
                    : 0;
            var discountPrice =
                discountPrices && discountPrices.length > 0
                    ? discountPrices[0].value
                    : normalPrice;

            if (cartPrice && cartPrice.length > 0) {
                discountPrice = cartPrice[0].value;
            }

            if (props.userInfo.isEmployee == true) {
                //discountPrice = normalPrice / 2;
                discountPrice = personelPrices[0].value;
            }

            var productPictures = null;

            if (productState.productAttributeValues.length > 0) {
                productPictures = variantPicture[0]
                    ? variantPicture[0].picture.virtualPath
                    : productState.product.productPictureMappings[0].picture
                          .virtualPath;
            } else {
                productPictures =
                    productState.product.productPictureMappings[0].picture
                        .virtualPath;
            }

            const productPicture = productPictures;
            const basketItem = {
                productId: productState.product.id,
                stockId: variantStockState
                    ? variantStockState.stock.id
                    : productState.stockList[0].id,
                productName: productState.product.name,
                productShortDesc: productState.product.shortDescription,
                productImage: productPicture,
                discountPrice:
                    discountPrice != null ? discountPrice : normalPrice,
                price: normalPrice,
                quantity: addCount,
                variantInfo: {
                    productAttributeId: 0,
                    productAttributeValue: '',
                    productAttributeValueId: variantStockState.clickedVariantId,
                    productAttributeValueValue:
                        variantStockState.stock
                            .productProductAttributeValueMappings &&
                        variantStockState.stock
                            .productProductAttributeValueMappings[0]
                            .productAttributeValue.value,
                    price: 0.0,
                    stockId: variantStockState
                        ? variantStockState.stock.id
                        : productState.stockList[0].id,
                    variantPictureId:
                        productState.product.productPictureMappings[0].picture
                            .id,
                    variantPicturePath:
                        variantStockState.stockPicture &&
                        variantStockState.stockPicture.virtualPath
                }
            };

            if (basket === undefined) {
                createBasket(basketItem);
            } else {
                //updateBasket
                AddItemToBasket(basketItem, addCount);
            }
            props.showToast(productState.product.name);
            setTimeout(function () {
                window.location.reload();
            }, 1000);
        }
    }
    const [addCount, setaddCount] = useState(1);
    function minusClicked() {
        if (addCount > 1) {
            var count = addCount;
            count--;
            setaddCount(count);
        }
    }
    function plusClicked() {
        var count = addCount;
        count++;
        setaddCount(count);
    }

    const prices =
        productState.product &&
        productState.product.prices.filter((p) => p.type == 1);
    const personelPrices =
        productState.product &&
        productState.product.prices.filter((p) => p.type == 3);
    const normalPrice = prices && prices[0].value;
    var productPrice = '';
    var discountPrice;
    var cartPrice;
    if (props.userInfo && props.userInfo.isEmployee == true) {
        productPrice =
            personelPrices != null
                ? personelPrices[0] == null
                    ? normalPrice
                    : personelPrices[0].value
                : normalPrice;
        discountPrice = productPrice;
    } else {
        productPrice = normalPrice;
        const disPrices =
            productState.product &&
            productState.product.prices.filter((p) => p.type == 2);
        const cartPrices =
            productState.product &&
            productState.product.prices.filter((p) => p.type === 4);
        discountPrice =
            disPrices && disPrices[0] != null
                ? disPrices[0].value
                : normalPrice;
        cartPrice =
            cartPrices && cartPrices.length > 0 ? cartPrices[0].value : 0;
    }

    return (
        <Modal
            centered
            aria-labelledby="quickAddModal"
            aria-hidden="true"
            backdrop="static"
            show={show}
            onHide={handleClose}
            id="quickAddModal"
            dialogClassName="add-to-cart-modal"
            contentClassName="overflow-hidden rounded-3"
        >
            <Modal.Header
                className="position-absolute"
                closeButton
            ></Modal.Header>
            <Modal.Body>
                {!productState.product ? (
                    <Skeleton
                    width={'100%'}
                    height={30}
                    borderradius={16}
                />
                )
                :
                (
                    ''
                )
                }
                <div className="product">
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="product-image d-flex align-items-center justify-content-center h-100">
                                {
                                productState.product ? 
                                <img
                                    className="img-fluid"
                                    src={firstImageUrl}
                                    alt="..."
                                />
                                : ''
                                }
                                
                            </div>
                        </div>
                        <div className="col-sm-7">
                            <div className="product-detail d-flex flex-column">
                                <h6 className="fw-bold">
                                    {productState.product &&
                                        productState.product.name}
                                </h6>
                                <div className="d-flex flex-column price">
                                    <h4 className="fw-bold text-primary">
                                        {productState.product && discountPrice &&
                                            discountPrice.toFixed(2) + ` TL`
                                            }
                                    </h4>
                                    {/* Sepet Fiyatı Olduğunda Görünecek Alan */}
                                    <span className="text-success fw-normal">
                                        {productState.product && cartPrice && cartPrice != 0
                                            ? `Sepet'te ` +
                                              cartPrice.toFixed(2) +
                                              ` TL`
                                            : ''}
                                    </span>
                                </div>
                                {keys &&
                                    keys.map((k) => (
                                        <div
                                            key={Math.random() * 1000 + k}
                                            className="optionGroup d-flex flex-column gap-2"
                                        >
                                            <div className="variantGroup text-black-50 small fw-bold">
                                                <span className="me-1">
                                                    Renk Seçiniz
                                                </span>
                                                <i className="bi bi-chevron-down"></i>
                                            </div>
                                            <div
                                                className={
                                                    k === 'Colors'
                                                        ? 'isVariant circle colors justify-content-start flex-wrap'
                                                        : 'isVariant button'
                                                }
                                            >
                                                {groups[k].map((v) => (
                                                    <Link
                                                        to="#"
                                                        key={
                                                            Math.random() *
                                                                1000 +
                                                            v
                                                                .productAttributeValue
                                                                .id
                                                        }
                                                        name={
                                                            v
                                                                .productAttributeValue
                                                                .id
                                                        }
                                                        className={`variantItem ${
                                                            selectedVariant ==
                                                            v
                                                                .productAttributeValue
                                                                .id
                                                                ? 'selected'
                                                                : ''
                                                        }`}
                                                        clicked={'false'}
                                                        title={
                                                            v
                                                                .productAttributeValue
                                                                .value
                                                        }
                                                        style={
                                                            v
                                                                .productAttributeValue
                                                                .imageBinary ==
                                                            null
                                                                ? {
                                                                      background:
                                                                          v
                                                                              .productAttributeValue
                                                                              .hexCode
                                                                  }
                                                                : {
                                                                      backgroundImage: `url(data:image/png;base64,${v.productAttributeValue.imageBinary})`,
                                                                      backgroundSize:
                                                                          24 +
                                                                          'px'
                                                                  }
                                                        }
                                                        onClick={() =>
                                                            variantClick(
                                                                v
                                                                    .productAttributeValue
                                                                    .id
                                                            )
                                                        }
                                                    ></Link>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                    {productState.product ? ( 
                                        <button
                                        type="button"
                                        className={`add-to-cart-category btn p-3 btn-primary rounded-2 ${
                                            sepetButtonDisable == true
                                                ? 'disabled'
                                                : ''
                                        }`}
                                        id="addToCart"
                                        onClick={() => addToBasketHandler()}
                                    >
                                        {sepetButtonDisable ? (
                                            'Stokta Yok'
                                        ) : (
                                            <div className="d-flex align-items-center">
                                                <i className="bi bi-cart3 me-2"></i>
                                                <span className="text-start fw-bold">
                                                    Sepete Ekle
                                                </span>
                                            </div>
                                        )}
                                    </button>
                                    ) : (
                                        '' 
                                    )}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default HomePageProductModal;
