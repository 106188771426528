import React, { Component, useEffect } from 'react';
import FeaturedProductContent from './SubComponents/FeaturedProductContent';
import Skeleton from 'react-loading-skeleton';

const FeaturedProducts = (props) => {
    const handleProductId = (id) => {
        props.openedProductId(id);
    };
    return (
        <section className="product-list py-5 new-products">
            <div className="container">
                <div className="section-title d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column">
                        <h4 className="content text-black-50">Öne Çıkanlar</h4>
                        <span className="fw-bold">
                            Yeni Çıkan Popüler Ürünlerimiz
                        </span>
                    </div>
                    <a
                        href={`/category/${props.categoryId}`}
                        className="box-light py-2 px-3 rounded-3 fw-bold"
                    >
                        Tüm Ürünler
                        <i className="bi bi-chevron-right ms-2"></i>
                    </a>
                </div>
                <div className="row row-cols-4 g-4 align-items-stretch scroll-snap">
                    {props.products.slice(0, 4).map((p) => (
                        <FeaturedProductContent
                            openedProductId={(id) => handleProductId(id)}
                            product={p}
                            isNew={p.isNew}
                            key={p.id}
                            userInfo={props && props.userInfo}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};
export default FeaturedProducts;
