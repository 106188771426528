import React, { useState, useEffect, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MiniBasket from '../basket/MiniBasket';
import NavMenu from './NavMenu';
import NavMenuMobile from './NavMenuMobile';
import Cookies from 'universal-cookie';
import Axios from 'axios';
import { API_BASE } from '../../config/env';
import OffCanvas from 'react-aria-offcanvas';
import { BrowserView, MobileView } from 'react-device-detect';
import MiniBasketWeb from '../basket/MiniBasketWeb';
const NavHeader = (props) => {
    const cookies = new Cookies();
    const [basket, setBasket] = useState();
    const [userInfo, setUserInfo] = useState({});
    const [openCanvas, setOpenCanvas] = useState({ isOpen: false });
    const [openHoverBasket, setOpenHoverBasket] = useState(false);

    const openSearch = () => {
        props.openSearch();
    };
    const logoClicked = () => {
        window.location.href = '/';
    };

    const [searchData, setSearchData] = useState({
        searchWord: '',
        isSearch: false,
        products: []
    });

    const handleChange = async (e) => {
        const word = e.target.value;
        setSearchData({
            searchWord: word,
            isSearch: word !== ''
        });

        if (word !== '' && word.length >= 3) {
            Axios.post(`${API_BASE}Product/SearchProductByName`, {
                Name: word
            }).then((data) => {
                setSearchData({
                    isSearch: true,
                    searchWord: word,
                    products: data.data.set
                });
                console.log(data.data);
            });
        }
    };

    const getProductPrice = (prices) => {
        const normalPrice = prices.filter((price) => price.type == 1);
        const discountedPrice = prices.filter((price) => price.type == 2);
        console.log(
            normalPrice.length > 0 ? normalPrice[0].value : '',
            discountedPrice.length > 0 ? discountedPrice[0].value : ''
        );
        return discountedPrice.length > 0
            ? discountedPrice[0].value.toFixed(2)
            : normalPrice[0].value.toFixed(2);
    };

    let location = useLocation();
    let restrictedPath = ['basket', '/addresscheck', '/checkoutpayment'];
    useEffect(() => {
        // var cookiedBasket = cookies.get("basket");
        // if (cookiedBasket !== undefined) {
        //   setBasket(cookiedBasket);
        // }
        const userToken = localStorage.getItem('token');
        if (userToken != '') {
            const config = {
                headers: { 'Content-Type': 'application/json' }
            };
            Axios.get(
                `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                config
            ).then((data) => {
                if (data.data) {
                    if (data.data.code == '200') {
                        setUserInfo(data.data.set);
                        CheckCustomerId(data.data.set.customerId);
                    } else {
                        CheckCustomerId(0);
                    }
                }
            });
        } else {
            CheckCustomerId(0);
        }
        function CheckCustomerId(customerId) {
            if (customerId == 0) {
                var cookiedBasket = cookies.get('basket');
                cookiedBasket && setBasket(cookiedBasket);
            } else {
                const getBasketServiceUrl = 'basket/GetCustomerBasket/';
                const config = {
                    headers: { 'Content-Type': 'application/json' }
                };
                Axios.get(
                    `${API_BASE}${getBasketServiceUrl}${customerId}`,
                    config
                )
                    .then((data) => {
                        setBasket(data.data.set);
                    })
                    .catch();
            }
        }
    }, []);
    return (
        <header id="mainHeader" className="main-header bg-white">
            <Helmet>
                <meta
                    name="description"
                    content="Akıllı Telefon ve Tabletler için Mobil Aksesuarlar. 150 TL ve üzeri alışverişlerde KARGO BEDAVA. Bilgi, alışveriş ve destek için web sitemizi ziyaret edin."
                />
                <title>mojue</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="header-middle-content border-bottom border-top py-4">
                <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between position-relative">
                        <button
                            id="menu-button"
                            aria-label="Menu"
                            aria-controls="menu"
                            aria-expanded={openCanvas.isOpen}
                            className="btn d-inline-block d-sm-none"
                            onClick={() => setOpenCanvas({ isOpen: true })}
                        >
                            <i className="bi bi-list h1 text-primary"></i>
                        </button>
                        <div className="col-sm-1 col-4">
                            <div className="logo">
                                <a href="/" title="mojue Mobil Aksesuarlar">
                                    <img
                                        src="/logo.svg"
                                        className="img-fluid"
                                        onClick={() => logoClicked()}
                                        style={{ cursor: 'pointer' }}
                                        alt="mojue logo"
                                    />
                                </a>
                            </div>
                        </div>
                        <MobileView>
                            <OffCanvas
                                onClose={() => setOpenCanvas({ isOpen: false })}
                                isOpen={openCanvas.isOpen}
                                mainContainerSelector="#root"
                                labelledby="menu-button"
                                position="left"
                                width="75vw"
                                height="100vh"
                                className="mobileMenu"
                            >
                                <NavMenuMobile />
                            </OffCanvas>{' '}
                        </MobileView>
                        <BrowserView className="container">
                            <NavMenu />
                        </BrowserView>
                        <div className="col-sm-1 col-4 position-relative">
                            <div className="customerMenu">
                                {restrictedPath && location.pathname !== '/' ? (
                                    <MobileView
                                        onClick={() => openSearch()}
                                        className="customerMenuItem"
                                        type="button"
                                    >
                                        <i className="bi bi-search"></i>
                                    </MobileView>
                                ) : (
                                    ''
                                )}
                                <BrowserView
                                    onClick={() => openSearch()}
                                    className="customerMenuItem"
                                    type="button"
                                >
                                    <i className="bi bi-search"></i>
                                </BrowserView>

                                {(restrictedPath &&
                                    location.pathname === '/basket') ||
                                location.pathname === '/AddressCheck' ||
                                location.pathname === '/Checkoutpayment' ||
                                location.pathname === '/OrderComplete' ||
                                location.pathname === '/OrderError' ? (
                                    ''
                                ) : (
                                    <a
                                        className="customerMenuItem position-relative"
                                        href="/Basket"
                                        onMouseEnter={() =>
                                            setOpenHoverBasket(true)
                                        }
                                        onMouseLeave={() =>
                                            setOpenHoverBasket(false)
                                        }
                                    >
                                        <i className="bi bi-cart3"></i>
                                        <span className="cartCount rounded-circle">
                                            {basket &&
                                            basket.basketProducts &&
                                            basket.basketProducts.length
                                                ? basket.basketProducts.length
                                                : 0}
                                        </span>
                                        <MiniBasketWeb
                                            openHoverBasket={openHoverBasket}
                                            setOpenHoverBasket={
                                                setOpenHoverBasket
                                            }
                                        />
                                    </a>
                                )}
                                <a
                                    href={
                                        userInfo && userInfo.customerId
                                            ? '/Profile'
                                            : '/Login'
                                    }
                                    className={
                                        userInfo && userInfo.customerId
                                            ? 'customerMenuItem'
                                            : 'customerMenuItem d-flex align-items-center'
                                    }
                                >
                                    {userInfo && userInfo.customerId ? (
                                        <i className="bi bi-person-check"></i>
                                    ) : (
                                        <i className="bi bi-person "></i>
                                    )}
                                </a>
                            </div>
                            <MiniBasket />
                        </div>
                    </div>
                    {restrictedPath && location.pathname !== '/' ? (
                        ''
                    ) : (
                        <MobileView>
                            <div className="d-flex w-100 position-relative">
                                <input
                                    type="search"
                                    placeholder="Ürün adı veya stok kodu ile arayın..."
                                    className="flex-grow-1 bg-light mt-3 border rounded-2 p-3"
                                    onChange={handleChange}
                                />
                                {searchData &&
                                searchData.products &&
                                searchData.products.length > 0 ? (
                                    <div
                                        className="position-absolute z-index-3 bg-light ps-3 pe-2 py-3 rounded-3 shadow"
                                        style={{
                                            top: 'calc(100% + 5px)',
                                            maxHeight: '45vh',
                                            overflowY: 'scroll'
                                        }}
                                    >
                                        <div className="row g-2">
                                            {searchData &&
                                                searchData.products &&
                                                searchData.products.map(
                                                    (prod) => (
                                                        <div
                                                            key={prod.id}
                                                            className="col-md-6"
                                                        >
                                                            <div className="bg-white rounded-2 p-2 border">
                                                                <a
                                                                    href={`/detail/${prod.id}`}
                                                                    title={
                                                                        prod.name
                                                                    }
                                                                >
                                                                    <div className="product hovered">
                                                                        <div className="row align-items-center">
                                                                            <div className="col-3 col-md-3">
                                                                                <div className="image">
                                                                                    <img
                                                                                        src={
                                                                                            prod
                                                                                                .productPictureMappings
                                                                                                .length >
                                                                                            0
                                                                                                ? `${prod.productPictureMappings[0].picture.virtualPath}`
                                                                                                : 'assets/images/noimages.jpg'
                                                                                        }
                                                                                        className="img-fluid"
                                                                                        alt={
                                                                                            prod.name
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-9 col-md-9">
                                                                                <div className="text-start">
                                                                                    <h6 className="fw-bold mb-1 text-black-50 halfclipped">
                                                                                        {
                                                                                            prod.name
                                                                                        }
                                                                                    </h6>
                                                                                </div>
                                                                                {/* <div className="description h6 fw-normal mb-2 text-black-50 text-start">
                                <span>{prod.shortDescription}</span>
                              </div> */}
                                                                                <div className="h6 text-start">
                                                                                    <div className="price text-dark fw-bold">
                                                                                        {getProductPrice(
                                                                                            prod.prices
                                                                                        )}{' '}
                                                                                        TL
                                                                                        {/* {prod.prices.length > 0
                                    ? (prod.prices
                                        .filter((p) => p.type == 1)[0]
                                        .value.toFixed(2))
                                    : ""}{" "} */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </MobileView>
                    )}
                </div>
            </div>
        </header>
    );
};
export default memo(NavHeader);
