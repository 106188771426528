import Axios from 'axios';
import React, { useEffect, useState, memo } from 'react';
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import { API_BASE } from '../../config/env';
import { BrowserView, MobileView } from 'react-device-detect';

const Footer = (props) => {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        Axios.get(`${API_BASE}Category/GetAllCategories`).then((data) =>
            setCategories(data.data.set)
        );
    }, []);

    return (
        <footer className="main-footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 mb-4">
                        <BrowserView>
                            <div className="categoryLinks row row-cols-2 g-4">
                                {categories &&
                                    categories
                                        .filter(
                                            (c) =>
                                                c.isActive == true &&
                                                c.showSubCategories == true
                                        )
                                        .map((d, i) => (
                                            <ul
                                                className="list-unstyled"
                                                key={d.id}
                                            >
                                                <h5 className="fw-bold mb-3">
                                                    {d.name}
                                                </h5>
                                                {categories &&
                                                    categories
                                                        .filter(
                                                            (dropBase) =>
                                                                dropBase.baseCategoryId ==
                                                                d.id
                                                        )
                                                        .map((dropItem) => (
                                                            <li
                                                                key={
                                                                    dropItem.id
                                                                }
                                                            >
                                                                <a
                                                                    href={`/category/${dropItem.id}`}
                                                                >
                                                                    {
                                                                        dropItem.name
                                                                    }
                                                                </a>
                                                            </li>
                                                        ))}
                                            </ul>
                                        ))}
                            </div>
                        </BrowserView>
                    </div>
                    <div className="col-sm-6">
                        <div className="supportPhone companyLinks">
                            <div className="icon">
                                <i className="bi bi-telephone"></i>
                            </div>
                            <div className="text">
                                <h3 className="text-black-50">Çağrı Merkezi</h3>
                                <a
                                    href="tel:00908502228832"
                                    className="h1 fw-bolder text-dark"
                                >
                                    0850 222 88 32
                                </a>
                                <p className="small text-black-50 mb-0">
                                    Hafta İçi 08:00 - 17:00
                                </p>
                            </div>
                        </div>
                        <div className="row g-sm-4 g-2 supportLink">
                            <div className="col-sm-6">
                                <a
                                    href="http://teknikservis.tesan.com.tr/"
                                    className="link"
                                >
                                    Teknik Servis Ürün Sorgulama
                                </a>
                            </div>
                            <div className="col-sm-6">
                                <a href="/OdemeveTeslimat" className="link">
                                    Ödeme ve Teslimat Detayları
                                </a>
                            </div>
                            <div className="col-sm-6">
                                <a href="/IptalveIade" className="link">
                                    İptal ve İade Prosedürü
                                </a>
                            </div>
                            <div className="col-sm-6">
                                <a href="/KVKKPage" className="link">
                                    Kişisel Verilerin Korunması
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom border border-top py-4 mt-5">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-sm-auto">
                            <ul className="list-inline mb-sm-0 mb-3 text-sm-start text-center">
                                <li className="list-inline-item">
                                    <a href="/Hakkimizda" className="me-2">
                                        Hikayemiz
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="mailto:mojuesiparis@mojue.com.tr">
                                        İletişim
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-auto">
                            <div className="copyright mb-sm-0 mb-3 text-center">
                                <span>
                                    © Tüm hakları saklıdır. Kredi kartı
                                    bilgileriniz 256bit SSL sertifikası ile
                                    korunmaktadır.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default memo(Footer);
