import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_BASE } from '../../config/env';
import PersonalInfoNavigation from './PersonalInfoNavigation';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const MyAddressList = (props) => {
    const [userInfo, setUserInfo] = useState({});
    const [userAddresses, setUserAddresses] = useState([]);
    const [getAllCountries, setGetAllCountries] = useState([]);
    const [userAddress, setUserAddress] = useState({});
    const [userAddressAdd, setUserAddressAdd] = useState({});
    const [userAddressEdit, setUserAddressEdit] = useState({});
    const [allDistricts, setAllDistricts] = useState([]);
    useEffect(() => {
        const userToken = localStorage.getItem('token');
        if (userToken) {
            const config = {
                headers: { 'Content-Type': 'application/json' }
            };
            //console.log(userToken);
            Axios.get(
                `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                config
            ).then((data) => {
                //console.log(data.data.set);
                setUserInfo(data.data.set);
                setUserAddressAdd({
                    ...userAddressAdd,
                    customerId: data.data.set.customerId
                });
                if (data.data) {
                    if (data.data.code == '201') {
                        window.location.href = '/Login';
                    } else if (data.data.code == '200') {
                    }
                }
            });

            //GetAddressesByCustomerToken

            Axios.get(
                `${API_BASE}Customer/GetAddressesByCustomerToken?token=${userToken}`,
                config
            ).then((data) => {
                setUserAddresses(data.data.set);
            });
            Axios.get(
                `${API_BASE}Customer/GetProvinceByCountryId`,
                config
            ).then((data) => {
                //console.log(data.data.set);
                setGetAllCountries(data.data.set);
            });
            Axios.get(
                `${API_BASE}Customer/GetDistrictByProvinceId?provinceId=${1}`,
                config
            ).then((data) => {
                //console.log(data.data.set);
                //setGetAllCountries(data.data.set);
                setAllDistricts(data.data.set);
            });
        } else {
            window.location.href = '/Login';
        }
    }, [props]);

    const changeProvinceSelect = (e) => {
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        Axios.get(
            `${API_BASE}Customer/GetDistrictByProvinceId?provinceId=${e.target.value}`,
            config
        ).then((data) => {
            setAllDistricts(data.data.set);
        });
    };

    const KeyCheck = (e) => {
        if (e.target.id == 'phoneNumberAdd') {
            if (document.getElementById('phoneNumberAdd').value.length <= 2) {
                document.getElementById('phoneNumberAdd').value = '+90';
            } else {
                userAddressAddHandler(e);
            }
        } else if (e.target.id == 'phoneNumberEdit') {
            if (document.getElementById('phoneNumberEdit').value.length <= 2) {
                document.getElementById('phoneNumberEdit').value = '+90';
            } else {
                userAddressEditHandler(e);
            }
        }
    };

    const userAddressAddHandler = (e) => {
        if (e.target.id == 'phoneNumberAdd') {
            e.target.value = e.target.value.replace(/\s+/g, '');
            e.target.value = e.target.value.slice(2);
        }
        setUserAddressAdd({
            ...userAddressAdd,
            [e.target.name]: e.target.value
        });
    };

    const userAddressEditHandler = (e) => {
        if (e.target.id == 'phoneNumberEdit') {
            e.target.value = e.target.value.replace(/\s+/g, '');
            e.target.value = e.target.value.slice(2);
        }
        setUserAddressEdit({
            ...userAddressEdit,
            [e.target.name]: e.target.value
        });
    };

    const addAdreess = (e) => {
        if (
            !userAddressAdd.firstName &&
            !userAddressAdd.lastName &&
            !userAddressAdd.address1 &&
            !userAddressAdd.phoneNumber &&
            !userAddressAdd.stateProvinceId &&
            !userAddressAdd.districtId &&
            !userAddressAdd.address2
        ) {
            alert('Tüm Alanları Doldurunuz!');
            return false;
        }

        if (userAddressAdd.phoneNumber.length < 11) {
            alert('Telefon Numarası 11 Haneli Olmalıdır!');
            return false;
        }

        var ozelChar = '*|,<>[]{}`;()@&$#%!+-"';
        var isChar = false;
        for (var i = 0; i < userAddressAdd.address2.length; i++) {
            if (ozelChar.indexOf(userAddressAdd.address2.charAt(i)) != -1) {
                isChar = true;
            }
        }

        if (isChar == true) {
            alert('Adres özel karakter içermemelidir.');
            return false;
        }

        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        setUserAddressAdd({ ...userAddressAdd, countryId: 219 });

        Axios.post(
            `${API_BASE}Customer/AddAddress`,
            userAddressAdd,
            config
        ).then((data) => {
            if (data.data) {
                if (data.data.code == '201') {
                    alert('Başarısız');
                } else if (data.data.code == '200') {
                    setUserAddressAdd({
                        ...userAddressAdd,
                        ...data.data
                    });
                    window.location.reload();
                }
            }
        });
    };

    const deleteAddress = (e) =>{
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        Axios.post(
            `${API_BASE}Customer/DeleteAddress?addressId=${userAddressEdit.id}`,
            config
        ).then((data) => {
            if (data.data) {
                if (data.data.code == '201') {
                    alert('Başarısız');
                } else if (data.data.code == '200') {
                    window.location.reload();
                }
            }
        });
    };

    const editAdreess = (e) => {
        if (
            !userAddressEdit.firstName &&
            !userAddressEdit.lastName &&
            !userAddressEdit.address1 &&
            !userAddressEdit.phoneNumber &&
            !userAddressEdit.stateProvinceId &&
            !userAddressEdit.districtId &&
            !userAddressEdit.address2
        ) {
            alert('Tüm Alanları Doldurunuz!');
            return false;
        }

        if (userAddressEdit.phoneNumber.length < 11) {
            alert('Telefon Numarası 11 Haneli Olmalıdır!');
            return false;
        }

        var ozelChar = '*|,<>[]{}`;()@&$#%!+-"';
        var isChar = false;
        for (var i = 0; i < userAddressEdit.address2.length; i++) {
            if (ozelChar.indexOf(userAddressEdit.address2.charAt(i)) != -1) {
                isChar = true;
            }
        }

        if (isChar == true) {
            alert('Adres özel karakter içermemelidir.');
            return false;
        }

        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        Axios.post(
            `${API_BASE}Customer/EditAddress`,
            userAddressEdit,
            config
        ).then((data) => {
            if (data.data) {
                if (data.data.code == '201') {
                    alert('Başarısız');
                } else if (data.data.code == '200') {
                    setUserAddressEdit({
                        ...userAddressEdit,
                        ...data.data
                    });
                    window.location.reload();
                }
            }
        });
    };

    const getAddress = (addressId) => {
        document.getElementById('editAddress').reset();
        setUserAddressAdd({});
        setUserAddress({});
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        Axios.get(
            `${API_BASE}Customer/GetAddressById?addressId=${addressId}`,
            config
        ).then((data) => {
            setUserAddress(data.data.set);
            setUserAddressEdit({
                ...userAddressEdit,
                ...data.data.set
            });
            Axios.get(
                `${API_BASE}Customer/GetDistrictByProvinceId?provinceId=${data.data.set.stateProvinceId}`,
                config
            ).then((data) => {
                setAllDistricts(data.data.set);
            });
        });
    };
    return (
        <section className="addresslist bg-light user-section py-sm-5 p-4 flex-fill">
            <div className="container">
                <div className="row">
                    <PersonalInfoNavigation user={userInfo} />
                    <div className="col-sm-9">
                        <div className="addressList">
                            <div className="header d-flex align-items-center mb-4">
                                <div className="col h5 fw-bold">
                                    <i className="bi bi-geo me-2 text-primary"></i>
                                    <span>Adres Listesi</span>
                                </div>
                                <div className="ms-auto">
                                    <button
                                        className="btn btn-outline-dark"
                                        data-bs-toggle="modal"
                                        data-bs-target="#addressAddModal"
                                    >
                                        <i className="bi bi-plus-circle me-2"></i>
                                        <span>Yeni Adres Ekle</span>
                                    </button>
                                </div>
                            </div>
                            <div className="row g-2">
                                {/* Adres Listesi Boş Olduğunda Gösterilecek */}
                                {userAddresses.length < 1 ? (
                                    <div className="empty-address-list p-4 text-black-50 text-center d-flex flex-column align-items-center gap-1 justify-content-center">
                                        <i className="bi bi-house h3"></i>
                                        <h5 className="fw-bolder">
                                            Adres Listeniz Boş
                                        </h5>
                                        <p>
                                            Lütfen Bir Teslimat Adresi Ekleyin.
                                        </p>
                                    </div>
                                ) : (
                                    ''
                                )}

                                {/* Adres Listesi Boş Olduğunda Gösterilecek */}
                                {userAddresses &&
                                    userAddresses.map((address) => (
                                        <div
                                            key={address.id}
                                            className="col-sm-6"
                                        >
                                            <div className="address bg-white rounded-2 p-4">
                                                <div className="title h5 fw-bolder my-2">
                                                    {address.firstName +
                                                        ' ' +
                                                        address.lastName}
                                                </div>
                                                <div className="clipped detail text-black-50 mb-2">
                                                    {address.address1}
                                                </div>
                                                <div className="edit">
                                                    <button
                                                        className="btn p-0 text-success fw-bold small"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#addressEditModal"
                                                    >
                                                        <i className="bi bi-pencil-square me-1"></i>
                                                        <span
                                                            id={address.id}
                                                            name={address.id}
                                                            onClick={(e) => {
                                                                getAddress(
                                                                    address.id
                                                                );
                                                            }}
                                                        >
                                                            Düzenle
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="addressEditModal"
                tabIndex="-1"
                aria-labelledby="addressEditModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable overflow-hidden">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-5 pb-0">
                            <h4 className="modal-title text-primary">
                                Adres Düzenle
                            </h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body px-5">
                            <form id="editAddress">
                                <div className="mb-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="address1"
                                        name="address1"
                                        placeholder="Adres Başlığı Örn: İş, Evim, Okul vb."
                                        onChange={(e) =>
                                            userAddressEditHandler(e)
                                        }
                                        defaultValue={userAddress.address1}
                                    />
                                </div>
                                <div className="row g-3 mb-3">
                                    <label className="form-label fw-bold h5 mt-5">
                                        <i className="bi bi-person me-2 text-primary"></i>{' '}
                                        Teslim Alacak Kişi Bilgileri
                                    </label>
                                    <div className="col">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            placeholder="Ad"
                                            aria-label="Ad"
                                            onChange={(e) =>
                                                userAddressEditHandler(e)
                                            }
                                            defaultValue={userAddress.firstName}
                                        />
                                    </div>
                                    <div className="col">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            placeholder="Soyad"
                                            aria-label="Soyad"
                                            onChange={(e) => {
                                                userAddressEditHandler(e);
                                            }}
                                            defaultValue={userAddress.lastName}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <PhoneInput
                                        onlyCountries={['tr']}
                                        country={'tr'}
                                        inputProps={{
                                            name: 'phoneNumber',
                                            id: 'phoneNumberEdit',
                                            className:
                                                'form-control w-100 rounded-2 py-4',
                                            placeholder:
                                                'Cep Telefonu (GSM) No',
                                            onKeyUp: (e) => {
                                                KeyCheck(e);
                                            },
                                            onClick: (e) => {
                                                KeyCheck(e);
                                            }
                                        }}
                                        value={'+9' + userAddress.phoneNumber}
                                    />
                                    <div id="phoneHelpBlock" class="form-text">
                                        Siparişlerinizin gönderim ve kargo
                                        süreçleri hakkında bilgilendirmelerin
                                        gönderilebilmesi için aktif ve geçerli
                                        bir telefon numarası giriniz.
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="tcKimlikNo"
                                        name="tcKimlikNo"
                                        placeholder="Tc Kimlik No Giriniz"
                                        defaultValue={userAddress.tcKimlikNo}
                                        onChange={(e) => {
                                            userAddressEditHandler(e);
                                        }}
                                    />
                                </div>
                                <div className="form-subheader fw-bold h5 mb-3 mt-5">
                                    <i className="bi bi-house me-2 text-primary"></i>
                                    Adres Bilgileriniz
                                </div>
                                <div className="mb-3">
                                    <select
                                        id="stateProvinceId"
                                        name="stateProvinceId"
                                        className="form-select"
                                        aria-label="Şehir Seçiniz"
                                        onChange={(e) => {
                                            userAddressEditHandler(e);
                                            changeProvinceSelect(e);
                                        }}
                                    >
                                        {getAllCountries &&
                                            getAllCountries.map((c) => (
                                                <option
                                                    value={c.id}
                                                    key={c.id}
                                                    selected={
                                                        userAddress.stateProvinceId &&
                                                        userAddress.stateProvinceId ==
                                                            c.id
                                                    }
                                                >
                                                    {c.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <select
                                        id="districtId"
                                        name="districtId"
                                        className="form-select"
                                        aria-label="İlçe Seçiniz"
                                        onChange={(e) =>
                                            userAddressEditHandler(e)
                                        }
                                    >
                                        {allDistricts &&
                                            allDistricts.map((d) => (
                                                <option
                                                    value={d.id}
                                                    key={d.id}
                                                    selected={
                                                        userAddress.districtId &&
                                                        userAddress.districtId ==
                                                            d.id
                                                    }
                                                >
                                                    {d.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <textarea
                                        className="form-control"
                                        rows="5"
                                        id="address2"
                                        name="address2"
                                        placeholder="Mahalle, sokak, cadde ve diğer bilgilerinizi giriniz."
                                        onChange={(e) => {
                                            userAddressEditHandler(e);
                                        }}
                                        defaultValue={userAddress.address2}
                                    ></textarea>
                                </div>
                                {/*<div className="form-subheader fw-bold h5 mb-3 mt-5">*/}
                                {/*  <i className="bi bi-receipt me-2 text-primary"></i>Fatura*/}
                                {/*  Bilgileriniz*/}
                                {/*</div>*/}
                                {/*<div className="mb-3">*/}
                                {/*  <label className="form-label me-3">Fatura Tipi</label>*/}
                                {/*  <div className="form-check form-check-inline">*/}
                                {/*    <input*/}
                                {/*      className="form-check-input"*/}
                                {/*      type="checkbox"*/}
                                {/*      name="individual"*/}
                                {/*      id="individual"*/}
                                {/*      value="Bireysel"*/}
                                {/*    />*/}
                                {/*    <label className="form-check-label" htmlFor="individual">*/}
                                {/*      Bireysel*/}
                                {/*    </label>*/}
                                {/*  </div>*/}
                                {/*  <div className="form-check form-check-inline">*/}
                                {/*    <input*/}
                                {/*      className="form-check-input"*/}
                                {/*      type="checkbox"*/}
                                {/*      name="corporate"*/}
                                {/*      id="corporate"*/}
                                {/*      value="Kurumsal"*/}
                                {/*    />*/}
                                {/*    <label className="form-check-label" htmlFor="corporate">*/}
                                {/*      Kurumsal*/}
                                {/*    </label>*/}
                                {/*  </div>*/}
                                {/*</div>*/}
                            </form>
                        </div>

                        <div class="modal-footer d-flex gap-2 my-5 justify-content-end">
                            <button
                                type="button"
                                className="btn btn-lg btn-danger"
                                onClick={deleteAddress}
                            >
                                Sil
                            </button>
                            <button
                                type="button"
                                className="btn btn-lg btn-primary"
                                onClick={editAdreess}
                            >
                                Kaydet
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="addressAddModal"
                tabIndex="-1"
                aria-labelledby="addressAddModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable overflow-hidden">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-5 pb-0">
                            <h4 className="modal-title text-primary">
                                Adres Ekle
                            </h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body px-5">
                            <div className="mb-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    id="address1"
                                    name="address1"
                                    placeholder="Adres Başlığı Örn: İş, Evim, Okul vb."
                                    onChange={(e) => userAddressAddHandler(e)}
                                />
                            </div>
                            <div className="row g-3 mb-3">
                                <label className="form-label fw-bold h5 mt-5">
                                    <i className="bi bi-person me-2 text-primary"></i>{' '}
                                    Teslim Alacak Kişi Bilgileri
                                </label>
                                <div className="col">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        placeholder="Ad"
                                        aria-label="Ad"
                                        onChange={(e) =>
                                            userAddressAddHandler(e)
                                        }
                                    />
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Soyad"
                                        aria-label="Soyad"
                                        onChange={(e) =>
                                            userAddressAddHandler(e)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <PhoneInput
                                    onlyCountries={['tr']}
                                    country={'tr'}
                                    inputProps={{
                                        name: 'phoneNumber',
                                        id: 'phoneNumberAdd',
                                        className:
                                            'form-control w-100 rounded-2 py-4',
                                        placeholder: 'Cep Telefonu (GSM) No',
                                        onKeyUp: (e) => {
                                            KeyCheck(e);
                                        },
                                        onClick: (e) => {
                                            KeyCheck(e);
                                        }
                                    }}
                                />
                                <div id="phoneHelpBlock" class="form-text">
                                    Siparişlerinizin gönderim ve kargo süreçleri
                                    hakkında bilgilendirmelerin gönderilebilmesi
                                    için aktif ve geçerli bir telefon numarası
                                    giriniz.
                                </div>
                            </div>
                            <div className="form-subheader fw-bold h5 mb-3 mt-5">
                                <i className="bi bi-house me-2 text-primary"></i>
                                Adres Bilgileriniz
                            </div>
                            <div className="mb-3">
                                <select
                                    id="stateProvinceId"
                                    name="stateProvinceId"
                                    className="form-select"
                                    aria-label="Şehir Seçiniz"
                                    onChange={(e) => {
                                        userAddressAddHandler(e);
                                        changeProvinceSelect(e);
                                    }}
                                >
                                    <option value="">Seçiniz</option>
                                    {getAllCountries &&
                                        getAllCountries.map((c) => (
                                            <option value={c.id} key={c.id}>
                                                {c.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <select
                                    id="districtId"
                                    name="districtId"
                                    className="form-select"
                                    aria-label="İlçe Seçiniz"
                                    onChange={(e) => userAddressAddHandler(e)}
                                >
                                    <option className="selected">
                                        İlçe Seçiniz
                                    </option>
                                    {allDistricts &&
                                        allDistricts.map((d) => (
                                            <option value={d.id} key={d.id}>
                                                {d.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <textarea
                                    className="form-control"
                                    rows="5"
                                    id="address2"
                                    name="address2"
                                    placeholder="Mahalle, sokak, cadde ve diğer bilgilerinizi giriniz."
                                    onChange={(e) => userAddressAddHandler(e)}
                                ></textarea>
                            </div>
                            {/*<div className="form-subheader fw-bold h5 mb-3 mt-5">*/}
                            {/*  <i className="bi bi-receipt me-2 text-primary"></i>Fatura*/}
                            {/*  Bilgileriniz*/}
                            {/*</div>*/}
                            {/*<div className="mb-3">*/}
                            {/*  <label className="form-label me-3">Fatura Tipi</label>*/}
                            {/*  <div className="form-check form-check-inline">*/}
                            {/*    <input*/}
                            {/*      className="form-check-input"*/}
                            {/*      type="checkbox"*/}
                            {/*      name="individual"*/}
                            {/*      id="individual"*/}
                            {/*      value="Bireysel"*/}
                            {/*    />*/}
                            {/*    <label className="form-check-label" htmlFor="individual">*/}
                            {/*      Bireysel*/}
                            {/*    </label>*/}
                            {/*  </div>*/}
                            {/*  <div className="form-check form-check-inline">*/}
                            {/*    <input*/}
                            {/*      className="form-check-input"*/}
                            {/*      type="checkbox"*/}
                            {/*      name="corporate"*/}
                            {/*      id="corporate"*/}
                            {/*      value="Kurumsal"*/}
                            {/*    />*/}
                            {/*    <label className="form-check-label" htmlFor="corporate">*/}
                            {/*      Kurumsal*/}
                            {/*    </label>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="modal-footer border-0 p-5 pt-0 align-items-center">
                            <div className="ms-auto">
                                <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    onClick={addAdreess}
                                >
                                    Kaydet
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MyAddressList;
