import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { API_BASE, tesanCDNURL, cargoPriceFree } from '../../config/env';
import { BrowserView, MobileView } from 'react-device-detect';
import { Accordion } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useParams } from 'react-router-dom';

const AddressCheck = (props) => {
    let params = useParams();
    const cookies = new Cookies();
    var cookiedAddress = cookies.get('shippingaddress');
    const [userInfo, setUserInfo] = useState({});
    const [userAddresses, setUserAddresses] = useState([]);
    const [basketId, SetBasketId] = useState(0);
    const [selectedAddress, setSelectedAddress] = useState(''); //ShippingAddress
    const [selectedBillingAddress, setSelectedBillingAddress] = useState(''); //BillingAddress
    const [selectedCargo, setSelectedCargo] = useState('');
    const [getAllCountries, setGetAllCountries] = useState([]);
    const [userAddress, setUserAddress] = useState({});
    const [userAddressAdd, setUserAddressAdd] = useState({});
    const [unregisterEmail, setUnregisterEmail] = useState('');
    const [unregisterEmailControl, setUnregisterEmailControl] = useState('');
    const [userAddressEdit, setUserAddressEdit] = useState({});
    const [allDistricts, setAllDistricts] = useState([]);
    const [stockQuantities, setStockQuantities] = useState([]);
    const [cargos, setCargos] = useState({
        cargos: []
    });
    function selectKargoHandler(kargoTitle, basePrice) {
        if (selectedCargo == kargoTitle) setSelectedCargo('');
        else setSelectedCargo(kargoTitle);
        setTotalInfo({
            ...totalInfo,
            Kargo:
                totalInfo.totalDiscountedPrice >= cargoPriceFree
                    ? 0
                    : Number(basePrice)
        });
        cookies.set(
            'cargoPrice',
            totalInfo.totalDiscountedPrice >= cargoPriceFree
                ? 0
                : Number(basePrice)
        );
        cookies.set('cargoId', kargoTitle);
    }
    function selectAddressHandler(addressId, sendType) {
        if (sendType == 'billing') {
            if (selectedBillingAddress == addressId)
                setSelectedBillingAddress('');
            else setSelectedBillingAddress(addressId);

            if (userInfo && userInfo.isEmployee == true) {
                const cookieAddres =
                    userAddresses.filter((a) => a.id == addressId)[0] == null
                        ? {}
                        : userAddresses.filter((a) => a.id == addressId)[0];
                cookies.set('billingaddress', cookieAddres);
            } else if (userInfo && userInfo.isEmployee == false) {
                const cookieAddres =
                    userAddresses.filter((a) => a.id == addressId)[0] == null
                        ? {}
                        : userAddresses.filter((a) => a.id == addressId)[0];
                cookies.set('billingaddress', cookieAddres);
            }
        } else {
            //shipping adres
            if (selectedAddress == addressId) setSelectedAddress('');
            else setSelectedAddress(addressId);

            if (userInfo && userInfo.isEmployee == true) {
                const cookieAddres =
                    userAddresses.filter((a) => a.id == addressId)[0] == null
                        ? {}
                        : userAddresses.filter((a) => a.id == addressId)[0];
                cookies.set('shippingaddress', cookieAddres);
            } else if (userInfo && userInfo.isEmployee == false) {
                const cookieAddres =
                    userAddresses.filter((a) => a.id == addressId)[0] == null
                        ? {}
                        : userAddresses.filter((a) => a.id == addressId)[0];
                cookies.set('shippingaddress', cookieAddres);
            }
        }
    }

    const KeyCheck = (e) => {
        if (e.target.id == 'phoneNumberAdd') {
            if (document.getElementById('phoneNumberAdd').value.length <= 2) {
                document.getElementById('phoneNumberAdd').value = '+90';
            } else {
                userAddressAddHandler(e);
            }
        } else if (e.target.id == 'phoneNumberEdit') {
            if (document.getElementById('phoneNumberEdit').value.length <= 2) {
                document.getElementById('phoneNumberEdit').value = '+90';
            } else {
                userAddressEditHandler(e);
            }
        }
    };

    const userAddressAddHandler = (e) => {
        if (e.target.id == 'phoneNumberAdd') {
            e.target.value = e.target.value.replace(/\s+/g, '');
            e.target.value = e.target.value.slice(2);
        }
        setUserAddressAdd({
            ...userAddressAdd,
            [e.target.name]: e.target.value
        });
    };

    const userAddressEditHandler = (e) => {
        if (e.target.id == 'phoneNumberEdit') {
            e.target.value = e.target.value.replace(/\s+/g, '');
            e.target.value = e.target.value.slice(2);
        }
        setUserAddressEdit({
            ...userAddressEdit,
            [e.target.name]: e.target.value
        });
    };

    const userUnregisterEmail = (e) => {
        setUnregisterEmail({
            ...unregisterEmail,
            [e.target.name]: e.target.value
        });
    };

    const userEmailControl = () => {
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };

        axios
            .get(
                `${API_BASE}Order/UnregisterMailControl?email=${unregisterEmail.unregisterMail}`,
                config
            )
            .then((data) => {
                if (data.data.set == false) {
                    alert('Bu e-Postaya ait üye kaydı bulunmaktadır!');
                    window.location.href = '/Login';
                }
            });
    };

    const [totalInfo, setTotalInfo] = useState({
        totalPrice: 0,
        totalDiscountedPrice: 0,
        KDV: 0,
        Kargo: 0
    });
    const [basketQuantity, setBasketQuantity] = useState([]);
    const [basket, setBasket] = useState({
        basketId: 0,
        basketProducts: [
            {
                productId: '',
                productImage: '',
                discountPrice: 0,
                campaignPrice: 0,
                productName: '',
                productShortDesc: '',
                price: 0,
                variantInfo: {
                    productAttributeId: 0,
                    productAttributeValue: '',
                    productAttributeValueId: 0,
                    productAttributeValueValue: '',
                    price: 0.0,
                    stockId: '',
                    variantPictureId: 0,
                    variantPicturePath: ''
                }
            }
        ],
        basketInfo: {
            totalAmount: 0.0,
            discountedAmount: 0.0,
            totalKdv: 0.0,
            isShipmentFree: false,
            shipmentFreeLimit: 0
        }
    });

    useEffect(() => {
        //order-address-page
        document.getElementById('body').className = 'order-address-page';
        const userToken = localStorage.getItem('token');
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };

        axios
            .get(
                `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                config
            )
            .then((data) => {
                setUserInfo(data.data.set);
                var isEmp = false;
                if (data.data) {
                    if (data.data.code == '201') {
                        //window.location.href = "/Login";
                    } else if (data.data.code == '200') {
                        setUserAddressAdd({
                            ...userAddressAdd,
                            customerId: data.data.set.customerId
                        });
                    }
                }
                if (data.data.set && data.data.set.isEmployee == true) {
                    axios
                        .get(`${API_BASE}Cargo/GetAllCargo`, config)
                        .then((data) => {
                            setCargos({
                                cargos: data.data.set
                            });
                        });
                } else {
                    axios
                        .get(`${API_BASE}Cargo/GetAllCargo`, config)
                        .then((data) => {
                            setCargos({
                                cargos: data.data.set.filter(
                                    (c) =>
                                        c.isEmployee === false ||
                                        c.isEmployee === null
                                )
                            });
                        });
                }

                axios
                    .get(`${API_BASE}Customer/GetProvinceByCountryId`, config)
                    .then((data) => {
                        setGetAllCountries(data.data.set);
                    });
                axios
                    .get(
                        `${API_BASE}Customer/GetDistrictByProvinceId?provinceId=${1}`,
                        config
                    )
                    .then((data) => {
                        setAllDistricts(data.data.set);
                    });
                if (data.data.code == '200') {
                    GetActiveBasket(data.data.set.customerId);
                    CheckCustomerId(data.data.set.customerId);
                } else {
                    CheckCustomerId(0);
                }
            });

        function GetActiveBasket(customerId) {
            //apiden ilgili basketin kupon kodunu çekiyoruz.
            axios
                .get(
                    `${API_BASE}basket/GetActiveBasket?customerId=${customerId}`,
                    config
                )
                .then((data) => {
                    if (data.data) {
                        if (data.data.code == '200') {
                            SetBasketId(data.data.set.Id);
                            if (
                                data.data.set.couponCode != null ||
                                data.data.set.campaignId != null
                            ) {
                                //SP basket id gönderilecek gelen değer true ya da false olacak;

                                const config = {
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                };
                                axios
                                    .get(
                                        `${API_BASE}Campaign/CouponCampaignControl?basketId=${data.data.set.id}`,
                                        config
                                    )
                                    .then((data) => {
                                        if (data.data) {
                                            if (data.data.code == '200') {
                                                var isCampaign = data.data.set;
                                                if (isCampaign == false) {
                                                    alert(
                                                        'Kampanya uygulanamadı. Geçersiz Kampanya!'
                                                    );
                                                    window.location.href =
                                                        '/basket';
                                                }
                                            }
                                        }
                                    });
                            }
                        }
                    }
                });
        }

        function CheckCustomerId(customerId) {
            if (customerId == 0) {
                var cookiedBasket = cookies.get('basket');
                cookiedBasket && setBasket(cookiedBasket);
                SepetIslemleri(cookiedBasket);
            } else {
                const config = {
                    headers: { 'Content-Type': 'application/json' }
                };
                axios
                    .get(
                        `${API_BASE}Customer/GetAddressesByCustomerToken?token=${userToken}`,
                        config
                    )
                    .then((data) => {
                        setUserAddresses(data.data.set);
                    });

                const getBasketServiceUrl = 'basket/GetCustomerBasket/';
                axios
                    .get(
                        `${API_BASE}${getBasketServiceUrl}${customerId}`,
                        config
                    )
                    .then((data) => {
                        setBasket(data.data.set);
                        SepetIslemleri(data.data.set);
                    })
                    .catch();
            }
        }
        //Cookies işlemleri.
        function SepetIslemleri(basket) {
            var stokKontrol = true;
            var totalPrice = 0;
            var totalDiscountedPrice = 0;
            var basketQuantities = [];
            // setBasketQuantity([{ productId: productId, quantity: 1, price: price }])
            if (!basket) {
                window.location.pathname = '/';
            }
            basket.basketProducts.map((bp) => {
                basketQuantities.push({
                    productId: bp.productId,
                    quantity: bp.quantity,
                    price: bp.price
                });
                totalPrice += bp.price * bp.quantity;
                totalDiscountedPrice +=
                    bp.campaignPrice !== undefined && bp.campaignPrice !== null
                        ? bp.campaignPrice * bp.quantity
                        : bp.discountPrice !== null
                        ? bp.discountPrice * bp.quantity
                        : bp.price * bp.quantity;

                setTotalInfo({
                    totalPrice: totalPrice,
                    totalDiscountedPrice: totalDiscountedPrice,
                    KDV: 20,
                    Kargo: 0
                });

                //StockCheck All
                const stockCheckAll =
                    basket &&
                    basket.basketProducts &&
                    basket.basketProducts.map((bp) => ({
                        productId: bp.productId,
                        stockId: bp.stockId
                    }));

                if (stockCheckAll != null) {
                    const config = {
                        headers: { 'Content-Type': 'application/json' }
                    };
                    axios
                        .post(
                            `${API_BASE}basket/StockCheckAll`,
                            stockCheckAll,
                            config
                        )
                        .then((data) => {
                            //setStockQuantities(data.data.set);

                            const stockQuantity = data.data.set.filter(
                                (sq) => sq.stockId == bp.stockId
                            )[0];

                            if (stockQuantity.quantity < bp.quantity) {
                                window.location.href = '/basket';
                            }
                        })
                        .catch((error) => {
                            console.error('axios hata: ' + error);
                        });
                }
            });
            setBasketQuantity(basketQuantities);
        }
    }, []);

    function btnOnPaymentClick() {
        if (!userInfo) {
            if (unregisterEmail == '') {
                alert('Lütfen E-Posta Adresinizi Giriniz!');
                return false;
            } else {
                cookies.set('unregisterEmail', unregisterEmail);
            }
        }
        if (isDifferentShiping == true && selectedBillingAddress == '') {
            alert('Fatura adresi seçin...');
            return;
        }
        if (selectedCargo == '' || selectedAddress == '') {
            alert('Lütfen adres ve kargo seçimi yapın');
        } else {
            window.location.href = '/Checkoutpayment';
        }
    }
    const changeProvinceSelect = (e) => {
        console.log(e.target);
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        axios
            .get(
                `${API_BASE}Customer/GetDistrictByProvinceId?provinceId=${e.target.value}`,
                config
            )
            .then((data) => {
                setAllDistricts(data.data.set);
            });
    };

    const getAddress = (addressId) => {
        //document.getElementById("editAddress").reset();
        setUserAddressAdd({});
        setUserAddress({});
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        axios
            .get(
                `${API_BASE}Customer/GetAddressById?addressId=${addressId}`,
                config
            )
            .then((data) => {
                setUserAddress(data.data.set);
                setUserAddressEdit({
                    ...userAddressEdit,
                    ...data.data.set
                });
                axios
                    .get(
                        `${API_BASE}Customer/GetDistrictByProvinceId?provinceId=${data.data.set.stateProvinceId}`,
                        config
                    )
                    .then((data) => {
                        setAllDistricts(data.data.set);
                    });
            });
    };
    const [isDifferentShiping, setIsDifferentShiping] = useState(false);
    function diferentCheckChange(event) {
        setIsDifferentShiping(event.target.checked);
        if (event.target.checked == false) {
            setSelectedBillingAddress('');
        }
    }
    const addAdreess = (e) => {
        if (
            !userAddressAdd.firstName ||
            !userAddressAdd.lastName ||
            !userAddressAdd.address1 ||
            !userAddressAdd.phoneNumber ||
            !userAddressAdd.stateProvinceId ||
            !userAddressAdd.districtId ||
            !userAddressAdd.address2 ||
            !userAddressAdd.tcKimlikNo
        ) {
            alert('Tüm Alanları Doldurunuz!');
            return false;
        }

        if (userAddressAdd.phoneNumber.length < 11) {
            alert('Telefon Numarası 11 Haneli Olmalıdır!');
            return false;
        }

        var ozelChar = '*|,<>[]{}`;()@&$#%!+-"';
        var isChar = false;
        for (var i = 0; i < userAddressAdd.address2.length; i++) {
            if (ozelChar.indexOf(userAddressAdd.address2.charAt(i)) != -1) {
                isChar = true;
            }
        }

        if (isChar == true) {
            alert('Adres özel karakter içermemelidir.');
            return false;
        }

        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        setUserAddressAdd({ ...userAddressAdd, countryId: 219 });

        if (!userInfo) {
            cookies.set('shippingaddress', userAddressAdd);
            window.location.reload();
        } else {
            axios
                .post(`${API_BASE}Customer/AddAddress`, userAddressAdd, config)
                .then((data) => {
                    if (data.data) {
                        if (data.data.code == '201') {
                            alert('Başarısız');
                        } else if (data.data.code == '200') {
                            setUserAddressAdd({
                                ...userAddressAdd,
                                ...data.data
                            });
                            window.location.reload();
                        }
                    }
                });
        }
    };

    const editAdreess = (e) => {
        if (
            !userAddressEdit.firstName ||
            !userAddressEdit.lastName ||
            !userAddressEdit.address1 ||
            !userAddressEdit.phoneNumber ||
            !userAddressEdit.stateProvinceId ||
            !userAddressEdit.districtId ||
            !userAddressEdit.address2 ||
            !userAddressEdit.tcKimlikNo
        ) {
            alert('Tüm Alanları Doldurunuz!');
            return false;
        }

        if (userAddressEdit.phoneNumber.length < 11) {
            alert('Telefon Numarası 11 Haneli Olmalıdır!');
            return false;
        }

        var ozelChar = '*|,<>[]{}`;()@&$#%!+-"';
        var isChar = false;
        for (var i = 0; i < userAddressEdit.address2.length; i++) {
            if (ozelChar.indexOf(userAddressEdit.address2.charAt(i)) != -1) {
                isChar = true;
            }
        }

        if (isChar == true) {
            alert('Adres özel karakter içermemelidir.');
            return false;
        }

        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        axios
            .post(`${API_BASE}Customer/EditAddress`, userAddressEdit, config)
            .then((data) => {
                if (data.data) {
                    if (data.data.code == '201') {
                        alert('Başarısız');
                    } else if (data.data.code == '200') {
                        setUserAddressEdit({
                            ...userAddressEdit,
                            ...data.data
                        });
                        window.location.reload();
                    }
                }
            });
    };
    return (
        <>
            <div className="checkout-header py-4">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-center">
                        <button
                            href="#"
                            className="btn step current p3 d-flex flex-column align-items-center"
                        >
                            <i className="bi bi-truck h5"></i>
                            <span className="fw-bold">Teslimat</span>
                        </button>
                        <span className="line current"></span>
                        <button
                            href="#"
                            className="btn step p3 d-flex flex-column align-items-center"
                        >
                            <i className="bi bi-credit-card h5"></i>
                            <span className="fw-bold">Güvenli Ödeme</span>
                        </button>
                        <span className="line"></span>
                        <button
                            href="#"
                            className="btn step p3 d-flex flex-column align-items-center"
                        >
                            <i className="bi bi-receipt h5"></i>
                            <span className="fw-bold">Özet</span>
                        </button>
                    </div>
                </div>
            </div>

            <BrowserView className="checkout-address py-5 flex-fill">
                <div className="container">
                    <div className="row">
                        {/* Basket Header */}
                        <div className="col-sm-12">
                            <div className="d-flex border border-danger rounded-1 overflow-hidden gap-3 align-items-center text-danger mb-5">
                                <span className="bg-danger text-white py-2 px-3">
                                    <i className="bi bi-exclamation-circle"></i>
                                </span>{' '}
                                <span>
                                    Ödeme işlemine devam edebilmek için lütfen{' '}
                                    <strong>Teslimat Adresi</strong> ve{' '}
                                    <strong>Kargo</strong> seçiniz.
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-9">
                            {/* Teslimat Adresi */}
                            <header className="header d-flex flex-wrap gap-3 justify-content-between align-items-center mb-4">
                                <h5 className="fw-bold">
                                    <i className="bi bi-house me-2"></i>
                                    <span>Teslimat Adresi</span>
                                </h5>
                            </header>
                            <div className="bg-white d-flex flex-column gap-4">
                                {/* Adresler */}
                                <div className="addressList">
                                    <div className="row g-3">
                                        {/* Adres Listesi Boş Olduğunda Gösterilecek */}
                                        {!cookiedAddress &&
                                        userAddresses &&
                                        userAddresses.length < 1 ? (
                                            <div className="empty-address-list p-4 text-black-50 text-center d-flex flex-column align-items-center gap-1 justify-content-center">
                                                <i className="bi bi-house h3"></i>
                                                <h5 className="fw-bold">
                                                    Adres Listeniz Boş
                                                </h5>
                                                <p>
                                                    Lütfen Bir Teslimat Adresi
                                                    Ekleyin.
                                                </p>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        {/* Adres Listesi Boş Olduğunda Gösterilecek */}
                                        {userAddresses &&
                                            userAddresses.map((address) => (
                                                <div
                                                    key={address.id}
                                                    className="col-sm-3 col-6"
                                                    onClick={() =>
                                                        selectAddressHandler(
                                                            address.id,
                                                            'shipping'
                                                        )
                                                    }
                                                >
                                                    <div
                                                        className={`h-100 product d-flex flex-column gap-3 border rounded-2 p-4 position-relative selectable ${
                                                            selectedAddress ==
                                                            address.id
                                                                ? 'selected'
                                                                : ''
                                                        }`}
                                                    >
                                                        <div className="stack">
                                                            <div className="title fw-bold">
                                                                {
                                                                    address.address1
                                                                }
                                                            </div>
                                                            <div className="clipped detail text-black-50">
                                                                {
                                                                    address.address2
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="stack">
                                                            <div className="fw-bold">
                                                                {
                                                                    address.firstName
                                                                }{' '}
                                                                {
                                                                    address.lastName
                                                                }
                                                            </div>
                                                            <div className="text-success fw-bold">
                                                                {
                                                                    address.phoneNumber
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="edit mt-auto">
                                                            <button
                                                                className="btn btn-sm border-primary text-primary rounded-1 px-2 py-1"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#addressEditModal"
                                                                type="button"
                                                            >
                                                                <span
                                                                    id={
                                                                        address.id
                                                                    }
                                                                    name={
                                                                        address.id
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        getAddress(
                                                                            address.id
                                                                        );
                                                                    }}
                                                                >
                                                                    Düzenle
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        {!userInfo && cookiedAddress ? (
                                            <div
                                                key={1}
                                                className="col-sm-3 col-6"
                                                onClick={() =>
                                                    selectAddressHandler(
                                                        1,
                                                        'shipping'
                                                    )
                                                }
                                            >
                                                <div
                                                    className={`address bg-white rounded-3 p-4 border ${
                                                        selectedAddress == 1
                                                            ? 'selected'
                                                            : ''
                                                    }`}
                                                >
                                                    <div className="title h5 fw-bold my-2">
                                                        {
                                                            cookiedAddress.address1
                                                        }
                                                    </div>
                                                    <div className="clipped detail small text-black-50 mb-2">
                                                        {
                                                            cookiedAddress.address2
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        <div className="col-sm-3 col-6">
                                            <button
                                                className="rounded-2 gap-2 btn box-light border h-100 w-100 d-flex flex-column justify-content-center align-items-center"
                                                data-bs-toggle="modal"
                                                data-bs-target="#addressAddModal"
                                                type="button"
                                            >
                                                <i className="bi bi-house h1"></i>
                                                <span className="fw-bold h5">
                                                    Yeni Adres Ekle
                                                </span>
                                                <span>
                                                    Adres ekleme formu için
                                                    tıklayın.
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    {userAddresses &&
                                    userAddresses.length > 0 ? (
                                        <div className="d-flex my-5">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="flexCheckDefault"
                                                    onChange={
                                                        diferentCheckChange
                                                    }
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckDefault"
                                                >
                                                    Farklı fatura adresi
                                                    kullanmak istiyorum.
                                                </label>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {/* Fatura Adresleri */}
                                    {isDifferentShiping == true ? (
                                        <>
                                            <header className="header d-flex flex-wrap gap-3 justify-content-between align-items-center mb-4">
                                                <h5 className="fw-bold">
                                                    <i className="bi bi-file-earmark-medical me-2"></i>
                                                    <span>Fatura Adresi</span>
                                                </h5>
                                            </header>
                                            <div className="row g-3">
                                                {userAddresses &&
                                                    userAddresses.map(
                                                        (address) => (
                                                            <div
                                                                key={address.id}
                                                                className="col-sm-3 col-6"
                                                                onClick={() =>
                                                                    selectAddressHandler(
                                                                        address.id,
                                                                        'billing'
                                                                    )
                                                                }
                                                            >
                                                                <div
                                                                    className={`h-100 address d-flex flex-column gap-3 border rounded-2 p-4 position-relative selectable ${
                                                                        selectedBillingAddress ==
                                                                        address.id
                                                                            ? 'selected'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    <div className="stack">
                                                                        <div className="title fw-bold ">
                                                                            {
                                                                                address.address1
                                                                            }
                                                                        </div>
                                                                        <div className="clipped detail text-black-50">
                                                                            {
                                                                                address.address2
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="stack">
                                                                        <div className="fw-bold">
                                                                            {
                                                                                address.firstName
                                                                            }{' '}
                                                                            {
                                                                                address.lastName
                                                                            }
                                                                        </div>
                                                                        <div className="text-success fw-bold">
                                                                            {
                                                                                address.phoneNumber
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="edit mt-auto">
                                                                        <button
                                                                            className="btn btn-sm border-primary text-primary rounded-1 px-2 py-1"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#addressEditModal"
                                                                            type="button"
                                                                        >
                                                                            <span
                                                                                id={
                                                                                    address.id
                                                                                }
                                                                                name={
                                                                                    address.id
                                                                                }
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    getAddress(
                                                                                        address.id
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Düzenle
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {/* Kargo Seçenekleri */}
                                <div className="cargoList">
                                    <header className="header d-flex flex-wrap gap-3 justify-content-between align-items-center mb-4">
                                        <h5 className="fw-bold">
                                            <i className="bi bi-truck me-2"></i>
                                            <span>Kargo Seçenekleri</span>
                                        </h5>
                                    </header>
                                    <div className="row g-3">
                                        {cargos.cargos &&
                                            cargos.cargos.map((cargo) => (
                                                <div
                                                    key={cargo.id}
                                                    className="col-sm-6"
                                                    onClick={() =>
                                                        selectKargoHandler(
                                                            cargo.id,
                                                            cargo.basePrice
                                                        )
                                                    }
                                                >
                                                    <div
                                                        className={`d-flex gap-3 align-items-center cargo bg-white rounded-2 p-4 border selectable ${
                                                            selectedCargo ==
                                                            cargo.id
                                                                ? 'selected'
                                                                : ''
                                                        }`}
                                                    >
                                                        <div className="col-2">
                                                            <div className="image bg-light rounded-2 p-2">
                                                                <img
                                                                    className="img-fluid"
                                                                    src={
                                                                        cargo.logoUrl !=
                                                                        null
                                                                            ? cargo.logoUrl
                                                                            : 'assets/images/noimage.jpg'
                                                                    }
                                                                    alt={
                                                                        cargo.name
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="title fw-bold">
                                                                {cargo.name}
                                                            </div>
                                                            <div className="detail text-black-50">
                                                                Tahmini Teslimat{' '}
                                                                <span className="fw-bold">
                                                                    {
                                                                        cargo.estimatedDay
                                                                    }{' '}
                                                                    İş Günü
                                                                </span>
                                                            </div>
                                                            <div className="ms-auto">
                                                                {totalInfo.totalDiscountedPrice >=
                                                                cargoPriceFree ? (
                                                                    <span className="fw-bold text-success">
                                                                        Ücretsiz
                                                                    </span>
                                                                ) : (
                                                                    <span className="fw-bold">
                                                                        {`${cargo.basePrice.toFixed(
                                                                            2
                                                                        )}`}{' '}
                                                                        TL
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                {!userInfo ? (
                                    <div className="unregisterMail box-light rounded-3 p-3">
                                        <div className="header d-flex align-items-center">
                                            <div className="col-sm-8 col-6 h5 fw-bold">
                                                <i className="bi bi-at me-1"></i>
                                                <span>E-Posta Adresiniz</span>
                                                <small className="d-block fw-light mt-1">
                                                    Siparişiniz hakkındaki
                                                    bilgilendirmelerin
                                                    gönderileceği e-posta
                                                    adresinizi yazınız.
                                                </small>
                                            </div>
                                            <div className="ms-auto flex-grow-1">
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    name="unregisterMail"
                                                    placeholder="E-Posta adresinizi yazınız"
                                                    autocomplete="email"
                                                    onChange={(e) =>
                                                        userUnregisterEmail(e)
                                                    }
                                                    onBlur={(e) =>
                                                        userEmailControl(e)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className="col-sm-3 mb-5">
                            <div className="summary border rounded-2 sticky-upper overflow-hidden">
                                <header className="border-bottom p-4 h5 fw-bold bg-light">
                                    Sipariş Özeti
                                </header>
                                <div className="d-flex flex-column ">
                                    <div className="detail d-flex flex-column gap-3 p-4 border-bottom">
                                        {/* Özet Elemanı */}
                                        <div className="d-flex justify-content-start align-items-start gap-3">
                                            <i className="bi bi-file-text text-primary"></i>
                                            <div className="d-flex flex-column">
                                                <span className="text-black-50 small">
                                                    İndirimsiz Toplam
                                                </span>
                                                <span className="fw-bold">
                                                    {totalInfo.totalPrice.toFixed(
                                                        2
                                                    )}{' '}
                                                    TL{' '}
                                                    <span className="text-danger small fw-normal">
                                                        ({totalInfo.KDV}% KDV
                                                        Dahil)
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        {totalInfo.totalPrice >
                                        totalInfo.totalDiscountedPrice ? (
                                            <div className="d-flex justify-content-start align-items-start gap-3">
                                                <i className="bi bi-file-medical text-primary"></i>
                                                <div className="d-flex flex-column">
                                                    <span className="text-black-50 small">
                                                        İndirimli Tutar
                                                    </span>
                                                    <span className="fw-bold">
                                                        {totalInfo.totalDiscountedPrice.toFixed(
                                                            2
                                                        )}{' '}
                                                        TL
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        <div className="d-flex justify-content-start align-items-start gap-3">
                                            <i className="bi bi-box-seam text-primary"></i>
                                            <div className="d-flex flex-column">
                                                <span className="text-black-50 small">
                                                    Kargo Bedeli
                                                </span>
                                                {Number(
                                                    totalInfo.totalDiscountedPrice
                                                ) >= cargoPriceFree ? (
                                                    <span className="fw-bold text-success">
                                                        ÜCRETSİZ
                                                    </span>
                                                ) : (
                                                    <span className="fw-bold">
                                                        {totalInfo.Kargo <= 0
                                                            ? 'Kargo Seçiniz'
                                                            : totalInfo.Kargo +
                                                              ' TL'}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="total px-4 pt-4">
                                        <div className="fw-normal text-black-50">
                                            Ödenecek Tutar
                                        </div>
                                        <div className="fw-bolder h4">
                                            {' '}
                                            {(
                                                totalInfo.totalDiscountedPrice +
                                                totalInfo.Kargo
                                            ).toFixed(2)}{' '}
                                            TL
                                        </div>
                                    </div>
                                    <div className="d-grid mt-4">
                                        <button
                                            className="btn btn-lg btn-primary py-3 fw-bold rounded-2 mx-4 mb-4 d-flex justify-content-between"
                                            type="button"
                                            onClick={() => btnOnPaymentClick()}
                                        >
                                            Devam Et
                                            <i className="bi bi-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BrowserView>
            <MobileView className="checkout-address py-5 flex-fill">
                <div className="container">
                    <div className="row">
                        {/* Basket Header */}
                        <div className="col-sm-12">
                            <div className="d-flex border border-danger rounded-2 overflow-hidden gap-3 align-items-stretch text-danger mb-5">
                                <span className="d-flex align-items-center bg-danger text-white py-2 px-3">
                                    <i className="bi bi-exclamation-circle"></i>
                                </span>{' '}
                                <span className="p-2">
                                    Ödeme işlemine devam edebilmek için lütfen{' '}
                                    <strong>Teslimat Adresi</strong> ve{' '}
                                    <strong>Kargo</strong> seçiniz.
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-9">
                            {/* Teslimat Adresi */}
                            <header className="header d-flex flex-wrap gap-3 justify-content-between align-items-center mb-4">
                                <h5 className="fw-bold">
                                    <i className="bi bi-house me-2"></i>
                                    <span>Teslimat Adresi</span>
                                </h5>
                                <button
                                    className="btn btn-link fw-bold text-decoration-none"
                                    data-bs-toggle="modal"
                                    data-bs-target="#addressAddModal"
                                    type="button"
                                >
                                    Yeni Adres Ekle
                                </button>
                            </header>
                            <div className="bg-white d-flex flex-column gap-4">
                                {/* Adresler */}
                                <div className="addressList">
                                    <div className="d-flex flex-column gap-3">
                                        {/* Adres Listesi Boş Olduğunda Gösterilecek */}
                                        {!cookiedAddress &&
                                        userAddresses &&
                                        userAddresses.length < 1 ? (
                                            <div className="empty-address-list p-4 text-black-50 text-center d-flex flex-column align-items-center gap-1 justify-content-center">
                                                <i className="bi bi-house h3"></i>
                                                <h5 className="fw-bold">
                                                    Adres Listeniz Boş
                                                </h5>
                                                <p>
                                                    Lütfen Bir Teslimat Adresi
                                                    Ekleyin.
                                                </p>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {/* Adres Listesi Boş Olduğunda Gösterilecek */}
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="rounded-3 shadow-sm">
                                                    <i className="bi bi-geo-alt me-2"></i>
                                                    <span>
                                                        {userAddresses &&
                                                            selectedAddress &&
                                                            userAddresses
                                                                .filter(
                                                                    (idd) =>
                                                                        idd.id ==
                                                                        selectedAddress
                                                                )
                                                                .map(
                                                                    (aName) =>
                                                                        'Teslim edilecek adres ' +
                                                                        aName.address1
                                                                )}
                                                        {!selectedAddress
                                                            ? 'Teslimat adresinizi seçiniz'
                                                            : ''}
                                                    </span>
                                                </Accordion.Header>
                                                <Accordion.Body className="border-0 p-0 rounded-3 d-flex flex-column gap-2">
                                                    {userAddresses &&
                                                        userAddresses.map(
                                                            (address) => (
                                                                <div
                                                                    key={
                                                                        address.id
                                                                    }
                                                                    onClick={() =>
                                                                        selectAddressHandler(
                                                                            address.id,
                                                                            'shipping'
                                                                        )
                                                                    }
                                                                >
                                                                    <div
                                                                        className={`h-100 product d-flex flex-column gap-2 border rounded-3 p-4 position-relative selectable ${
                                                                            selectedAddress ==
                                                                            address.id
                                                                                ? 'selected'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        <div className="stack">
                                                                            <div className="title fw-bold">
                                                                                {
                                                                                    address.address1
                                                                                }
                                                                            </div>
                                                                            <div className="clipped detail fw-normal text-black-50">
                                                                                {
                                                                                    address.address2
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <div className="stack">
                                                                                <div className="fw-bold">
                                                                                    {
                                                                                        address.firstName
                                                                                    }{' '}
                                                                                    {
                                                                                        address.lastName
                                                                                    }
                                                                                </div>
                                                                                <div className="text-success fw-bold">
                                                                                    {
                                                                                        address.phoneNumber
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="edit mt-auto">
                                                                                <button
                                                                                    className="btn btn-sm border-primary text-primary rounded-1 px-2 py-1"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#addressEditModal"
                                                                                    type="button"
                                                                                >
                                                                                    <span
                                                                                        id={
                                                                                            address.id
                                                                                        }
                                                                                        name={
                                                                                            address.id
                                                                                        }
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            getAddress(
                                                                                                address.id
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Düzenle
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    {!userInfo &&
                                                    cookiedAddress ? (
                                                        <div
                                                            key={1}
                                                            className="col-sm-3 col-6"
                                                            onClick={() =>
                                                                selectAddressHandler(
                                                                    1,
                                                                    'shipping'
                                                                )
                                                            }
                                                        >
                                                            <div
                                                                className={`address bg-white rounded-3 p-4 border ${
                                                                    selectedAddress ==
                                                                    1
                                                                        ? 'selected'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <div className="title h5 fw-bold my-2">
                                                                    {
                                                                        cookiedAddress.address1
                                                                    }
                                                                </div>
                                                                <div className="clipped detail small text-black-50 mb-2">
                                                                    {
                                                                        cookiedAddress.address2
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                    {userAddresses &&
                                    userAddresses.length > 0 ? (
                                        <div className="d-flex my-3">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="flexCheckDefault"
                                                    onChange={
                                                        diferentCheckChange
                                                    }
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckDefault"
                                                >
                                                    Farklı fatura adresi
                                                    kullanmak istiyorum.
                                                </label>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {/* Fatura Adresleri */}
                                    {isDifferentShiping == true ? (
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="rounded-3 shadow-sm">
                                                    <i className="bi bi-geo-alt me-2"></i>
                                                    <span>
                                                        {userAddresses &&
                                                            selectedBillingAddress &&
                                                            userAddresses
                                                                .filter(
                                                                    (fdd) =>
                                                                        fdd.id ==
                                                                        selectedBillingAddress
                                                                )
                                                                .map(
                                                                    (fName) =>
                                                                        'Faturanın gönderileceği adres ' +
                                                                        fName.address1
                                                                )}
                                                        {!selectedBillingAddress
                                                            ? 'Adres seçiniz'
                                                            : ''}
                                                    </span>
                                                </Accordion.Header>
                                                <Accordion.Body className="border-0 p-0 rounded-3 d-flex flex-column gap-2">
                                                    {userAddresses &&
                                                        userAddresses.map(
                                                            (address) => (
                                                                <div
                                                                    key={
                                                                        address.id
                                                                    }
                                                                    onClick={() =>
                                                                        selectAddressHandler(
                                                                            address.id,
                                                                            'billing'
                                                                        )
                                                                    }
                                                                >
                                                                    <div
                                                                        className={`h-100 product d-flex flex-column gap-2 border rounded-3 p-4 position-relative selectable ${
                                                                            selectedBillingAddress ==
                                                                            address.id
                                                                                ? 'selected'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        <div className="stack">
                                                                            <div className="title fw-bold">
                                                                                {
                                                                                    address.address1
                                                                                }
                                                                            </div>
                                                                            <div className="clipped detail fw-normal text-black-50">
                                                                                {
                                                                                    address.address2
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <div className="stack">
                                                                                <div className="fw-bold">
                                                                                    {
                                                                                        address.firstName
                                                                                    }{' '}
                                                                                    {
                                                                                        address.lastName
                                                                                    }
                                                                                </div>
                                                                                <div className="text-success fw-bold">
                                                                                    {
                                                                                        address.phoneNumber
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="edit mt-auto">
                                                                                <button
                                                                                    className="btn btn-sm border-primary text-primary rounded-1 px-2 py-1"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#addressEditModal"
                                                                                    type="button"
                                                                                >
                                                                                    <span
                                                                                        id={
                                                                                            address.id
                                                                                        }
                                                                                        name={
                                                                                            address.id
                                                                                        }
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            getAddress(
                                                                                                address.id
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Düzenle
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {/* Kargo Seçenekleri */}
                                <div className="cargoList">
                                    <header className="header d-flex flex-wrap gap-3 justify-content-between align-items-center mb-4">
                                        <h5 className="fw-bold">
                                            <i className="bi bi-truck me-2"></i>
                                            <span>Kargo Seçenekleri</span>
                                        </h5>
                                    </header>
                                    <div className="row g-3">
                                        {cargos.cargos &&
                                            cargos.cargos.map((cargo) => (
                                                <div
                                                    key={cargo.id}
                                                    className="col-sm-6"
                                                    onClick={() =>
                                                        selectKargoHandler(
                                                            cargo.id,
                                                            cargo.basePrice
                                                        )
                                                    }
                                                >
                                                    <div
                                                        className={`d-flex gap-3 align-items-center cargo bg-white rounded-3 p-4 border selectable shadow-sm ${
                                                            selectedCargo ==
                                                            cargo.id
                                                                ? 'selected'
                                                                : ''
                                                        }`}
                                                    >
                                                        <div className="col-3">
                                                            <div className="image bg-light rounded-2 p-2">
                                                                <img
                                                                    className="img-fluid"
                                                                    src={
                                                                        cargo.logoUrl !=
                                                                        null
                                                                            ? cargo.logoUrl
                                                                            : 'assets/images/noimage.jpg'
                                                                    }
                                                                    alt={
                                                                        cargo.name
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="title fw-bold">
                                                                {cargo.name}
                                                            </div>
                                                            <div className="detail text-black-50">
                                                                Tahmini Teslimat{' '}
                                                                <span className="fw-bold">
                                                                    {
                                                                        cargo.estimatedDay
                                                                    }{' '}
                                                                    İş Günü
                                                                </span>
                                                            </div>
                                                            <div className="ms-auto">
                                                                {totalInfo.totalDiscountedPrice >=
                                                                cargoPriceFree ? (
                                                                    <span className="fw-bold text-success">
                                                                        Ücretsiz
                                                                    </span>
                                                                ) : (
                                                                    <span className="fw-bold">
                                                                        {`${cargo.basePrice.toFixed(
                                                                            2
                                                                        )}`}{' '}
                                                                        TL
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                {!userInfo ? (
                                    <div className="unregisterMail box-light rounded-3 p-3">
                                        <div className="header d-flex align-items-center">
                                            <div className="col-sm-8 col-6 h5 fw-bold">
                                                <i className="bi bi-at me-1"></i>
                                                <span>E-Posta Adresiniz</span>
                                                <small className="d-block fw-light mt-1">
                                                    Siparişiniz hakkındaki
                                                    bilgilendirmelerin
                                                    gönderileceği mail
                                                    adresinizi yazınız.
                                                </small>
                                            </div>
                                            <div className="ms-auto flex-grow-1">
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    name="unregisterMail"
                                                    placeholder="E-Posta adresinizi yazınız"
                                                    autocomplete="email"
                                                    onChange={(e) =>
                                                        userUnregisterEmail(e)
                                                    }
                                                    onBlur={(e) =>
                                                        userEmailControl(e)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className="position-fixed bottom-0 p-0 z-index-3 w-100">
                            <div className="d-flex flex-column gap-3 bg-white p-3 m-3 rounded-3 border shadow">
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className="rounded-3">
                                            <i className="bi bi-receipt me-2"></i>
                                            <span>Detayları Göster</span>
                                        </Accordion.Header>
                                        <Accordion.Body className="pt-2 pb-0">
                                            <div className="detail d-flex flex-column gap-3 py-4">
                                                {/* Özet Elemanı */}
                                                <div className="d-flex justify-content-start align-items-start gap-3">
                                                    <i className="bi bi-file-text text-primary"></i>
                                                    <div className="d-flex flex-column">
                                                        <span className="text-black-50 small">
                                                            İndirimsiz Toplam
                                                        </span>
                                                        <span className="fw-bold">
                                                            {totalInfo.totalPrice.toFixed(
                                                                2
                                                            )}{' '}
                                                            TL{' '}
                                                            <span className="text-danger small fw-normal">
                                                                ({totalInfo.KDV}
                                                                % KDV Dahil)
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                {totalInfo.totalPrice >
                                                totalInfo.totalDiscountedPrice ? (
                                                    <div className="d-flex justify-content-start align-items-start gap-3">
                                                        <i className="bi bi-file-medical text-primary"></i>
                                                        <div className="d-flex flex-column">
                                                            <span className="text-black-50 small">
                                                                İndirimli Tutar
                                                            </span>
                                                            <span className="fw-bold">
                                                                {totalInfo.totalDiscountedPrice.toFixed(
                                                                    2
                                                                )}{' '}
                                                                TL
                                                            </span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                <div className="d-flex justify-content-start align-items-start gap-3">
                                                    <i className="bi bi-box-seam text-primary"></i>
                                                    <div className="d-flex flex-column">
                                                        <span className="text-black-50 small">
                                                            Kargo Bedeli
                                                        </span>
                                                        {Number(
                                                            totalInfo.totalDiscountedPrice
                                                        ) >= cargoPriceFree ? (
                                                            <span className="fw-bold text-success">
                                                                ÜCRETSİZ
                                                            </span>
                                                        ) : (
                                                            <span className="fw-bold">
                                                                {totalInfo.Kargo <=
                                                                0
                                                                    ? 'Kargo Seçiniz'
                                                                    : totalInfo.Kargo +
                                                                      ' TL'}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="total">
                                        <div className="fw-normal text-black-50">
                                            Ödenecek Tutar
                                        </div>
                                        <div className="fw-bolder h4 text-success">
                                            {' '}
                                            {(
                                                totalInfo.totalDiscountedPrice +
                                                totalInfo.Kargo
                                            ).toFixed(2)}{' '}
                                            TL
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => btnOnPaymentClick()}
                                        className="btn btn-lg btn-primary py-3 fw-bold rounded-3 d-flex justify-content-between"
                                        type="button"
                                    >
                                        <span>Devam Et</span>
                                        <i className="bi bi-chevron-right ms-2"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MobileView>
            {/* Adres Ekle Modal */}
            <div
                className="modal fade"
                id="addressAddModal"
                tabIndex="-1"
                aria-labelledby="addressAddModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable overflow-hidden">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-5 pb-0">
                            <h4 className="modal-title text-primary">
                                Adres Ekle
                            </h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body px-5">
                            <div className="mb-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    id="address1"
                                    name="address1"
                                    placeholder="Adres Başlığı Örn: İş, Evim, Okul vb."
                                    onChange={(e) => userAddressAddHandler(e)}
                                />
                            </div>
                            <div className="row g-3 mb-3">
                                <label className="form-label fw-bold h5 mt-5">
                                    <i className="bi bi-person me-2 text-primary"></i>{' '}
                                    Teslim Alacak Kişi Bilgileri
                                </label>
                                <div className="col">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        placeholder="Ad"
                                        aria-label="Ad"
                                        onChange={(e) =>
                                            userAddressAddHandler(e)
                                        }
                                    />
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Soyad"
                                        aria-label="Soyad"
                                        onChange={(e) =>
                                            userAddressAddHandler(e)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <PhoneInput
                                    onlyCountries={['tr']}
                                    country={'tr'}
                                    inputProps={{
                                        name: 'phoneNumber',
                                        id: 'phoneNumberAdd',
                                        className:
                                            'form-control w-100 rounded-3 py-4',
                                        placeholder: 'Cep Telefonu (GSM) No',
                                        onKeyUp: (e) => {
                                            KeyCheck(e);
                                        },
                                        onClick: (e) => {
                                            KeyCheck(e);
                                        }
                                    }}
                                />
                                <div id="phoneHelpBlock" className="form-text">
                                    Siparişlerinizin gönderim ve kargo süreçleri
                                    hakkında bilgilendirmelerin gönderilebilmesi
                                    için aktif ve geçerli bir telefon numarası
                                    giriniz.
                                </div>
                            </div>
                            <div className="mb-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    id="tcKimlikNo"
                                    name="tcKimlikNo"
                                    pattern="[0-9]*"
                                    placeholder="Tc Kimlik No Giriniz"
                                    onChange={(e) => userAddressAddHandler(e)}
                                    maxLength="11"
                                />
                            </div>
                            <div className="form-subheader fw-bold h5 mb-3 mt-5">
                                <i className="bi bi-house me-2 text-primary"></i>
                                Adres Bilgileriniz
                            </div>
                            <div className="mb-3">
                                <select
                                    id="stateProvinceId"
                                    name="stateProvinceId"
                                    className="form-select"
                                    aria-label="İl Seçiniz"
                                    onChange={(e) => {
                                        userAddressAddHandler(e);
                                        changeProvinceSelect(e);
                                    }}
                                >
                                    <option className="selected" value="">
                                        İl Seçiniz
                                    </option>
                                    {getAllCountries &&
                                        getAllCountries.map((c) => (
                                            <option value={c.id} key={c.id}>
                                                {c.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <select
                                    id="districtId"
                                    name="districtId"
                                    className="form-select"
                                    aria-label="İlçe Seçiniz"
                                    onChange={(e) => userAddressAddHandler(e)}
                                >
                                    <option className="selected" value="">
                                        İlçe Seçiniz
                                    </option>
                                    {allDistricts &&
                                        allDistricts.map((d) => (
                                            <option value={d.id} key={d.id}>
                                                {d.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <textarea
                                    className="form-control"
                                    rows="5"
                                    id="address2"
                                    name="address2"
                                    placeholder="Mahalle, sokak, cadde ve diğer bilgilerinizi giriniz."
                                    onChange={(e) => userAddressAddHandler(e)}
                                ></textarea>
                            </div>
                        </div>
                        <div className="modal-footer border-0 p-5 pt-0 align-items-center">
                            <div className="ms-auto">
                                <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    onClick={addAdreess}
                                >
                                    Kaydet
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Adres Düzenle Modal */}
            <div
                className="modal fade"
                id="addressEditModal"
                tabIndex="-1"
                aria-labelledby="addressEditModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable overflow-hidden">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-5 pb-0">
                            <h4 className="modal-title text-primary">
                                Adres Düzenle
                            </h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body px-5">
                            <form id="editAddress">
                                <div className="mb-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="address1"
                                        name="address1"
                                        placeholder="Adres Başlığı Örn: İş, Evim, Okul vb."
                                        onChange={(e) =>
                                            userAddressEditHandler(e)
                                        }
                                        defaultValue={userAddress.address1}
                                    />
                                </div>
                                <div className="row g-3 mb-3">
                                    <label className="form-label fw-bold h5 mt-5">
                                        <i className="bi bi-person me-2 text-primary"></i>{' '}
                                        Teslim Alacak Kişi Bilgileri
                                    </label>
                                    <div className="col">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            placeholder="Ad"
                                            aria-label="Ad"
                                            onChange={(e) =>
                                                userAddressEditHandler(e)
                                            }
                                            defaultValue={userAddress.firstName}
                                        />
                                    </div>
                                    <div className="col">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            placeholder="Soyad"
                                            aria-label="Soyad"
                                            onChange={(e) => {
                                                userAddressEditHandler(e);
                                            }}
                                            defaultValue={userAddress.lastName}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <PhoneInput
                                        onlyCountries={['tr']}
                                        country={'tr'}
                                        value={'+9' + userAddress.phoneNumber}
                                        inputProps={{
                                            name: 'phoneNumber',
                                            id: 'phoneNumberEdit',
                                            className:
                                                'form-control w-100 rounded-3 py-4',
                                            placeholder:
                                                'Cep Telefonu (GSM) No',
                                            onKeyUp: (e) => {
                                                KeyCheck(e);
                                            },
                                            onClick: (e) => {
                                                KeyCheck(e);
                                            }
                                        }}
                                        defaultValue={
                                            '+9' + userAddress.phoneNumber
                                        }
                                    />
                                    <div
                                        id="phoneHelpBlock"
                                        className="form-text"
                                    >
                                        Siparişlerinizin gönderim ve kargo
                                        süreçleri hakkında bilgilendirmelerin
                                        gönderilebilmesi için aktif ve geçerli
                                        bir telefon numarası giriniz.
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="tcKimlikNo"
                                        name="tcKimlikNo"
                                        placeholder="Tc Kimlik No Giriniz"
                                        defaultValue={userAddress.tcKimlikNo}
                                        onChange={(e) => {
                                            userAddressEditHandler(e);
                                        }}
                                    />
                                </div>
                                <div className="form-subheader fw-bold h5 mb-3 mt-5">
                                    <i className="bi bi-house me-2 text-primary"></i>
                                    Adres Bilgileriniz
                                </div>
                                <div className="mb-3">
                                    <select
                                        id="stateProvinceId"
                                        name="stateProvinceId"
                                        className="form-select"
                                        aria-label="İl Seçiniz"
                                        onChange={(e) => {
                                            userAddressEditHandler(e);
                                            changeProvinceSelect(e);
                                        }}
                                    >
                                        <option value="">İl Seçiniz</option>
                                        {getAllCountries &&
                                            getAllCountries.map((c) => (
                                                <option
                                                    value={c.id}
                                                    key={c.id}
                                                    selected={
                                                        userAddress.stateProvinceId &&
                                                        userAddress.stateProvinceId ==
                                                            c.id
                                                    }
                                                >
                                                    {c.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <select
                                        id="districtId"
                                        name="districtId"
                                        className="form-select"
                                        aria-label="İlçe Seçiniz"
                                        onChange={(e) =>
                                            userAddressEditHandler(e)
                                        }
                                    >
                                        <option value="">İlçe Seçiniz</option>
                                        {allDistricts &&
                                            allDistricts.map((d) => (
                                                <option
                                                    value={d.id}
                                                    key={d.id}
                                                    selected={
                                                        userAddress.districtId &&
                                                        userAddress.districtId ==
                                                            d.id
                                                    }
                                                >
                                                    {d.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <textarea
                                        className="form-control"
                                        rows="5"
                                        id="address2"
                                        name="address2"
                                        placeholder="Mahalle, sokak, cadde ve diğer bilgilerinizi giriniz."
                                        onChange={(e) => {
                                            userAddressEditHandler(e);
                                        }}
                                        defaultValue={userAddress.address2}
                                    ></textarea>
                                </div>
                                {/*<div className="form-subheader fw-bold h5 mb-3 mt-5">*/}
                                {/*  <i className="bi bi-receipt me-2 text-primary"></i>Fatura*/}
                                {/*  Bilgileriniz*/}
                                {/*</div>*/}
                                {/*<div className="mb-3">*/}
                                {/*  <label className="form-label me-3">Fatura Tipi</label>*/}
                                {/*  <div className="form-check form-check-inline">*/}
                                {/*    <input*/}
                                {/*      className="form-check-input"*/}
                                {/*      type="checkbox"*/}
                                {/*      name="individual"*/}
                                {/*      id="individual"*/}
                                {/*      value="Bireysel"*/}
                                {/*    />*/}
                                {/*    <label className="form-check-label" htmlFor="individual">*/}
                                {/*      Bireysel*/}
                                {/*    </label>*/}
                                {/*  </div>*/}
                                {/*  <div className="form-check form-check-inline">*/}
                                {/*    <input*/}
                                {/*      className="form-check-input"*/}
                                {/*      type="checkbox"*/}
                                {/*      name="corporate"*/}
                                {/*      id="corporate"*/}
                                {/*      value="Kurumsal"*/}
                                {/*    />*/}
                                {/*    <label className="form-check-label" htmlFor="corporate">*/}
                                {/*      Kurumsal*/}
                                {/*    </label>*/}
                                {/*  </div>*/}
                                {/*</div>*/}
                            </form>
                        </div>
                        <div className="modal-footer border-0 p-5 pt-0 align-items-center">
                            <div className="ms-auto">
                                <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    onClick={editAdreess}
                                >
                                    Kaydet
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddressCheck;
