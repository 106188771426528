export default function CustomerPolicy(props) {
    return (
        <div
            className="modal fade"
            id="customerPolicyModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="customerPolicyModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5
                            className="modal-title"
                            id="customerPolicyModalLabel"
                        >
                            Üyelik Sözleşmesi
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body lh-lg">
                        <h5>
                            <strong>
                                KULLANIM KOŞULLARI VE ÜYELİK SÖZLEŞMESİ
                            </strong>
                        </h5>
                        <p>
                            Sitemizi kullanmadan önce lütfen aşağıda yazılı
                            koşulları okuyunuz. Bu alışveriş sitesinde sunulan
                            hizmetlerden yararlananlar veya herhangi bir şekilde
                            siteye erişim sağlayan her gerçek ve tüzel kişi
                            aşağıdaki kullanım koşullarını kabul etmiş
                            sayılmaktadır.
                        </p>
                        <p>
                            Sitemizde sunulan hizmet Çobançeşme Mah. Bilge Sok.
                            No:17 Yenibosna 34196 Bahçelievler/İstanbul
                            adresinde mukim TESAN İLETİŞİM AŞ. (Bundan sonra
                            kısaca " TESAN " olarak anılacaktır.) tarafından
                            sağlanmaktadır. Sitenin yasal sahibi TESAN olup,
                            site üzerinde her türlü kullanım ve tasarruf yetkisi
                            TESAN’a aittir.
                        </p>
                        <p>
                            İşbu Sözleşme Taraflara sözleşme konusu site ile
                            ilgili hak ve yükümlülükler yükler ve Taraflar işbu
                            Sözleşmeyi kabul ettiklerinde bahsi geçen hak ve
                            yükümlülükleri eksiksiz, doğru, zamanında işbu
                            sözleşmede talep edilen şartlar dahilinde yerine
                            getireceklerini beyan ederler.{' '}
                        </p>
                        <h4>
                            <strong>1. TANIMLAR:</strong>
                        </h4>
                        <h5>
                            <strong>1.1. SİTE:</strong>
                        </h5>
                        <p>
                            TESAN tarafından belirlenen çerçeve içerisinde
                            çeşitli hizmetlerin ve içeriklerin sunulduğu,
                            ürünlerin tanıtım ve satış hizmetlerinin
                            gerçekleştirildiği çevrimiçi ortamdan erişimi mümkün
                            olan web sitesidir.
                        </p>
                        <h5>
                            <strong>1.2.</strong>
                        </h5>
                        <p>
                            <strong>ÜYE:</strong> TESAN’dan ürün ve/veya hizmet
                            alımı yapmak üzere faydalanmak isteyen, üyelik
                            formunu eksiksiz doldurarak, TESAN tarafından
                            üyelikleri onaylanarak üyeliği kabul edilen her
                            gerçek ve tüzel kişidir. 18 yaşından gün almış
                            kişiler gerçek kimlik bilgileri ile sitedeki üyelik
                            formunu eksiksiz doldurarak üye olabilirler.{' '}
                        </p>
                        <p>
                            ÜYE olmak isteyen şirketler 18 yaşından gün almış
                            yasal yetkilisinin sitedeki üyelik formunu eksiksiz
                            doldurarak ÜYE olabilir ve kurumları adına işlem
                            yapabilirler. "üye adı" üyeye özeldir ve aynı "ÜYE
                            adı" iki farklı ÜYE’ye verilmez.
                        </p>
                        <h5>
                            <strong>1.3.</strong>
                        </h5>
                        <p>
                            <strong>KULLANICI:</strong> TESAN’a ait web sitesini
                            alışveriş yaparak ya da yapmaksızın ziyaret eden
                            kişidir.
                        </p>
                        <h5>
                            <strong>1.4.</strong>
                        </h5>
                        <p>
                            <strong>ONAYLAMA İŞLEMİ:</strong> ÜYE tarafından,
                            üyelik formunda belirtilen e-posta adresine TESAN'ın
                            e-posta göndermesi ile …………… KULLANICI’nın üyeliğini
                            aktif hale getirilebilmesi için yapılması gereken
                            işlemdir.
                        </p>
                        <h5>
                            <strong>1.5.</strong>
                        </h5>
                        <p>
                            <strong>LİNK:</strong> Site üzerinden bir başka web
                            sitesine, dosyalara, içeriğe veya başka bir web
                            sitesinden siteye, dosyalara ve içeriğe erişimi
                            mümkün kılan bağlantıdır.
                        </p>
                        <h5>
                            <strong>1.6.</strong>
                        </h5>
                        <p>
                            <strong>İÇERİK:</strong> Sitede yayınlanan veya
                            erişimi mümkün olan her türlü bilgi, dosya, resim,
                            program, rakam, fiyat vs. görsel, yazınsal ve
                            işitsel imgelerdir.
                        </p>
                        <h5>
                            <strong>1.7.</strong>
                        </h5>
                        <p>
                            <strong>
                                WEB SİTESİ KULLANIM KOŞULLARI VE ÜYELİK
                                SÖZLEŞMESİ:
                            </strong>{' '}
                            Site vasıtasıyla sunulmakta olan ticari ve kişiye
                            özel nitelikteki hizmetlerden yararlanacak gerçek
                            ve/veya tüzel kişilerle TESAN arasında elektronik
                            ortamda akdedilen işbu sözleşmedir.
                        </p>
                        <h5>
                            <strong>1.8.</strong>
                        </h5>
                        <p>
                            <strong>KİŞİSEL BİLGİ:</strong> Üyenin kimliği,
                            adresi, elektronik posta adresi, telefon numarası,
                            IP adresi, sitenin hangi bölümlerini ziyaret ettiği,
                            domain tipi, browser tipi, ziyaret tarihi, saati vs
                            bilgilerdir.
                        </p>
                        <h4>
                            <strong>
                                2. HİZMETLERİN KAPSAMI VE SORUMLULUKLAR:
                            </strong>
                        </h4>
                        <h5>
                            <strong>2.1.</strong>
                        </h5>
                        <p>
                            TESAN’ın, site üzerinden sunacağı hizmetler genel
                            itibariyle Tüketici Hukuku mevzuatında tanımlanan
                            elektronik ticaretten ibarettir.
                        </p>
                        <h5>
                            <strong>2.2.</strong>
                        </h5>
                        <p>
                            TESAN’ın, site üzerinden vereceği hizmetler sınırlı
                            sayıda olmamak üzere; www.mojue.com.tr adresinde
                            satışa sunulan ürünlerin; ÜYE tarafından bedeli
                            ödendikten sonra, stok durumunun müsait olması
                            halinde, taahhüt edilen sürede ürünün müşteriye
                            kargo firması tarafından TESAN adına ayıpsız olarak
                            teslimidir.
                        </p>
                        <h5>
                            <strong>2.3.</strong>
                        </h5>
                        <p>
                            TESAN, site üzerinden sunacağı hizmetlerin kapsamını
                            ve niteliğini belirlemekte tamamen serbest olup,
                            hizmetlere ilişkin olarak yapacağı değişiklikleri
                            sitede yayınlamasıyla yürürlüğe koymuş addedilir.
                            İşbu kullanım koşulları ve üyelik sözleşmesi TESAN
                            tarafından gerektiği zaman değiştirebilir ancak bu
                            değişiklikler düzenli olarak SİTE'de yayınlanacak ve
                            aynı tarihten itibaren geçerli olacaktır.
                        </p>
                        <h5>
                            <strong>2.4.</strong>
                        </h5>
                        <p>
                            TESAN işbu site ve site uzantısında mevcut her tür
                            hizmet, ürün, kampanya vs. bilgiler ve siteyi
                            kullanma koşulları ile sitede sunulan bilgileri
                            önceden bir ihtara gerek olmaksızın değiştirme,
                            yayını durdurma ve/veya duraklatma hakkını saklı
                            tutar. Değişiklikler, sitede yayınlanmalarıyla
                            yürürlüğe girer ve sitenin kullanımı ya da siteye
                            giriş ile bu değişiklikler de kabul edilmiş sayılır.
                            Bu koşullar link verilen diğer web sayfaları için de
                            geçerlidir.{' '}
                        </p>
                        <p>
                            TESAN, sözleşmenin ihlali, haksız fiil, ihmal veya
                            diğer sebepler neticesinde; işlemin kesintiye
                            uğraması, hata, ihmal, kesinti, silinme, kayıp,
                            işlemin veya iletişimin gecikmesi, bilgisayar
                            virüsü, iletişim hatası, hırsızlık, imha veya
                            izinsiz olarak kayıtlara girilmesi, değiştirilmesi
                            veya kullanılması hususunda ve durumunda herhangi
                            bir sorumluluk kabul etmez.
                        </p>
                        <h5>
                            <strong>2.5.</strong>
                        </h5>
                        <p>
                            Site üzerinden, TESAN'ın kendi kontrolünde olmayan
                            ve başkaca üçüncü kişilerin sahip olduğu ve
                            işlettiği başka web sitelerine ve/veya başka
                            içeriklere link verilebilir. Bu linkler
                            KULLANICI’lara ve ÜYE’lere yönlenme kolaylığı
                            sağlamak amacıyla konmuş olup herhangi bir web
                            sitesini veya o siteyi işleten kişiyi
                            desteklememektedir. Link verilen web sitesinin
                            içerdiği bilgilere yönelik herhangi bir türde bir
                            beyan veya garanti niteliği taşımamaktadır. Site
                            üzerindeki linkler vasıtasıyla erişilen web siteleri
                            ve içerikleri hakkında TESAN'ın herhangi bir
                            sorumluluğu yoktur ve bu sitelerin kullanımıyla
                            doğabilecek zararlar, KULLANICI’ların ve ÜYE’lerin
                            kendi sorumluluğundadır. TESAN bu tür link verilen
                            web sitelerine erişimi, kendi yazılı muvafakatine
                            bağlayabileceği gibi, TESAN uygun görmeyeceği
                            linklere erişimi her zaman kesebilir.
                        </p>
                        <h5>
                            <strong>2.6.</strong>
                        </h5>
                        <p>
                            TESAN, sitede mevcut olan bilgilerin doğruluk ve
                            güncelliğini sürekli olarak kontrol etmektedir.
                            Ancak gösterilen çabaya rağmen, site üzerindeki
                            bilgiler fiili değişikliklerin gerisinde kalabilir.
                            Site muhteviyatında yer alan materyal ve bilgiler
                            siteye verildiği anda sunulmaktadır, ilgili hizmet
                            veya bilginin güncel durumu ile sitede yer alan
                            durumu arasında farklılık olabilir. Sitede yer alan
                            bilgilerin, güncelliği, doğruluğu, şartları,
                            kalitesi, performansı ve TESAN’ın sitede mevcut ve
                            bunlarla sınırlı olmayan, bunlarla bağlantılı veya
                            bağımsız diğer bilgi, hizmet veya ürünlere etkisi
                            ile tamlığı hakkında herhangi bir sarih ya da zımni
                            garanti verilmemekte ve taahhütte bulunulmamaktadır.
                        </p>
                        <h5>
                            <strong>2.7.</strong>
                        </h5>
                        <p>
                            KULLANICI ve ÜYE, sitedeki, yüklenmeye ve/veya
                            paylaşıma müsait dosya, bilgi ve belgelerin,
                            virüslerden, truva atlarından, dialer
                            programlarından, spam, spyware ve/veya bunlar gibi
                            diğer her türlü kötü ve zarar verme amaçlı kodlardan
                            veya materyallerden arındırılamamış olabileceğini ve
                            bu hususlarda sitenin garanti vermediğini kabul
                            etmektedir. Bu tip kötü ve zarar verme amaçlı
                            programların, kodların veya materyallerin önlenmesi,
                            veri giriş çıkışlarının doğruluğu veya herhangi bir
                            kayıp verinin geri kazanılması için gereken tüm
                            yazılım ve donanım ihtiyaçlarını karşılamak, bakım
                            ve güncellemelerini yapmak tamamen KULLANICI’nın ve
                            ÜYE’nin sorumluluğundadır. Bu tür kötü amaçlı
                            programlar, kodlar veya materyallerin sebep
                            olabileceği, veri yanlışlıkları veya kayıplarından
                            dolayı KULLANICI, ÜYE’nin ve/veya üçüncü kişilerin
                            uğrayabileceği hiçbir zarardan TESAN sorumlu
                            değildir.
                        </p>
                        <p>
                            Bu tür kötü amaçlı programlar, kodlar veya
                            materyaller, veri yanlışlıkları veya kayıplarından
                            dolayı KULLANICI’nın, ÜYE’nin ve/veya üçüncü
                            kişilerin uğrayabileceği hiçbir zarardan TESAN
                            sorumlu değildir.
                        </p>
                        <h5>
                            <strong>2.8.</strong>
                        </h5>
                        <p>
                            Site dâhilinde üçüncü kişiler tarafından sağlanan
                            hizmetlerden ve yayınlanan içeriklerden dolayı
                            TESAN’ın, işbirliği içinde bulunduğu kurumların,
                            TESAN çalışanlarının ve yöneticilerinin sorumluluğu
                            bulunmamaktadır.
                        </p>
                        <p>
                            Herhangi bir üçüncü kişi tarafından sağlanan ve
                            yayınlanan bilgilerin, içeriklerin, görsel ve
                            işitsel imgelerin doğruluğu ve hukuka uygunluğunun
                            taahhüdü bütünüyle bu eylemleri gerçekleştiren
                            üçüncü kişilerin sorumluluğundadır. TESAN, üçüncü
                            kişiler tarafından sağlanan hizmetlerin ve
                            içeriklerin güvenliğini, doğruluğunu ve hukuka
                            uygunluğunu taahhüt ve garanti etmemektedir.
                        </p>
                        <h5>
                            <strong>2.9.</strong>
                        </h5>
                        <p>
                            KULLANICI ve ÜYE, sitenin kullanımında tersine
                            mühendislik yapmayacağını ya da bunların kaynak
                            kodunu bulmak veya elde etmek amacına yönelik
                            herhangi bir başka işlemde bulunmayacağını aksi
                            halde ve üçüncü kişiler nezdinde doğacak zararlardan
                            sorumlu olacağını, hakkında hukuki ve cezai işlem
                            yapılacağını peşinen kabul eder.
                        </p>
                        <h5>
                            <strong>2.10.</strong>
                        </h5>
                        <p>
                            KULLANICI ve ÜYE, site içindeki faaliyetlerinde,
                            sitenin herhangi bir bölümünde veya iletişimlerinde
                            genel ahlaka ve adaba aykırı, kanuna aykırı, üçüncü
                            kişilerin haklarını zedeleyen, yanıltıcı, saldırgan,
                            müstehcen, pornografik, kişilik haklarını zedeleyen,
                            telif haklarına aykırı, yasa dışı faaliyetleri
                            teşvik eden içerikler üretmeyeceğini,
                            paylaşmayacağını kabul eder. Aksi halde oluşacak
                            zararlardan tamamen kendisi sorumludur ve bu durumda
                            site yetkilileri, bu tür hesapları askıya alabilir,
                            sona erdirebilir, yasal süreç başlatma hakkını saklı
                            tutar. Bu sebeple idari ve yargı mercilerinden
                            etkinlik veya kullanıcı hesapları ile ilgili bilgi
                            talepleri gelirse paylaşma hakkını saklı tutar.
                            KULLANICI ve ÜYE’lerin site üzerindeki faaliyetleri
                            nedeniyle üçüncü kişilerin uğradıkları veya
                            uğrayabilecekleri zararlardan dolayı TESAN'ın
                            doğrudan ve/veya dolaylı hiçbir sorumluluğu yoktur.
                        </p>
                        <h5>
                            <strong>2.11.</strong>
                        </h5>
                        <p>
                            Ticari elektronik iletiler, Alıcılara ancak önceden
                            onayları alınmak kaydıyla gönderilebilir. Bu onay,
                            yazılı olarak veya her türlü elektronik iletişim
                            araçlarıyla alınabilir. Kendisiyle iletişime
                            geçilmesi amacıyla Alıcının iletişim bilgilerini
                            vermesi hâlinde, temin edilen mal veya hizmetlere
                            ilişkin değişiklik, kullanım ve bakıma yönelik
                            ticari elektronik iletiler için ayrıca onay alınmaz.
                        </p>
                        <h4>
                            <strong>3. FİKRİ MÜLKİYET HAKLARI:</strong>
                        </h4>
                        <p>
                            İşbu sitede yer alan unvan, işletme adı, marka,
                            patent, logo, ürün, fotoğraf, video, tasarım, bilgi
                            ve yöntem gibi tescilli veya tescilsiz tüm fikri
                            mülkiyet haklarının sahibi TESAN olup, ulusal ve
                            uluslararası koruması altındadır. İşbu sitenin
                            ziyaret edilmesi veya bu sitedeki hizmetlerde
                            yararlanılması söz konusu fikri mülkiyet hakları
                            konusunda hiçbir hak vermez. Sitede yer alan
                            bilgiler hiçbir şekilde çoğaltılamaz, yayınlanamaz,
                            kopyalanamaz, sunulamaz ve/veya aktarılamaz. Sitenin
                            bütünü veya bir kısmı diğer bir internet sitesinde
                            v.s. herhangi bir yerde izinsiz kullanılamaz.
                        </p>
                        <h4>
                            <strong>4. GİZLİ BİLGİ:</strong>
                        </h4>
                        <h5>
                            <strong>4.1.</strong>
                        </h5>
                        <p>
                            TESAN, site üzerinden kullanıcıların ilettiği
                            kişisel bilgileri 3. Kişilere açıklamayacaktır. Bu
                            kişisel bilgiler; kişi adı-soyadı, adresi, telefon
                            numarası, cep telefonu, e-posta adresi gibi
                            kullanıcıyı tanımlamaya yönelik her türlü diğer
                            bilgiyi ermekte olup, kısaca " Gizli Bilgiler”
                            olarak anılacaktır.
                        </p>
                        <h5>
                            <strong>4.2.</strong>
                        </h5>
                        <p>
                            Kullanıcı, sadece tanıtım, reklam, kampanya,
                            promosyon, duyuru vb. pazarlama faaliyetleri
                            kapsamında kullanılması ile sınırlı olmak üzere,
                            TESAN’ın grup şirketleri veya iştirakleri ile
                            paylaşmasına muvafakat ettiğini kabul ve beyan eder.
                            Bu kişisel bilgiler firma bünyesinde müşteri profili
                            belirlemek, müşteri profiline uygun promosyon ve
                            kampanyalar sunmak ve istatiksel çalışmalar yapmak
                            amacıyla kullanılabilecektir.{' '}
                        </p>
                        <h5>
                            <strong>4.3.</strong>
                        </h5>
                        <p>
                            Gizli bilgiler, ancak resmi makamlarca usulü
                            dairesinde bu bilgilerin talep edilmesi halinde ve
                            yürürlükteki emredici mevzuat hükümleri gereğince
                            resmi makamlara açıklama yapılmasının zorunlu olduğu
                            durumlarda resmi makamlara açıklanabilecektir.
                        </p>
                        <h4>
                            <strong>5. SORUMLULUĞUN SINIRLANDIRILMASI:</strong>
                        </h4>
                        <p>
                            TESAN siteye erişilmesi, sitenin ya da sitedeki
                            bilgilerin ve diğer verilerin programların vs.
                            kullanılması sebebiyle, sözleşmenin ihlali, haksız
                            fiil, ya da başkaca sebeplere binaen, doğabilecek
                            doğrudan ya da dolaylı hiçbir zarardan sorumlu
                            değildir. TESAN, sözleşmenin ihlali, haksız fiil,
                            ihmal veya diğer sebepler neticesinde; işlemin
                            kesintiye uğraması, hata, ihmal, kesinti hususunda
                            herhangi bir sorumluluk kabul etmez. Bu siteye ya da
                            link verilen diğer web sitelerine erişilmesi ya da
                            sitenin kullanılması ile TESAN’ın, kullanım/ziyaret
                            sonucunda, doğabilecek her tür sorumluluktan,
                            mahkeme ve diğer masraflar da dahil olmak üzere her
                            tür zarar ve talep hakkından ayrı kılındığı kabul
                            edilmektedir.
                        </p>
                        <h4>
                            <strong>6. GARANTİ VERMEME:</strong>
                        </h4>
                        <p>
                            İşbu sözleşme maddesi uygulanabilir kanunun izin
                            verdiği azami ölçüde geçerli olacaktır. TESAN
                            tarafından sunulan hizmetler "olduğu gibi” ve
                            "mümkün olduğu” temelde sunulmakta ve
                            pazarlanabilirlik, belirli bir amaca uygunluk veya
                            ihlal etme konusunda tüm zımni garantiler de dahil
                            olmak üzere hizmetler veya uygulama ile ilgili
                            olarak (bunlarda yer alan tüm bilgiler dahil) sarih
                            veya zımni, kanuni veya başka bir nitelikte hiçbir
                            garantide bulunmamaktadır.{' '}
                        </p>
                        <h4>
                            <strong>7. KAYIT VE GÜVENLİK:</strong>
                        </h4>
                        <p>
                            KULLANICI ve/veya ÜYE, doğru, eksiksiz ve güncel
                            kayıt bilgilerini vermek zorundadır. Aksi halde bu
                            Sözleşme ihlal edilmiş sayılacak ve KULLANICI
                            ve/veya ÜYE bilgilendirilmeksizin hesap
                            kapatılabilecektir. KULLANICI ve/veya ÜYE; site ve
                            üçüncü taraf sitelerdeki şifre ve hesap
                            güvenliğinden kendisi sorumludur. Aksi halde
                            oluşacak veri kayıplarından ve güvenlik
                            ihlallerinden veya donanım ve cihazların zarar
                            görmesinden TESAN sorumlu tutulamaz.{' '}
                        </p>
                        <h4>
                            <strong>8.DEVİR:</strong>
                        </h4>
                        <p>
                            TESAN, işbu sözleşmeyi bildirimsiz olarak istediği
                            zaman kısmen veya bütünüyle devredebilir. Ancak
                            KULLANICI ve ÜYE bu sözleşmeyi veya herhangi bir
                            kısmını başka bir tarafa devredemez.{' '}
                        </p>
                        <h4>
                            <strong>9. MÜCBİR SEBEPLER:</strong>
                        </h4>
                        <p>
                            Tarafların kontrolünde olmayan; tabii afetler,
                            yangın, patlamalar, iç savaşlar, savaşlar,
                            ayaklanmalar, halk hareketleri, seferberlik ilanı,
                            grev, lokavt ve salgın hastalıklar, altyapı ve
                            internet arızaları v.s. gibi sebeplerden dolayı
                            sözleşmeden doğan yükümlülükler taraflarca ifa
                            edilemez hale gelirse, Taraflar bundan sorumlu
                            değildir. Bu sürede Tarafların işbu sözleşmeden
                            doğan hak ve yükümlülükleri askıya alınır.
                        </p>
                        <h4>
                            <strong>
                                10. SÖZLEŞMENİN BÜTÜNLÜĞÜ VE UYGULANABİLİRLİK:
                            </strong>
                        </h4>
                        <p>
                            İşbu sözleşme şartlarından biri, kısmen veya tamamen
                            geçersiz hale gelirse, sözleşmenin geri kalanı
                            geçerliliğini korumaya devam eder.{' '}
                        </p>
                        <h4>
                            <strong>
                                11. SÖZLEŞMEDE YAPILACAK DEĞİŞİKLİKLER:
                            </strong>
                        </h4>
                        <p>
                            TESAN, dilediği zaman sitede sunulan hizmetler ve
                            işbu sözleşme şartlarını kısmen veya tamamen
                            değiştirebilir. Değişiklikler sitede yayınlandığı
                            tarihten itibaren geçerli olacaktır. Değişiklikler
                            takip etmek KULLANICI ve/veya ÜYE’nin
                            sorumluluğundadır. KULLANICI ve/veya ÜYE, sunulan
                            hizmetlerden yararlanmaya devam etmekle bu
                            değişiklikleri de kabul etmiş sayılır.{' '}
                        </p>
                        <h4>
                            <strong>12. TEBLİGAT:</strong>
                        </h4>
                        <p>
                            İşbu sözleşme ile ilgili taraflara gönderilecek olan
                            tüm bildirimler, TESAN’ın bilinen elektronik posta
                            adresi ve KULLANICI ve/veya ÜYE’nin üyelik formunda
                            belirttiği elektronik posta adresi vasıtası ile
                            yapılacaktır. KULLANICI, üye olurken belirttiği
                            adresin geçerli tebligat adresi olduğunu, değişmesi
                            durumunda 5 (beş) gün içinde yazılı olarak diğer
                            tarafa bildireceğini, aksi halde bu adrese yapılacak
                            tebligatların geçeri sayılacağını kabul eder{' '}
                        </p>
                        <h4>
                            <strong>13. UYGULANACAK HUKUK VE YETKİ:</strong>
                        </h4>
                        <p>
                            İşbu "SİTE Kullanım Koşulları" ve Web Sitesi
                            Kullanım Sözleşmesi’nden kaynaklanacak ihtilaflar
                            Türk Hukuku’na tabidir ve İstanbul Bakırköy
                            Mahkemeleri ve İcra Daireleri yetkilidir. TESAN’ın,
                            KULLANICI ve ÜYE’nin bulunduğu ülkede dava açma
                            hakkı saklıdır.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                props.customerPolicyConfirm();
                            }}
                            data-bs-dismiss="modal"
                        >
                            Okudum kabul ediyorum.
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            Kapat
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
