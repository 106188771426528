import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';
import {
    API_BASE,
    contentTechnic,
    contentPackage,
    contentProduct,
    contentVideo,
    contentSpecial,
    content3D,
    cargoPriceFree
} from '../../config/env';
import axios from 'axios';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import ProductDetail from './ProductDetail';
import {
    AddItemToBasket,
    createBasket,
    getCurrentBasket
} from '../../redux/actions/BASKET/BasketCookieActions';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import { BrowserView, MobileView } from 'react-device-detect';

const Product = (props) => {
    const { product } = props.product;
    if (product && product.isActive == false) {
        window.location.href = '/';
    }

    const productDetail_ = props.productDetail;
    const productAttributes = props.product.productAttributes;
    const productAttributeValues = props.product.productAttributeValues;

    const [userInfo, setUserInfo] = useState({});
    const currentURL = window.location.href; // returns the absolute URL of a page

    var firstImage = '';
    var pictureList = [];
    const [productDetail, setProductDetail] = useState({
        ProductId: 0,
        Quantity: 1,
        SelectedVariants: [
            {
                MainVariantId: 0,
                MainVariantValue: '',
                VariantId: 0,
                VariantValue: ''
            }
        ]
    });
    const [announcement, setAnnouncement] = useState([]);
    const [productContents, setProductContent] = useState([]);

    const [asset, setAsset] = useState([]);
    const [sepetButtonDisable, setSepetButtonDisable] = useState(false);
    const [variantPicture, setVariantPicture] = useState([]);
    const [isVariantSelected, setIsVariantSelected] = useState(false);
    const [selectedVariant, setSelectedVariant] = useState('');
    const [productCapacity, setProductCapacity] = useState('');
    const cookies = new Cookies();
    const [basket, setBasket] = useState({
        basketId: 0,
        basketProducts: [
            {
                productId: '',
                productImage: '',
                discountPrice: 0,
                price: 0,
                variantInfo: [
                    {
                        productAttributeId: 0,
                        productAttributeValue: '',
                        productAttributeValueId: 0,
                        productAttributeValueValue: '',
                        price: 0.0,
                        stockId: '',
                        variantPictureId: 0,
                        variantPicturePath: ''
                    }
                ]
            }
        ]
    });
    const primaryRef = React.createRef();
    const secondaryRef = React.createRef();
    const [isCustomerLogin, setIsCustomerLogin] = useState(false);
    useEffect(() => {
        document.getElementById('body').className = 'product-page';

        const userToken = localStorage.getItem('token');
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };

        axios
            .get(`${API_BASE}Announcement/GetAllAnnouncements`, config)
            .then((data) => {
                if (data.data) {
                    if (data.data.code == '200') {
                        setAnnouncement(data.data.set);
                    }
                }
            });

        if (product) {
            axios
                .get(
                    `${API_BASE}Product/GetProductContent?productId=${props.product.product.id}`,
                    config
                )
                .then((data) => {
                    if (data.data) {
                        if (data.data.code == '200') {
                            setProductContent(data.data.set);
                        }
                    }
                });

            axios
                .get(
                    `${API_BASE}Product/GetProductAssets?productId=${props.product.product.id}`,
                    config
                )
                .then((data) => {
                    if (data.data) {
                        if (data.data.code == '200') {
                            setAsset(data.data.set);
                        }
                    }
                });

            if (product.categoryProductMappings[0].categoryId == 4) {
                axios
                    .get(
                        `${API_BASE}Product/GetBatteryCalc?productId=${props.product.product.id}`,
                        config
                    )
                    .then((data) => {
                        if (data.data) {
                            if (data.data.code == '200') {
                                setProductCapacity(data.data.set);
                            }
                        }
                    });
            }
        }

        axios
            .get(
                `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                config
            )
            .then((data) => {
                if (data.data) {
                    if (data.data.code == '200') {
                        setUserInfo(data.data.set);
                        setIsCustomerLogin(true);
                    }
                }
            });

        setVariantStockState({
            stock: {},
            otherVariants: []
        });
        setVariantStock({
            barcode: '',
            discount: null,
            discountedPrice: null,
            id: 0,
            price: null,
            productId: 0,
            productProductAttributeValueMappings: [],
            quantity: 0,
            stockId: '',
            wrkStockId: 0
        });
        //var cookiedBasket = cookies.get('basket');
        //console.log("cuukied");
        //console.log(cookiedBasket);
        //if (cookiedBasket != undefined) setBasket(cookiedBasket);

        if (
            props.product.variantPictureList &&
            props.product.variantPictureList.length > 0
        ) {
            setVariantPicture(props.product.variantPictureList);
        }

        window.scrollTo(0, 0);

        if (
            props.product.productAttributeValues &&
            props.product.productAttributeValues.length == 0
        ) {
            if (
                props.product.stockList &&
                props.product.stockList[0] &&
                props.product.stockList[0].quantity == 0
            )
                setSepetButtonDisable(true);
            if (props.product.stockList.length < 1) {
                setSepetButtonDisable(true);
            }
        }

        if (
            productAttributes &&
            productAttributes.length > 0 &&
            productAttributeValues &&
            productAttributeValues.length > 0
        ) {
            selectVariant(
                productAttributes[0].id,
                productAttributes[0].name,
                productAttributeValues[0].id,
                productAttributeValues[0].value
            );
        }

        // Google Tag Manager dataLayer
        if (product) {
            var dprice = product.prices.filter((dp) => dp.type == 2);
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                event: 'view_item',
                currency: 'TRY',
                value:
                    dprice.length == 1
                        ? dprice[0].value
                        : product.prices[0].value,
                dp: {
                    items: [
                        {
                            id: product.stockId,
                            item_id: product.stockId,
                            item_name: product.name,
                            item_variant: product
                                .productProductAttributeValueMappings
                                .productAttributeValue
                                ? product
                                      .productProductAttributeValueMappings[0]
                                      .productAttributeValue.value
                                : '',
                            currency: 'TRY',
                            discount:
                                dprice.length == 1
                                    ? product.prices[0].value - dprice[0].value
                                    : '',
                            price:
                                dprice.length == 1
                                    ? dprice[0].value
                                    : product.prices[0].value,
                            quantity: 1
                        }
                    ]
                }
            });
        }
    }, [product]);
    const [variantStockState, setVariantStockState] = useState({
        stock: {},
        otherVariants: [],
        clickedVariantId: 0
    });

    const [variantStock, setVariantStock] = useState({
        barcode: '',
        discount: null,
        discountedPrice: null,
        id: 0,
        price: null,
        productId: 0,
        productProductAttributeValueMappings: [],
        quantity: 0,
        stockId: '',
        wrkStockId: 0
    });

    const quantityTextRef = React.createRef();
    const quantityText2Ref = React.createRef();
    function minusQuantity() {
        if (productDetail.Quantity > 1) {
            setProductDetail({
                ProductId: productDetail.ProductId,
                Quantity: productDetail.Quantity - 1,
                SelectedVariants: productDetail.SelectedVariants
            });
            quantityTextRef.current.value =
                Number(quantityTextRef.current.value) - 1;
            quantityText2Ref.current.value =
                Number(quantityText2Ref.current.value) - 1;
        }
    }

    function plusQuantity() {
        setProductDetail({
            ProductId: productDetail.ProductId,
            Quantity: productDetail.Quantity + 1,
            SelectedVariants: productDetail.SelectedVariants
        });
        quantityTextRef.current.value =
            Number(quantityTextRef.current.value) + 1;
        quantityText2Ref.current.value =
            Number(quantityText2Ref.current.value) + 1;
    }

    function selectVariant(
        MainVariantId,
        MainVariantValue,
        VariantId,
        VariantValue
    ) {
        document
            .querySelectorAll('[data-bs-slide-to="0"]')
            .forEach((el) => el.click());
        if (
            productDetail.SelectedVariants.length == 1 &&
            productDetail.SelectedVariants[0].MainVariantId === 0
        ) {
            productDetail.SelectedVariants[0].MainVariantId = MainVariantId;
            productDetail.SelectedVariants[0].MainVariantValue =
                MainVariantValue;
            productDetail.SelectedVariants[0].VariantId = VariantId;
            productDetail.SelectedVariants[0].VariantValue = VariantValue;
        } else {
            if (
                productDetail.SelectedVariants.filter(
                    (p) => p.MainVariantId == MainVariantId
                ).length > 0
            ) {
                productDetail.SelectedVariants.find(
                    (v) => v.MainVariantId == MainVariantId
                ).VariantId = VariantId;
                productDetail.SelectedVariants.find(
                    (v) => v.MainVariantId == MainVariantId
                ).VariantValue = VariantValue;
            } else {
                productDetail.SelectedVariants.push({
                    MainVariantId: MainVariantId,
                    MainVariantValue: MainVariantValue,
                    VariantId: VariantId,
                    VariantValue: VariantValue
                });
            }
        }

        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        axios
            .get(
                `${API_BASE}Product/GetProductStockMapping?id=${VariantId}&productId=${product.id}`,
                config
            )
            .then((data) => {
                if (data.data.set.stock.quantity == 0) {
                    setSepetButtonDisable(true);
                } else {
                    setSepetButtonDisable(false);
                }
                setVariantStockState({
                    ...data.data.set,
                    clickedVariantId: VariantId
                });
                axios
                    .get(
                        `${API_BASE}Order/GetStockById?stockId=${data.data.set.stock.id}`,
                        config
                    )
                    .then((data) => {
                        setVariantStock({ ...data.data.set });
                        axios
                            .get(
                                `${API_BASE}Product/GetVariantPictureById?productId=${product.id}&attributeValueId=${VariantId}`,
                                config
                            )
                            .then((data) => {
                                setVariantPicture(
                                    data.data.set.variantPictureList
                                );
                            });
                    });
            });

        if (selectedVariant != VariantId) setSelectedVariant(VariantId);
        if (productDetail.SelectedVariants.length == productAttributes.length)
            setIsVariantSelected(true);
    }

    function customerAddToCart() {
        const prices = product.prices;

        const cartPrice = prices.filter((p) => p.type === 4);

        const discountPrices = prices.filter((p) => p.type === 2);

        const normalPrices = prices.filter((p) => p.type === 1);
        const normalPrice =
            normalPrices && normalPrices.length > 0 ? normalPrices[0].value : 0;
        var discountPrice =
            discountedPrices && discountedPrices.length > 0
                ? discountedPrices[0].value
                : normalPrice;

        if (cartPrice && cartPrice.length > 0) {
            discountPrice = cartPrice[0].value;
        }

        if (userInfo.isEmployee == true) {
            //discountPrice = normalPrice / 2;
            discountPrice = personelPrices && personelPrices.length > 0 ? personelPrices[0].value : normalPrice;
        }
        const productPicture = variantPicture[0]
            ? variantPicture[0].picture.virtualPath
            : product.productPictureMappings[0].picture.virtualPath;

        const addToCartServiceUrl = 'basket/AddProductToBasket';

        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        const body = JSON.stringify({
            BasketId: basket != null || basket != {} ? basket.basketId : 0,
            CustomerId: userInfo.customerId,
            productId: product.id,
            stockId: variantStockState.stock.id
                ? variantStockState.stock.id
                : props.product.stockList[0].id,
            productName: product.name,
            productShortDesc: product.shortDescription,
            productImage: productPicture,
            discountPrice: Number(discountPrice),
            price: Number(normalPrice),
            quantity: productDetail.Quantity,
            variantInfo: {
                productAttributeId: 0,
                productAttributeValue: '',
                productAttributeValueId: variantStockState.clickedVariantId,
                productAttributeValueValue:
                    productDetail.SelectedVariants[0].VariantValue,
                price: 0.0,
                //stockId: variantStockState.stock.id,
                stockId: variantStockState.stock.id
                    ? variantStockState.stock.id
                    : props.product.stockList[0].id,
                //variantPictureId: product.productPictureMappings[0].picture.id,
                variantPictureId:
                    variantStockState.stockPicture != null
                        ? variantStockState.stockPicture.id
                        : 0,
                variantPicturePath:
                    variantStockState.stockPicture &&
                    variantStockState.stockPicture.virtualPath
            }
        });
        axios
            .post(`${API_BASE}${addToCartServiceUrl}`, body, config)
            .then((data) => {
                setBasket(data.data.set);
                props.showToast(product.name);
                setTimeout(function () {
                    window.location.reload();
                }, 2000);
            })
            .catch((error) => alert(error));
    }

    function cookieAddToCart() {
        const basket = getCurrentBasket();
        const prices = product.prices;

        const cartPrice = prices.filter((p) => p.type === 4);

        const discountPrices = prices.filter((p) => p.type === 2);

        const normalPrices = prices.filter((p) => p.type === 1);
        const normalPrice =
            normalPrices && normalPrices.length > 0 ? normalPrices[0].value : 0;
        var discountPrice =
            discountedPrices && discountedPrices.length > 0
                ? discountedPrices[0].value
                : normalPrice;

        if (cartPrice && cartPrice.length > 0) {
            discountPrice = cartPrice[0].value;
        }

        if (userInfo.isEmployee == true) {
            //discountPrice = normalPrice / 2;
            discountPrice = personelPrices && personelPrices.length > 0 ? personelPrices[0].value : normalPrice;
        }
        const productPicture = variantPicture[0]
            ? variantPicture[0].picture.virtualPath
            : product.productPictureMappings[0].picture.virtualPath;
        const basketItem = {
            productId: product.id,
            stockId: variantStockState.stock.id
                ? variantStockState.stock.id
                : props.product.stockList[0].id,
            productName: product.name,
            productShortDesc: product.shortDescription,
            productImage: productPicture,
            discountPrice: discountPrice,
            price: normalPrice,
            quantity: productDetail.Quantity,
            variantInfo: {
                productAttributeId: 0,
                productAttributeValue: '',
                productAttributeValueId: variantStockState.clickedVariantId,
                productAttributeValueValue:
                    productDetail.SelectedVariants[0].VariantValue,
                price: 0.0,
                //stockId: variantStockState.stock.id,
                stockId: variantStockState.stock.id
                    ? variantStockState.stock.id
                    : props.product.stockList[0].id,
                //variantPictureId: product.productPictureMappings[0].picture.id,
                variantPictureId:
                    variantStockState.stockPicture != null
                        ? variantStockState.stockPicture.id
                        : 0,
                variantPicturePath:
                    variantStockState.stockPicture &&
                    variantStockState.stockPicture.virtualPath
            }
        };

        if (basket === undefined) {
            createBasket(basketItem);
            //console.log("basket created");
        } else {
            //updateBasket
            //console.log(basketItem);
            //console.log("Add product to basket");
            AddItemToBasket(basketItem, productDetail.Quantity);
        }
        props.showToast(product.name);
        setTimeout(function () {
            window.location.reload();
        }, 2000);
    }

    function addToCart() {
        if (isCustomerLogin === true) {
            if (props.product.productAttributeValues.length > 0) {
                if (isVariantSelected) {
                    if (variantStockState.stock) {
                        if (variantStock.quantity == 0) {
                            alert('Stok Yeterli Değil');
                            return false;
                        }
                    }
                    customerAddToCart();
                } else {
                    alert('Lütfen Renk Seçiniz');
                }
            } else {
                const stockList =
                    props && props.product && props.product.stockList;
                if (stockList && stockList.length > 0) {
                    const stock = stockList[0];
                    if (stock.quantity > 0) {
                        customerAddToCart();
                    } else {
                        alert('Stok Yeterli Değil');
                        return false;
                    }
                }
            }
        } else {
            if (props.product.productAttributeValues.length > 0) {
                if (isVariantSelected) {
                    if (variantStockState.stock) {
                        if (variantStock.quantity == 0) {
                            alert('Stok Yeterli Değil');
                            return false;
                        }
                    }
                    cookieAddToCart();
                } else {
                    alert('Lütfen Renk Seçiniz');
                    return false;
                }
            } else {
                const stockList =
                    props && props.product && props.product.stockList;
                if (stockList && stockList.length > 0) {
                    const stock = stockList[0];
                    if (stock.quantity > 0) {
                        cookieAddToCart();
                    } else {
                        alert('Stok Yeterli Değil');
                        return false;
                    }
                }
            }
        }
        window.dataLayer.push({
            event: 'add_to_cart'
        });
    }

    const prices =
        props.product.product &&
        props.product.product.prices.filter((p) => p.type === 1);
    const discountedPrices =
        props.product.product &&
        props.product.product.prices.filter((p) => p.type === 2);
    const personelPrices =
        props.product.product &&
        props.product.product.prices.filter((p) => p.type === 3);
    const cartPrices =
        props.product.product &&
        props.product.product.prices.filter((p) => p.type === 4);

    const price = prices && prices[0].value;
    var discountedPrice =
        discountedPrices && discountedPrices.length > 0
            ? discountedPrices[0].value
            : 0;

    const productContentTechnic =
        productContents &&
        productContents.filter((pc) => pc.chapterId == contentTechnic);
    const productContentPackage =
        productContents &&
        productContents.filter((pc) => pc.chapterId == contentPackage);
    const productContentProduct =
        productContents &&
        productContents.filter((pc) => pc.chapterId == contentProduct);
    const productContentVideo =
        productContents &&
        productContents.filter((pc) => pc.chapterId == contentVideo);

    const productContentSpecial =
        productContents &&
        productContents.filter((pc) => pc.chapterId == contentSpecial);
    const productContent3D =
        productContents &&
        productContents.filter((pc) => pc.chapterId == content3D);

    if (userInfo && userInfo.isEmployee == true) {
        //discountedPrice = price / 2;
        discountedPrice =
            personelPrices != null
                ? personelPrices[0] == null
                    ? price
                    : personelPrices[0].value
                : price;
    } else {
        var cartPrice =
            cartPrices && cartPrices.length > 0 ? cartPrices[0].value : 0;
    }
    var isDiscount = '';
    if (discountedPrice) {
        isDiscount = 'isDiscount';
    }
    const discountPercent = ((price - discountedPrice) * 100) / price;
    var priceItems = '';
    if (discountedPrice) {
        priceItems = (
            <>
                <div
                    className="d-flex gap-3 price isdiscount flex-grow-1 flex-shrink-0 align-items-center"
                    itemProp="offers"
                    itemType="https://schema.org/Offer"
                    itemScope
                >
                    <link itemProp="url" href={currentURL} />
                    <meta
                        itemProp="availability"
                        content="https://schema.org/InStock"
                    />
                    <meta
                        itemProp="itemCondition"
                        content="https://schema.org/NewCondition"
                    />
                    <meta itemProp="priceCurrency" content="TRY" />
                    {discountPercent == 0
                        ? ''
                        : (
                              <div className="discountBox">
                                  <span className="discountPercent">
                                      %{discountPercent.toFixed(0)}
                                  </span>
                                  <span className="discountText">İNDİRİM</span>
                              </div>
                          ) || <Skeleton width={'10%'} borderradius={16} />}
                    <div className="priceGroup isDiscount">
                        <span
                            className="mainPrice"
                            itemProp="price"
                            content={price.toFixed(2)}
                        >
                            {price.toFixed(2) + ' TL' || (
                                <Skeleton width={'20%'} borderradius={16} />
                            )}
                        </span>
                        <span className="actualPrice">
                            {discountedPrice.toFixed(2) + ' TL' || (
                                <Skeleton width={'20%'} borderradius={16} />
                            )}
                        </span>
                        {/* Sepet Fiyatı Olduğunda Görünecek Alan */}
                        <span className="basketPrice">
                            {cartPrice && cartPrice != 0
                                ? `Sepet'te ` + cartPrice.toFixed(2) + ` TL`
                                : ''}
                        </span>
                    </div>
                </div>
            </>
        );
    } else {
        priceItems = (
            <div
                itemProp="offers"
                itemType="https://schema.org/Offer"
                itemScope
                className="priceGroup"
            >
                <link itemProp="url" href={currentURL} />
                <meta
                    itemProp="availability"
                    content="https://schema.org/InStock"
                />
                <meta
                    itemProp="itemCondition"
                    content="https://schema.org/NewCondition"
                />
                <meta itemProp="priceCurrency" content="TRY" />
                <span className="small text-black-50">Fiyat</span>
                <span
                    className="actualPrice"
                    itemProp="price"
                    content={prices && prices[0].value.toFixed(2)}
                >
                    {(prices && prices[0].value.toFixed(2) + ' TL') || (
                        <Skeleton width={'20%'} borderradius={16} />
                    )}
                </span>
                {/* Sepet Fiyatı Olduğunda Görünecek Alan */}
                <span className="basketPrice">
                    {cartPrice && cartPrice != 0
                        ? `Sepet'te ` + cartPrice.toFixed(2) + ` TL`
                        : ''}
                </span>
            </div>
        );
    }
    function changeHandler() {}

    const [show, setShow] = useState(false);
    const content3dClose = () => setShow(false);
    const content3dShow = () => setShow(true);

    const [customerDeviceCapacity, setCustomerDeviceCapacity] = useState('');
    const [maxChargeResults, setMaxChargeResults] = useState(0);

    const ChargeCalculate = (event) => {
        event.preventDefault();
        const productCalc = parseFloat(productCapacity);
        const customerCalc = parseFloat(customerDeviceCapacity);

        if (!isNaN(productCalc) && !isNaN(customerCalc)) {
            if (customerDeviceCapacity !== 0) {
                const res = (((productCalc * 3.7) / 5) * 0.85) / customerCalc;
                setMaxChargeResults(res);
            } else {
                setMaxChargeResults('Cannot divide by zero');
            }
        } else {
            setMaxChargeResults('Invalid input');
        }
    };

    return (
        <>
            <Helmet>
                <title>{product && product.name}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            {/* Breadcrumb */}
            <div className="container-fluid border-bottom py-3">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb m-0 small justify-content-start align-items-center">
                            <li className="breadcrumb-item small">
                                <a href="/">Ana Sayfa</a>
                            </li>
                            <li className="breadcrumb-item small">
                                <a
                                    href={`/category/${
                                        product &&
                                        product.categoryProductMappings &&
                                        product.categoryProductMappings[0] &&
                                        product.categoryProductMappings[0]
                                            .category.id
                                    }`}
                                >
                                    {product &&
                                        product.categoryProductMappings &&
                                        product.categoryProductMappings[0] &&
                                        product.categoryProductMappings[0]
                                            .category &&
                                        product.categoryProductMappings[0]
                                            .category.name}
                                </a>
                            </li>
                            <li className="breadcrumb-item small">
                                <span className="text-black-50">
                                    {product && product.name}
                                </span>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* Campaign Notice */}
            {announcement.map((anc) => (
                <div className="py-3 bg-success text-white">
                    <div className="" key={anc.id}>
                        <div className="container">
                            <div
                                className="d-flex align-items-center campaign-notice"
                                key={anc.id}
                            >
                                <i className="bi bi-megaphone me-3 h3"></i>{' '}
                                {ReactHtmlParser(anc.fullDescription)}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <section className="product-detail mt-0">
                <div
                    className="main-detail bg-light py-sm-5 py-3 px-sm-0"
                    itemType="https://schema.org/Product"
                    itemScope
                >
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-sm-6">
                                <div
                                    id="productImageCarousel"
                                    className="carousel slide"
                                    data-bs-ride="carousel"
                                >
                                    {productContent3D &&
                                    productContent3D.length > 0 ? (
                                        <>
                                            <Button
                                                variant="outline-dark"
                                                className="d-block mt-3 rounded-3 shadow"
                                                onClick={content3dShow}
                                            >
                                                <i className="bi bi-badge-3d me-2"></i>
                                                <span className="fw-bold d-inline-block">
                                                    360º Evinde Görüntüle
                                                </span>
                                            </Button>
                                            <Modal
                                                show={show}
                                                fullscreen
                                                onHide={content3dClose}
                                                size="lg"
                                            >
                                                <Modal.Header
                                                    closeButton
                                                    className="border-0 p-3"
                                                ></Modal.Header>
                                                <ModalBody className="p-0">
                                                    {productContent3D.map(
                                                        (pc, i) => (
                                                            <div key={i}>
                                                                {ReactHtmlParser(
                                                                    pc.fdescription
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </ModalBody>
                                            </Modal>
                                        </>
                                    ) : (
                                        ' '
                                    )}
                                    <div className="carousel-indicators">
                                        {variantPicture.length > 0
                                            ? variantPicture.map((i, x) =>
                                                  x == 0 ? (
                                                      <button
                                                          key={x}
                                                          type="button"
                                                          data-bs-target="#productImageCarousel"
                                                          data-bs-slide-to={`${x}`}
                                                          className="active rounded-circle"
                                                          aria-current="true"
                                                          aria-label={`"Slide "${x}`}
                                                      ></button>
                                                  ) : (
                                                      <button
                                                          key={x}
                                                          type="button"
                                                          data-bs-target="#productImageCarousel"
                                                          data-bs-slide-to={`${x}`}
                                                          className="rounded-circle"
                                                          aria-current="true"
                                                          aria-label={`"Slide "${x}`}
                                                      ></button>
                                                  )
                                              )
                                            : product &&
                                              product.productPictureMappings.map(
                                                  (i, x) =>
                                                      x == 0 ? (
                                                          <button
                                                              key={x}
                                                              type="button"
                                                              data-bs-target="#productImageCarousel"
                                                              data-bs-slide-to={`${x}`}
                                                              className="active rounded-circle"
                                                              aria-current="true"
                                                              aria-label={`"Slide "${x}`}
                                                          ></button>
                                                      ) : (
                                                          <button
                                                              key={x}
                                                              type="button"
                                                              data-bs-target="#productImageCarousel"
                                                              data-bs-slide-to={`${x}`}
                                                              className="rounded-circle"
                                                              aria-current="true"
                                                              aria-label={`"Slide "${x}`}
                                                          ></button>
                                                      )
                                              )}
                                    </div>
                                    <button
                                        className="carousel-control-prev bg-transparent"
                                        type="button"
                                        data-bs-target="#productImageCarousel"
                                        data-bs-slide="prev"
                                    >
                                        <span
                                            className="carousel-control-prev-icon"
                                            aria-hidden="true"
                                        ></span>
                                        <span className="visually-hidden">
                                            Önceki
                                        </span>
                                    </button>
                                    <button
                                        className="carousel-control-next bg-transparent"
                                        type="button"
                                        data-bs-target="#productImageCarousel"
                                        data-bs-slide="next"
                                    >
                                        <span
                                            className="carousel-control-next-icon"
                                            aria-hidden="true"
                                        ></span>
                                        <span className="visually-hidden">
                                            Sonraki
                                        </span>
                                    </button>
                                    <div className="carousel-inner">
                                        {variantPicture.length > 0
                                            ? variantPicture.map(
                                                  (variant, i) => (
                                                      <div
                                                          key={i}
                                                          className={`carousel-item${
                                                              i == 0
                                                                  ? ' active'
                                                                  : ''
                                                          }`}
                                                      >
                                                          <img
                                                              src={`${
                                                                  variant.picture ==
                                                                  null
                                                                      ? '/assets/images/noimage.jpg'
                                                                      : variant
                                                                            .picture
                                                                            .virtualPath
                                                              }`}
                                                              className="d-block img-fluid p-5 productImg"
                                                              alt={'....'}
                                                              itemProp="image"
                                                          />
                                                      </div>
                                                  )
                                              )
                                            : (product &&
                                                  product.productPictureMappings.map(
                                                      (pic, i) => (
                                                          <div
                                                              key={i}
                                                              className={`carousel-item${
                                                                  i == 0
                                                                      ? ' active'
                                                                      : ''
                                                              }`}
                                                          >
                                                              <img
                                                                  src={`${pic.picture.virtualPath}`}
                                                                  className="d-block img-fluid p-5 productImg"
                                                                  alt={'....'}
                                                                  itemProp="image"
                                                              />
                                                          </div>
                                                      )
                                                  )) || (
                                                  <Skeleton
                                                      height={360}
                                                      borderradius={16}
                                                  />
                                              )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="product-main-detail d-flex flex-column gap-sm-5 gap-4">
                                    <BrowserView className="d-flex flex-column gap-5">
                                        <div className="title">
                                            <h3
                                                className="fw-bold text-dark"
                                                itemProp="name"
                                                content={
                                                    product && product.name
                                                }
                                            >
                                                {(product && product.name) || (
                                                    <Skeleton
                                                        count={2}
                                                        borderradius={16}
                                                    />
                                                )}
                                            </h3>
                                            <meta
                                                itemProp="brand"
                                                content="mojue"
                                            />
                                            <div
                                                className="text-black-50 h5 description"
                                                itemProp="description"
                                                content={
                                                    product &&
                                                    product.shortDescription
                                                }
                                            >
                                                {product &&
                                                    product.shortDescription}
                                            </div>
                                            <small
                                                className="text-black-50 stock-id"
                                                itemProp="sku"
                                                content={
                                                    product && product.stockId
                                                }
                                            >
                                                {(product &&
                                                    product.stockId) || (
                                                    <Skeleton
                                                        width={'20%'}
                                                        borderradius={16}
                                                    />
                                                )}
                                            </small>
                                        </div>
                                        {priceItems}
                                    </BrowserView>
                                    <MobileView className="add-to-cart shadow">
                                        <div
                                            className={`variantGroup d-flex align-items-center gap-3 ${
                                                productAttributes &&
                                                productAttributes.length > 0
                                                    ? ''
                                                    : 'd-none'
                                            }`}
                                        >
                                            <div className="fw-bold text-black-50">
                                                {' '}
                                                Renk{' '}
                                            </div>
                                            {productAttributes &&
                                                productAttributes.map((pa) => (
                                                    <div
                                                        key={pa.id}
                                                        className={`isVariant ${
                                                            pa.name == 'Colors'
                                                                ? 'circle colors'
                                                                : 'button'
                                                        }`}
                                                    >
                                                        {productAttributeValues &&
                                                            productAttributeValues.map(
                                                                (pav) => {
                                                                    return (
                                                                        <span
                                                                            id={
                                                                                pav.id
                                                                            }
                                                                            key={
                                                                                pav.id
                                                                            }
                                                                            className={`variantItem ${
                                                                                selectedVariant ==
                                                                                pav.id
                                                                                    ? 'selected'
                                                                                    : ''
                                                                            }`}
                                                                            style={
                                                                                pav.imageBinary ==
                                                                                null
                                                                                    ? {
                                                                                          background:
                                                                                              pav.hexCode
                                                                                      }
                                                                                    : {
                                                                                          backgroundImage: `url(data:image/png;base64,${pav.imageBinary})`,
                                                                                          backgroundSize:
                                                                                              24 +
                                                                                              'px'
                                                                                      }
                                                                            }
                                                                            title={
                                                                                pav.value
                                                                            }
                                                                            onClick={() =>
                                                                                selectVariant(
                                                                                    pa.id,
                                                                                    pa.name,
                                                                                    pav.id,
                                                                                    pav.value
                                                                                )
                                                                            }
                                                                        >
                                                                            {pa.name !=
                                                                            'Colors'
                                                                                ? pav.value
                                                                                : ''}
                                                                        </span>
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                ))}{' '}
                                        </div>
                                        <div className="d-flex gap-3">
                                            {priceItems}
                                            <button
                                                className={`btn btn-lg btn-primary add-to-cart-detail addToCartButton ${
                                                    sepetButtonDisable == true
                                                        ? 'disabled'
                                                        : ''
                                                }`}
                                                onClick={addToCart}
                                            >
                                                {sepetButtonDisable == true ? (
                                                    <span className="fw-bold">
                                                        Stokta Yok
                                                    </span>
                                                ) : (
                                                    <span className="fw-bold">
                                                        Sepete Ekle
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </MobileView>
                                    <BrowserView
                                        className={`variantGroup d-flex align-items-center gap-4 ${
                                            productAttributes &&
                                            productAttributes.length > 0
                                                ? ''
                                                : 'd-none'
                                        }`}
                                    >
                                        <div className="fw-bold text-black-50">
                                            {' '}
                                            Renk{' '}
                                        </div>
                                        {productAttributes &&
                                            productAttributes.map((pa) => (
                                                <div
                                                    key={pa.id}
                                                    className={`isVariant ${
                                                        pa.name == 'Colors'
                                                            ? 'circle colors'
                                                            : 'button'
                                                    }`}
                                                >
                                                    {productAttributeValues &&
                                                        productAttributeValues.map(
                                                            (pav) => {
                                                                return (
                                                                    <span
                                                                        id={
                                                                            pav.id
                                                                        }
                                                                        key={
                                                                            pav.id
                                                                        }
                                                                        className={`variantItem ${
                                                                            selectedVariant ==
                                                                            pav.id
                                                                                ? 'selected'
                                                                                : ''
                                                                        }`}
                                                                        data-color={
                                                                            pav.hexCode
                                                                        }
                                                                        style={
                                                                            pav.imageBinary ==
                                                                            null
                                                                                ? {
                                                                                      background:
                                                                                          pav.hexCode
                                                                                  }
                                                                                : {
                                                                                      backgroundImage: `url(data:image/png;base64,${pav.imageBinary})`,
                                                                                      backgroundSize:
                                                                                          24 +
                                                                                          'px'
                                                                                  }
                                                                        }
                                                                        title={
                                                                            pav.value
                                                                        }
                                                                        onClick={() =>
                                                                            selectVariant(
                                                                                pa.id,
                                                                                pa.name,
                                                                                pav.id,
                                                                                pav.value
                                                                            )
                                                                        }
                                                                    >
                                                                        {pa.name !=
                                                                        'Colors'
                                                                            ? pav.value
                                                                            : ''}
                                                                    </span>
                                                                );
                                                            }
                                                        )}
                                                </div>
                                            ))}{' '}
                                    </BrowserView>
                                    <BrowserView>
                                        <button
                                            className={`btn btn-lg btn-primary add-to-cart-detail addToCartButton w-100 ${
                                                sepetButtonDisable == true
                                                    ? 'disabled'
                                                    : ''
                                            }`}
                                            onClick={addToCart}
                                        >
                                            {sepetButtonDisable == true ? (
                                                <span className="fw-bold">
                                                    <i className="bi bi-bag-x me-2"></i>{' '}
                                                    Stokta Yok
                                                </span>
                                            ) : (
                                                <span className="fw-bold">
                                                    <i className="bi bi-bag-check me-2"></i>{' '}
                                                    Sepete Ekle
                                                </span>
                                            )}
                                        </button>
                                    </BrowserView>
                                    <MobileView>
                                        <h4
                                            className="fw-bold w-100"
                                            itemProp="name"
                                            content={product && product.name}
                                        >
                                            {(product && product.name) || (
                                                <Skeleton
                                                    count={2}
                                                    borderradius={16}
                                                />
                                            )}
                                        </h4>
                                        <meta
                                            itemProp="description"
                                            content={
                                                product && product.category
                                            }
                                        />
                                        <small
                                            className="text-black-50 fw-bolder stock-id w-100"
                                            itemProp="sku"
                                            content={product && product.stockId}
                                        >
                                            {(product && product.stockId) || (
                                                <Skeleton
                                                    width={'20%'}
                                                    borderradius={16}
                                                />
                                            )}
                                        </small>
                                        <meta
                                            itemProp="productID"
                                            content={product && product.stockId}
                                        />
                                    </MobileView>
                                    <div className="safeBuy row">
                                        <div className="col-sm-4">
                                            <div className="safeItem">
                                                <img
                                                    src="/assets/images/fastcargo.svg"
                                                    alt="..."
                                                    className="convert-turquoise img-fluid"
                                                />
                                                <div className="safeText d-flex flex-column text-black-50">
                                                    <span className="fw-bold">
                                                        24 Saatte Kargoda
                                                    </span>
                                                    <span className="fw-normal small">
                                                        Siparişleriniz (Hafta
                                                        Sonu hariç) 24 saat
                                                        içerisinde kargoya
                                                        verilir.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="safeItem">
                                                <img
                                                    src="/assets/images/installment.svg"
                                                    alt="..."
                                                    className="convert-turquoise img-fluid"
                                                />
                                                <div className="safeText d-flex flex-column text-black-50">
                                                    <span className="fw-bold">
                                                        6 Taksit Seçeneği
                                                    </span>
                                                    <span className="fw-normal small">
                                                        Tüm alışverişlerde vade
                                                        farksız 6 taksite kadar
                                                        ödeme imkanı.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="safeItem">
                                                <img
                                                    src="/assets/images/securepayment.svg"
                                                    alt="..."
                                                    className="convert-turquoise img-fluid"
                                                />
                                                <div className="safeText d-flex flex-column text-black-50">
                                                    <span className="fw-bold">
                                                        Güvenli Ödeme
                                                    </span>
                                                    <span className="fw-normal small">
                                                        256 Bit SSL şifreleme
                                                        ile tüm ödemelerinizi
                                                        güvenli şekilde
                                                        yapabilirsiniz.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>{' '}
                        </div>
                    </div>
                </div>
                <div className="technical-detail">
                    <div className="container">
                        <Tabs
                            defaultActiveKey="product-features"
                            id="product-detail-tab"
                            className="mb-3 scroll-snap"
                        >
                            {productContentProduct &&
                            productContentProduct.length > 0 ? (
                                <Tab
                                    eventKey="product-features"
                                    title="Ürün Özellikleri"
                                    tabClassName="product-features-tab scroll-snap-item box-light"
                                >
                                    {productContentProduct.map((pc, i) => (
                                        <div key={i}>
                                            {ReactHtmlParser(pc.fdescription)}
                                        </div>
                                    ))}
                                    {asset ? (
                                        <a
                                            href={asset.assetPath}
                                            target="_blank"
                                            rel="noopener"
                                            className="userManualButton"
                                            title={`${product.name} Kullanım Klavuzu`}
                                        >
                                            <div className="d-flex align-items-center gap-2 fw-bold">
                                                <i className="bi bi-file-earmark-medical h4"></i>
                                                <span className="text-start">
                                                    Kullanım Kılavuzunu
                                                    Görüntüle
                                                </span>{' '}
                                            </div>
                                        </a>
                                    ) : (
                                        ''
                                    )}
                                </Tab>
                            ) : (
                                '' || (
                                    <Skeleton
                                        height={48}
                                        count={3}
                                        borderradius={16}
                                    />
                                )
                            )}
                            {/*video*/}
                            {productContentVideo &&
                            productContentVideo.length > 0 ? (
                                <Tab
                                    eventKey="product-video"
                                    title="Ürün Videosu"
                                    tabClassName="product-video-tab scroll-snap-item box-light"
                                >
                                    {productContentVideo.map((pc, i) => (
                                        <div key={i}>
                                            {ReactHtmlParser(pc.fdescription)}
                                        </div>
                                    ))}
                                </Tab>
                            ) : (
                                '' || (
                                    <Skeleton
                                        height={48}
                                        count={3}
                                        borderradius={16}
                                    />
                                )
                            )}
                            {productContentTechnic &&
                            productContentTechnic.length > 0 ? (
                                <Tab
                                    eventKey="product-technic"
                                    title="Teknik Özellikler"
                                    tabClassName="product-technic-tab scroll-snap-item"
                                >
                                    {productContentTechnic.map((pc, i) => (
                                        <div key={i}>
                                            {ReactHtmlParser(pc.fdescription)}
                                        </div>
                                    ))}
                                </Tab>
                            ) : (
                                ''
                            )}
                            {productContentPackage &&
                            productContentPackage.length > 0 ? (
                                <Tab
                                    eventKey="package-content"
                                    title="Paket İçeriği"
                                    tabClassName="package-content-tab scroll-snap-item"
                                >
                                    {productContentPackage.map((pc, i) => (
                                        <div key={i}>
                                            {ReactHtmlParser(pc.fdescription)}
                                        </div>
                                    ))}
                                </Tab>
                            ) : (
                                ''
                            )}
                            {props.product &&
                            props.product.specificationAttributes &&
                            props.product.specificationAttributes.filter(
                                (sa) => sa.name === 'Uyumluluk'
                            ).length > 0 ? (
                                <Tab
                                    eventKey="supportCon"
                                    title="Uyumluluk"
                                    tabClassName="supportcon-tab scroll-snap-item"
                                >
                                    {props.product.specificationAttributes
                                        .filter(
                                            (sa) => sa.name === 'Uyumluluk'
                                        )[0]
                                        .specificationAttributeOptions.map(
                                            (pd) => (
                                                <span
                                                    key={pd.id}
                                                    className="bg-light py-1 px-2 rounded-1"
                                                >
                                                    {' '}
                                                    {pd.name}{' '}
                                                </span>
                                            )
                                        )}
                                </Tab>
                            ) : (
                                ''
                            )}
                            {props.product.product &&
                            props.product.product.categoryProductMappings[0]
                                .categoryId == 4 ? (
                                <Tab
                                    eventKey="charge-calculation"
                                    title="Kaç Kez Şarj Eder"
                                    tabClassName="charge-calculation-tab scroll-snap-item"
                                >
                                    <div className="row justify-content-center py-3">
                                        <div className="col-sm-6">
                                            <Form>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className="fw-bold">
                                                        Cihaz Pil Kapasitesi
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="productCapacity"
                                                        type="text"
                                                        placeholder="10000"
                                                        value={productCapacity}
                                                        disabled={'disabled'}
                                                        onChange={(e) =>
                                                            setProductCapacity(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicPassword"
                                                >
                                                    <Form.Label className="fw-bold">
                                                        Şarj Edilecek Cihazın
                                                        Pil Kapasitesi (mAh
                                                        cinsinden)
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="customerDeviceCapacity"
                                                        type="text"
                                                        placeholder="3280"
                                                        value={
                                                            customerDeviceCapacity
                                                        }
                                                        onChange={(e) =>
                                                            setCustomerDeviceCapacity(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Şarj etmek istediğiniz
                                                        cihazınızın toplam pil
                                                        kapasitesini yazınız.
                                                    </Form.Text>
                                                </Form.Group>
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                    onClick={ChargeCalculate}
                                                >
                                                    Hesapla
                                                </Button>
                                            </Form>
                                            {maxChargeResults > 0 ? (
                                                <div className="d-flex flex-column align-items-start gap-3 mt-5 box-light p-5 rounded-4">
                                                    <h4 className="fw-bold">
                                                        {product.name} ile
                                                        Cihazınızı
                                                    </h4>
                                                    <div className="d-flex flex-column gap-0">
                                                        <span className="h2 fw-bold">
                                                            {Math.round(
                                                                maxChargeResults
                                                            )}{' '}
                                                            kez şarj
                                                            edebilirsiniz
                                                        </span>
                                                        <span></span>
                                                    </div>
                                                    <div>
                                                        <p className="fw-bold">
                                                            mojue taşınabilir
                                                            şarj aletleri ile
                                                            optimum şartlarda
                                                            cihazınızı kaç kez
                                                            şarj edebileceğinizi
                                                            gösteren yaklaşık
                                                            değerlerdir.
                                                        </p>
                                                        <p className="small">
                                                            Taşınabilir şarj
                                                            aletlerinin kaç kez
                                                            şarj edebileceği güç
                                                            kaynağının voltaj
                                                            değerine, şarj
                                                            edilen cihazın pil
                                                            kapasitesine, şarj
                                                            kablosunun
                                                            kalitesine ve şarj
                                                            sırasında şarj
                                                            edilen cihazın
                                                            kullanım durumuna
                                                            göre değişiklik
                                                            gösterebilir.
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </Tab>
                            ) : (
                                ''
                            )}
                        </Tabs>
                    </div>
                </div>
            </section>
            <section id="productDetail">
                {productContentSpecial && productContentSpecial.length > 0 ? (
                    <div className="special-detail bg-light">
                        <div className="container">
                            <div className="detail-content">
                                {productContentSpecial.map((pc, i) => (
                                    <div className="d-flex flex-column" key={i}>
                                        {ReactHtmlParser(pc.fdescription)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </section>
        </>
    );
};

export default Product;
