export const API_BASE = 'https://webapi.mojue.com.tr/api/';
//export const API_BASE = 'https://webapi.ttectest.com/api/';
//export const API_BASE= 'http://localhost:5000/api/';

//export const IMAGE_URL = 'http://localhost:3000/Images/';
export const IMAGE_URL = 'https://admin.mojue.com.tr/Images/';

export const tesanCDNURL = 'https://tesancdn.ams3.cdn.digitaloceanspaces.com/';

export const contentTechnic = 42;
export const contentSpecial = 40;
export const contentPackage = 37;
export const contentProduct = 5;
export const contentVideo = 51;
export const content3D = 52;
export const cargoPriceFree = 150;
