import React, { Component } from 'react';

export default class Hakkimizda extends Component {
    render() {
        return (
            <div className="container">
                <div className="py-5">
                    <div className="text-center mb-4">
                        <h1 className="fw-bold">HİKAYEMİZ</h1>
                    </div>
                    <div className="h5 fw-normal lh-lg">
                        <p>
                            2018 yılında İstanbul'da doğan mojue, uygun fiyat ve
                            fonksiyonelliği birleştiren kaliteli mobil
                            aksesuarları üstün hizmet anlayışı ile müşterilerine
                            sunmayı misyon edinmiştir. mojue, kurulduğu 1983
                            yılından bu yana müşteri odaklı ve yenilikçi
                            vizyonuyla Türkiye’nin katma değerli teknoloji
                            distribütörü TESAN İLETİŞİM A.Ş’nin markasıdır.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
