import React, { useState } from 'react';
import { render } from 'react-dom';
import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './components/root/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from './redux/reducers/rootReducer';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxPromise from 'redux-promise-middleware';
// import logger from 'redux-logger';
//, logger
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(reduxPromise, thunk))
);
const rootElement = ReactDOMClient.createRoot(document.getElementById('root'));
rootElement.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
