import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { API_BASE } from '../../config/env';
import { CSSTransition } from 'react-transition-group';

const MiniBasketWeb = (props) => {
    const cookies = new Cookies();
    const [customerId, SetCustomerId] = useState(0);
    const [basket, setBasket] = useState();
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalInfo, setTotalInfo] = useState({
        totalPrice: 0,
        totalDiscountedPrice: 0,
        KDV: 0,
        Kargo: 0
    });
    const [basketQuantity, setBasketQuantity] = useState([]);
    const [stockQuantities, setStockQuantities] = useState([]);
    function removeCartItem(productId, stockId) {
        if (customerId == 0) {
            var basket = cookies.get('basket');
            if (basket) {
                const product = basket.basketProducts.filter(
                    (bp) => bp.stockId === stockId
                )[0];
                if (product) {
                    const index = basket.basketProducts.indexOf(product);
                    basket.basketProducts.splice(index, 1);
                    cookies.set('basket', basket, { path: '/' });
                    window.location.reload();
                }
            }
        } else {
            const delToCartServiceUrl = 'basket/DeleteBasketItem';

            const config = {
                headers: { 'Content-Type': 'application/json' }
            };

            const body = JSON.stringify({
                customerId: customerId,
                productId: productId,
                stockId: stockId
            });
            axios
                .post(`${API_BASE}${delToCartServiceUrl}`, body, config)
                .then((data) => {
                    setBasket(data.data.set);
                    window.location.reload();
                })
                .catch((error) => alert(error));
        }
    }
    const minusClickHandle = (e) => {
        //console.log(e.currentTarget);
        const stockId = e.currentTarget.name.substring(6);
        const price = Number(e.currentTarget.dataset.price);
        var basketMinus;
        if (customerId == 0) {
            basketMinus = cookies.get('basket');
        } else {
            basketMinus = basket;
        }

        const product = basketMinus.basketProducts.filter(
            (bp) => bp.stockId == stockId
        )[0];
        //console.log(stockId);
        if (basketQuantity && basketQuantity.length > 0) {
            var qu = basketQuantity.filter((bq) => bq.stockId == stockId)[0];

            if (qu != null) {
                if (qu.quantity > 1) {
                    qu.quantity--;
                    product.quantity--;
                    if (customerId == 0) {
                        cookies.set('basket', basketMinus, { path: '/' });
                        setBasket(cookies.get('basket'));
                    } else {
                        var obj = {};
                        obj.customerId = customerId;
                        obj.productId = product.productId;
                        obj.stockId = stockId;
                        obj.plusMinus = 0;

                        const minusToCartServiceUrl = 'basket/UpdateBasket';

                        const config = {
                            headers: { 'Content-Type': 'application/json' }
                        };

                        axios
                            .post(
                                `${API_BASE}${minusToCartServiceUrl}`,
                                obj,
                                config
                            )
                            .then((data) => {
                                setBasket(data.data.set);
                            })
                            .catch((error) => alert(error));
                    }
                    setBasketQuantity(basketQuantity);
                }
            } else {
                product.quantity = 1;
                if (customerId == 0) {
                    cookies.set('basket', basketMinus, { path: '/' });
                    setBasket(cookies.get('basket'));
                } else {
                    var obj = {};
                    obj.customerId = customerId;
                    obj.productId = product.productId;
                    obj.stockId = stockId;
                    obj.plusMinus = 0;

                    const plusToCartServiceUrl = 'basket/UpdateBasket';

                    const config = {
                        headers: { 'Content-Type': 'application/json' }
                    };

                    axios
                        .post(`${API_BASE}${plusToCartServiceUrl}`, obj, config)
                        .then((data) => {
                            setBasket(data.data.set);
                        })
                        .catch((error) => alert(error));
                }
                setBasketQuantity([
                    ...basketQuantity,
                    { stockId: stockId, quantity: 1 }
                ]);
            }
        } else {
            setBasketQuantity([
                { stockId: stockId, quantity: 1, price: price }
            ]);
        }
        calculateTotalPrice();
    };
    const calculateTotalPrice = () => {
        var total = 0;
        basketQuantity.map((bq) => {
            total +=
                bq.discountPrice !== undefined
                    ? bq.discountPrice * bq.quantity
                    : bq.price * bq.quantity;
        });
        setTotalInfo({ totalDiscountedPrice: total });
    };
    const plusClickHandle = (e) => {
        const stockId = e.currentTarget.name.substring(5);
        const price = Number(e.currentTarget.dataset.price);
        const stockQuantity = stockQuantities.filter(
            (sq) => sq.stockId == stockId
        )[0];
        if (basketQuantity && basketQuantity.length > 0) {
            var qu = basketQuantity.filter((bq) => bq.stockId == stockId)[0];
            var basketPlus;

            if (customerId == 0) {
                basketPlus = cookies.get('basket');
            } else {
                basketPlus = basket;
            }
            const product = basketPlus.basketProducts.filter(
                (bp) => bp.stockId == stockId
            )[0];
            if (qu != null) {
                if (stockQuantity.quantity <= product.quantity) {
                    alert('Stok yeterli değil');
                    return false;
                }
                qu.quantity++;
                product.quantity++;
                if (customerId == 0) {
                    cookies.set('basket', basketPlus, { path: '/' });
                    setBasket(cookies.get('basket'));
                } else {
                    var obj = {};
                    obj.customerId = customerId;
                    obj.productId = product.productId;
                    obj.stockId = stockId;
                    obj.plusMinus = 1;

                    const plusToCartServiceUrl = 'basket/UpdateBasket';

                    const config = {
                        headers: { 'Content-Type': 'application/json' }
                    };

                    axios
                        .post(`${API_BASE}${plusToCartServiceUrl}`, obj, config)
                        .then((data) => {
                            if (data.data.code == '200') {
                                setBasket(data.data.set);
                            } else if (data.data.code == '201') {
                                alert('Stok Yeterli Değil');
                            }
                        })
                        .catch((error) => alert(error));
                }
                setBasketQuantity([...basketQuantity]);
            } else {
                setBasketQuantity([
                    ...basketQuantity,
                    { stockId: stockId, quantity: 1, price: price }
                ]);
            }
        } else {
            setBasketQuantity([
                { stockId: stockId, quantity: 1, price: price }
            ]);
        }
        calculateTotalPrice();
    };
    const handleQuantityChange = (e) => {};
    useEffect(() => {
        const userToken = localStorage.getItem('token');
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };

        axios
            .get(
                `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                config
            )
            .then((data) => {
                if (data.data) {
                    if (data.data.code == '200') {
                        SetCustomerId(data.data.set.customerId);
                        CheckCustomerId(data.data.set.customerId);
                    } else {
                        CheckCustomerId(0);
                    }
                }
            });

        function CheckCustomerId(customerId) {
            if (customerId == 0) {
                var cookiedBasket = cookies.get('basket');
                cookiedBasket && setBasket(cookiedBasket);
                SepetIslemleri(cookiedBasket);
            } else {
                const getBasketServiceUrl = 'basket/GetCustomerBasket/';
                const config = {
                    headers: { 'Content-Type': 'application/json' }
                };
                axios
                    .get(
                        `${API_BASE}${getBasketServiceUrl}${customerId}`,
                        config
                    )
                    .then((data) => {
                        setBasket(data.data.set);
                        SepetIslemleri(data.data.set);
                    })
                    .catch();
            }
        }

        function SepetIslemleri(basket) {
            var totalPrice = 0;
            var totalDiscountedPrice = 0;
            var basketQuantities = [];
            basket &&
                basket.basketProducts &&
                basket.basketProducts.map((bp) => {
                    basketQuantities.push({
                        productId: bp.productId,
                        stockId: bp.stockId,
                        quantity: bp.quantity,
                        price: bp.discountPrice
                    });
                    totalPrice += bp.price * bp.quantity;
                    totalDiscountedPrice +=
                        bp.discountPrice !== undefined
                            ? bp.discountPrice * bp.quantity
                            : bp.price * bp.quantity;

                    setTotalInfo({
                        totalPrice: totalPrice,
                        totalDiscountedPrice: totalDiscountedPrice,
                        KDV: 20,
                        Kargo: 0
                    });
                });
            setBasketQuantity(basketQuantities);

            //StockCheck All
            const stockCheckAll =
                basket &&
                basket.basketProducts &&
                basket.basketProducts.map((bp) => ({
                    productId: bp.productId,
                    stockId: bp.stockId
                }));
            if (stockCheckAll != null) {
                const config = {
                    headers: { 'Content-Type': 'application/json' }
                };
                axios
                    .post(
                        `${API_BASE}basket/StockCheckAll`,
                        stockCheckAll,
                        config
                    )
                    .then((data) => {
                        setStockQuantities(data.data.set);
                    })
                    .catch((error) => {
                        console.error('axios hata: ' + error);
                    });
            }
        }
    }, []);

    function redirectToBasket() {
        window.location.href = `/basket`;
    }

    function redirectToAddressCheck(props) {
        window.location.href = `/AddressCheck`;
    }
    var cartItem = (
        <div className="hoverBasket p-4 rounded-4" id="miniCart">
            <header className="pb-3 border-bottom mb-3">
                <h6 className="fw-bold">Sepetiniz</h6>
            </header>
            {/* Sepet Boş Olduğunda Gösterilecek */}
            {basket == undefined ||
            basket.basketProducts == undefined ||
            basket.basketProducts.length < 1 ? (
                <div className="empty-basket p-4 text-black-50 text-center d-flex align-items-center gap-3 justify-content-center">
                    <i className="bi bi-cart3 h3"></i>
                    <h5 className="fw-bolder">Sepetiniz Boş</h5>
                </div>
            ) : (
                ''
            )}
            {/* Sepet Boş Olduğunda Gösterilecek */}
            <div className="contents d-flex gap-3 flex-column">
                {basket &&
                    basket.basketProducts &&
                    basket.basketProducts.map((pr) => (
                        <div
                            className="product"
                            key={pr.stockId + '-' + Math.random()}
                        >
                            <div className="row g-3">
                                <div className="col-3">
                                    <div className="image bg-light p-2 rounded-2">
                                        <img
                                            src={
                                                pr.productImage != null
                                                    ? pr.productImage
                                                    : '/assets/images/noimage.jpg'
                                            }
                                            className="img-fluid"
                                            alt="Ürün Fotoğrafı Yok"
                                        />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="d-flex flex-column gap-2 position-relative">
                                        <div className="title d-flex flex-column gap-1">
                                            <span className="small text-black">
                                                {`${pr.productName.substring(
                                                    0,
                                                    60
                                                )}`}
                                                ...
                                            </span>
                                            <span className="small price text-primary">
                                                {pr.discountPrice != null
                                                    ? pr.discountPrice.toFixed(
                                                          2
                                                      )
                                                    : pr.price.toFixed(2)}{' '}
                                                TL
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                {basket &&
                basket.basketProducts &&
                basket.basketProducts.length > 0 ? (
                    <div className="buttons">
                        <div className="d-grid">
                            <button
                                href="#"
                                className="btn btn-lg btn-primary py-3"
                                onClick={redirectToBasket}
                            >
                                <span className="fw-bold">
                                    Alışverişi Tamamla
                                </span>
                            </button>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
    return (
        <CSSTransition
            in={props.openHoverBasket}
            timeout={300}
            classNames="fade"
            unmountOnExit
        >
            {cartItem}
        </CSSTransition>
    );
};

export default MiniBasketWeb;
