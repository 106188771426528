import React from 'react';
import { tesanCDNURL } from '../../config/env';
import axios from 'axios';
import { API_BASE } from '../../config/env';

function CategoryProductDetail(props) {
    const productIdHandle = (id) => {
        props.openedProductId(id);
    };
    const currentProductMapping = props.productMap;
    var discountedPrices = currentProductMapping.product.prices.filter(
        (p) => p.type === 2
    );
    const prices = currentProductMapping.product.prices.filter(
        (p) => p.type === 1
    );
    const price = prices[0] && prices[0].value;
    var discountedPrice =
        discountedPrices[0] && discountedPrices[0].value != null
            ? discountedPrices[0].value
            : price;

    if (props && props.userInfo && props.userInfo.isEmployee == true) {
        const personalPrice = currentProductMapping.product.prices.filter(
            (p) => p.type === 3
        );
        // discountedPrice = price / 2;
        discountedPrice =
            personalPrice[0] == null ? price : personalPrice[0].value;
    }
    var discountPercent = 0;
    if (discountedPrice) {
        discountPercent = ((price - discountedPrice) / price) * 100;
    }
    var priceItems = '';
    if (discountedPrice != price) {
        priceItems = (
            <div className="price isdiscount flex-grow-1 flex-shrink-0">
                <div className="priceGroup">
                    <div className="text-danger small">
                        <strong>%{discountPercent.toFixed(0)}</strong> İNDİRİM
                    </div>
                    <div className="mainprice text-black-50">
                        <span>{price.toFixed(2)} TL</span>
                    </div>
                    <div className="discountprice">
                        <span>{discountedPrice.toFixed(2)} TL</span>
                    </div>
                </div>
            </div>
        );
    } else {
        priceItems = (
            <div className="price flex-grow-1 flex-shrink-0">
                <div className="mainprice">
                    <span> {price.toFixed(2)} TL</span>
                </div>
            </div>
        );
    }
    //Variants
    var variantsIdList = [];
    var variantItems = [];
    currentProductMapping.product.productProductAttributeValueMappings &&
        currentProductMapping.product.productProductAttributeValueMappings
            .filter(
                (pavm) =>
                    pavm.productAttributeValue.productAttribute.name ===
                        'Colors' && pavm.isActive == true
            )
            .map((pavm) => {
                if (
                    variantsIdList.indexOf(pavm.productAttributeValue.id) === -1
                ) {
                    variantsIdList.push(pavm.productAttributeValue.id);
                    variantItems.push(
                        <span
                            key={pavm.id}
                            className="variantItem"
                            style={
                                pavm.productAttributeValue.imageBinary == null
                                    ? {
                                          background:
                                              pavm.productAttributeValue.hexCode
                                      }
                                    : {
                                          backgroundImage: `url(data:image/png;base64,${pavm.productAttributeValue.imageBinary})`,
                                          backgroundSize: 24 + 'px'
                                      }
                            }
                            title={pavm.productAttributeValue.value}
                        ></span>
                    );
                }
            });

    //console.log(currentProductMapping.product.productProductAttributeValueMappings[0].productId + ' ' + currentProductMapping.product.productProductAttributeValueMappings[0].productAttributeValueId);
    //currentProductMapping.product.productPictureMappings[0].picture.virtualPath = 'https://erp.tesan.net/documents/product/643E4287-053A-7C26-7420285A84F03718_b.png';
    return (
        <div className="col">
            <div
                key={currentProductMapping.id}
                className="product-item border border-light overflow-hidden rounded-3 h-100"
            >
                {currentProductMapping.product.productPictureMappings &&
                    (currentProductMapping.product.productPictureMappings[0] !=
                        null &&
                    currentProductMapping.product.productPictureMappings[0]
                        .picture.virtualPath !== null ? (
                        <div className="image">
                            <a
                                href={`/detail/${currentProductMapping.product.id}`}
                                role="button"
                                title={currentProductMapping.product.name}
                            >
                                <img
                                    className="img-fluid"
                                    // style={{ width: "50%" }}
                                    src={`${currentProductMapping.product.productPictureMappings[0].picture.virtualPath}`}
                                    alt={currentProductMapping.product.name}
                                />
                                {variantItems.length > 1 ? (
                                    <div className="isVariant colors circle">
                                        {variantItems}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </a>
                            <button
                                className="quick-add-to-cart-button"
                                //data-bs-toggle="modal"
                                //data-bs-target="#quickAddModal"
                                id={`product-${currentProductMapping.product.id}`}
                                onClick={() => {
                                    productIdHandle(
                                        currentProductMapping.product.id
                                    );
                                }}
                            >
                                <span className="text">Sepete Ekle</span>
                                <span className="icon">
                                    <i className="bi bi-cart3 h4"></i>
                                </span>
                            </button>
                        </div>
                    ) : (
                        <div className="image">
                            <img
                                className="img-fluid"
                                src="/assets/images/noimage.jpg"
                                alt={currentProductMapping.product.name}
                            />
                            {variantItems.length > 1 ? (
                                <div className="isVariant colors circle">
                                    {variantItems}
                                </div>
                            ) : (
                                ''
                            )}
                            <button
                                className="quick-add-to-cart-button"
                                //data-bs-toggle="modal"
                                //data-bs-target="#quickAddModal"
                                id={`product-${currentProductMapping.product.id}`}
                                onClick={() => {
                                    productIdHandle(
                                        currentProductMapping.product.id
                                    );
                                }}
                            >
                                <span className="text">Sepete Ekle</span>
                                <span className="icon">
                                    <i className="bi bi-cart3 h4"></i>
                                </span>
                            </button>
                        </div>
                    ))}
                <div className="detail d-flex flex-sm-row flex-column gap-3 align-items-start">
                    <h6 className="title w-100">
                        <a
                            href={`/detail/${currentProductMapping.product.id}`}
                            role="button"
                            title={currentProductMapping.product.name}
                        >
                            {currentProductMapping.product.name}
                        </a>
                    </h6>
                    {priceItems}
                </div>
            </div>
        </div>
    );
}
export default CategoryProductDetail;
