import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { API_BASE } from '../../config/env';
import Cookies from 'universal-cookie';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { ModalBody, ModalFooter } from 'react-bootstrap';

const Login = (props) => {
    const [userState, setUserState] = useState({
        userName: '',
        password: '',
        results: []
    });
    const [redirectState, setRedirectState] = useState('');
    const userNameInput = useRef('');
    const passwordInput = useRef('');
    const cookies = new Cookies();
    useEffect(() => {
        //redirect ise cookie'de vardır.
        const userToken = localStorage.getItem('token');
        if (userToken) {
            //token validate
            //GetCustomerByToken
            const config = {
                headers: { 'Content-Type': 'application/json' }
            };
            axios
                .get(
                    `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                    config
                )
                .then((data) => {
                    if (data.data) {
                        if (data.data.code == '201') {
                        } else if (data.data.code == '200') {
                            const redirectCookie = cookies.get('redirectUrl');
                            if (redirectCookie) {
                                cookies.set('redirectUrl', '', { path: '/' });

                                window.location.href = '/Basket';
                            } else {
                                window.location.href = '/Profile';
                            }
                        }
                    }
                });
        } else {
            const redirectCookie = cookies.get('redirectUrl');
            if (redirectCookie) {
                setRedirectState(redirectCookie);
            }
        }
        setTimeout(function () {
            setUserState({
                userName: userNameInput.current.value,
                password: passwordInput.current.value
            });
        }, 1000);
    }, []);
    const handleChange = (e) => {
        setUserState({ ...userState, [e.target.name]: e.target.value });
    };
    const btnResetClicked = (e) => {
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        axios
            .post(
                `${API_BASE}Customer/ResetPassword?customerMail=${userNameInput.current.value}`,
                config
            )
            .then((data) => {
                if (data.data.code == '200') {
                    alert(
                        'Lütfen Mailinize Gelen Şifre Sıfırlama Linkinden Şifrenizi Sıfırlayınız.'
                    );
                } else {
                    alert("Bu e-Mail'e ait müşteri bulunamadı!");
                }
            });
    };
    const btnLoginClicked = (e) => {
        if (userNameInput.current.value && passwordInput.current.value) {
            setUserState({
                userName: userNameInput.current.value,
                password: passwordInput.current.value
            });
        } else {
            if (!userState.userName || !userState.password) {
                alert('Lütfen Kullanıcı Adı ve Şifrenizi Yazınız.');
                return false;
            }
        }
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        axios.post(`${API_BASE}Auth/GetToken`, userState, config).then((data) =>
            // userState.results == null
            //   ? alert("Kullanıcı bulunamadı")
            //   : alert("giriş başarılı")
            {
                if (data.data) {
                    if (data.data.code == '201') {
                        alert('Kullanıcı Adı veya Şifre Hatalı');
                    } else if (data.data.code == '202') {
                        axios
                            .post(
                                `${API_BASE}Customer/ResetPassword?customerMail=${data.data.set.email}`,
                                config
                            )
                            .then((data) => {
                                alert(
                                    'Şifre Yenileme Periyodunuz Gelmiştir. \nLütfen Mailinize Gelen Şifre Sıfırlama Linkinden Şifrenizi Sıfırlayınız.'
                                );
                            });
                    } else if (data.data.code == '200') {
                        if (data.data.set.isActive == false) {
                            alert(
                                'Hesabınız aktif değil. Lütfen size gönderilen aktifleştirme mailindeki linke tıklayınız...'
                            );
                            return false;
                        } else {
                            var cookiedBasket = cookies.get('basket');
                            if (cookiedBasket != null) {
                                console.log(cookiedBasket);
                                cookiedBasket.basketProducts.map((bp) => {
                                    const addToCartServiceUrl =
                                        'basket/AddProductToBasket';
                                    const body = JSON.stringify({
                                        CustomerId: data.data.set.customerId,
                                        productId: bp.productId,
                                        stockId: bp.stockId,
                                        productName: bp.productName,
                                        productShortDesc: bp.productShortDesc,
                                        productImage: bp.productImage,
                                        discountPrice: bp.discountPrice,
                                        price: bp.price,
                                        quantity: bp.quantity,
                                        variantInfo: {
                                            productAttributeId: 0,
                                            productAttributeValue: '',
                                            productAttributeValueId:
                                                bp.variantInfo
                                                    .productAttributeValueId,
                                            productAttributeValueValue:
                                                bp.variantInfo
                                                    .productAttributeValueValue,
                                            price: 0.0,
                                            stockId: bp.variantInfo.stockId,
                                            variantPictureId:
                                                bp.variantInfo.variantPictureId,
                                            variantPicturePath:
                                                bp.variantInfo
                                                    .variantPicturePath
                                        }
                                    });
                                    axios
                                        .post(
                                            `${API_BASE}${addToCartServiceUrl}`,
                                            body,
                                            config
                                        )
                                        .then((data) => {})
                                        .catch((error) => alert(error));
                                });
                                cookies.remove('basket');
                            }
                            setUserState({
                                ...userState,
                                ...data.data
                            });
                            localStorage.setItem('token', data.data.set.token);
                            const redirectCookie = cookies.get('redirectUrl');
                            if (redirectCookie) {
                                window.location.href = '/Basket';
                            } else {
                                window.location.href = '/Profile';
                            }
                        }
                    }
                }
            }
        );
    };
    const SetLocalStorage = (data) => {
        //localstorage yaz.
        var value = document.getElementById('chkRememberMe').checked;
        if (value === true) {
            localStorage.setItem('mojueUser', userState);
        } else {
            localStorage.removeItem('mojueUser');
        }
        setUserState({ password: '' });
        alert('Kullanıcı Girişi Başarılı. ');
        props.history.push('/Profile');
    };

    const [show, setShow] = useState(false);

    const forgotPassClose = () => setShow(false);
    const forgotPassShow = () => setShow(true);

    return (
        <section className="login py-sm-5 py-4">
            <div className="container">
                <div className="d-grid col-sm-6 mx-auto">
                    <div className="header d-flex flex-column mb-3 text-center">
                        <div className="mb-1 h2">
                            <i className="bi bi-person text-primary" />
                        </div>
                        <div className="h5 fw-bolder">
                            <span>Hoşgeldiniz</span>
                        </div>
                        <div className="mb-5">
                            <span>Devam etmek için hesabınıza giriş yapın</span>
                        </div>
                        <div className="ms-auto">
                            <span className="me-3">Hesabınız Yok Mu ?</span>
                            <a className="btn btn-light" href="/CreateCustomer">
                                <i className="bi bi-person-plus me-2" />
                                <span>Yeni Hesap Oluştur</span>
                            </a>
                        </div>
                    </div>
                    <div>
                        <form>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="userName"
                                    name="userName"
                                    ref={userNameInput}
                                    placeholder="Kullanıcı Adı ya da E-posta Adresiniz"
                                    autoComplete="email"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    ref={passwordInput}
                                    placeholder="Şifreniz"
                                    onChange={(e) => handleChange(e)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            btnLoginClicked();
                                        }
                                    }}
                                />
                            </div>
                            <div className="mb-3 d-flex align-items-center">
                                {/* <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="rememberme"
                                        checked
                                        onChange={() => true}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="rememberme"
                                    >
                                        Beni Hatırla
                                    </label>
                                </div> */}
                                <a
                                    href="#"
                                    className="btn btn-link ms-auto"
                                    onClick={forgotPassShow}
                                >
                                    Şifremi Unuttum
                                </a>
                            </div>
                            <div className="d-grid my-5">
                                <button
                                    type="button"
                                    className="btn btn-lg btn-primary mb-2"
                                    onClick={btnLoginClicked}
                                >
                                    Giriş Yap
                                </button>
                                {redirectState && (
                                    <a
                                        href="/AddressCheck"
                                        className="btn btn-lg btn-secondary mt-2"
                                    >
                                        Üyeliksiz Devam Et
                                    </a>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* Şifremi Unuttum Modal */}
            <Modal show={show} onHide={forgotPassClose}>
                <ModalHeader closeButton className="border-0"></ModalHeader>
                <ModalBody className="p-5">
                    <Form.Label
                        htmlFor="inputPassword5"
                        className="h4 mb-2 fw-bolder"
                    >
                        Şifremi Unuttum
                    </Form.Label>
                    <Form.Control
                        type="email"
                        id="inputPassword5"
                        ref={userNameInput}
                        aria-describedby="passwordHelpBlock"
                        placeholder="E-Posta Adresi"
                        size="md"
                    />
                    <Form.Text
                        id="passwordHelpBlock"
                        className="d-block mt-2"
                        muted
                    >
                        Üyeliğinize ait E-posta adresinizi yazarak "Gönder"
                        tuşuna basınız.
                    </Form.Text>
                    <div className="d-grid gap-2 mt-5">
                        <Button
                            type="submit"
                            size="lg"
                            onClick={btnResetClicked}
                        >
                            Gönder
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
            {/* Şifremi Unuttum Modal */}
        </section>
    );
};
export default Login;
